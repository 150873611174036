<div class="h-screen">
  <div class="flex h-full">
    <div class="hidden lg:flex w-7/12 flex-col justify-center pl-24">
      <div class="flex flex-col items-start m-4">
        <a href="/">
          <img
            [src]="'../../../assets/Logo.svg'"
            alt="Logo"
            class="w-[250px] mb-4" />
        </a>
        <div class="text-white text-[60px] leading-tight font-normal">
          Experience the Power of Product Traceability
          <span class="font-bold">with reGenesis.</span>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-5/12 h-screen">
      <div class="m-5 p-5 box-border rounded bg-light">
        <div class="form-content overflow-auto">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
