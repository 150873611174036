<div class="flex flex-col">
  <div class="sticky top-0 z-10 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid && (selectedIcon === '' || uploadedIcon === '')"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form">
      <div>
        <p>CTE Title</p>
        <input
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('title')?.invalid && form.get('title')?.touched
          "
          placeholder="Enter Title"
          formControlName="title" />
        <app-error-message
          *ngIf="form.get('title')?.invalid && form.get('title')?.touched"
          [control]="form.get('title')"
          label="CTE Title"></app-error-message>
      </div>

      <div>
        <p>CTE Description</p>
        <textarea
          rows="5"
          class="w-full resize-none"
          pInputTextarea
          [class.input-danger]="
            form.get('description')?.invalid && form.get('description')?.touched
          "
          formControlName="description"
          placeholder="Add description here"></textarea>
        <app-error-message
          *ngIf="
            form.get('description')?.invalid && form.get('description')?.touched
          "
          [control]="form.get('description')"
          label="CTE Description"></app-error-message>
      </div>

      <div>
        <p>CTE Icon</p>
        <div [ngClass]="{ 'disabled-section': data?.isDefault }">
          <div class="grid grid-cols-10 gap-2">
            @for (icon of cteIcons$(); track icon) {
              <div
                class="icon-box"
                [ngClass]="{
                  selected: selectedIcon === icon,
                }"
                (click)="selectedIcon = icon; uploadedIcon = ''">
                <img [src]="icon" [alt]="icon" />
              </div>
            }
          </div>
        </div>
      </div>

      <div>
        <p>Upload Custom CTE Icon</p>
        <div class="flex" [ngClass]="{ 'disabled-section': data?.isDefault }">
          <div class="upload-container">
            <input
              #fileInput
              type="file"
              [id]="'icon-upload'"
              class="file-input"
              (change)="upload($event)"
              accept="image/svg+xml" />
            <label [for]="'icon-upload'" class="upload-label">
              <i class="pi pi-upload"></i>
              <p><small>Upload Custom Icon (.SVG)</small></p>
            </label>
          </div>
          @if (uploadedIcon !== '') {
            <div
              class="relative p-[50px] flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
              <img [src]="uploadedIcon" class="w-100" />
              <span
                class="absolute top-[15px] right-[15px] transform translate-x-1/2 -translate-y-1/2 cursor-pointer bg-white text-red-600 rounded-full p-1"
                (click)="uploadedIcon = ''"
                title="Remove Icon">
                ✕
              </span>
            </div>
          }
          @if (this.isUploading) {
            <div
              class="flex items-center justify-center w-[150px] h-[150px] ml-[10px] progress-spinner-container">
              <p-progressSpinner />
            </div>
          }
        </div>
      </div>

      <div class="flex items-center gap-3">
        <div class="mt-[5px]">
          <p-inputSwitch
            name="multiple"
            formControlName="multipleKDEsRecords" />
        </div>
        <label for="multiple">Enable Multiple KDE Records</label>
      </div>

      <div class="flex items-center gap-3">
        <div class="mt-[5px]">
          <p-inputSwitch
            name="cteType"
            (onChange)="toggleCTEType($event)"
            formControlName="type" />
        </div>
        <label for="cteType">Recall CTE </label>
      </div>

      <div class="flex items-center gap-3">
        <div class="mt-[5px]">
          <p-inputSwitch
            name="location"
            (onChange)="toggleMapVisible($event)"
            formControlName="isMapVisible" />
        </div>
        <label for="location">Enable CTE Location</label>
      </div>

      @if (form.get('isMapVisible')?.value) {
        <div>
          <input
            #searchBox
            type="text"
            class="w-100"
            placeholder="Search for a location"
            (input)="filterPlaces($event)"
            (focus)="filterPlaces($event)" />

          <ul *ngIf="filteredPlaces.length > 0" class="autocomplete-dropdown">
            <li
              *ngFor="let place of filteredPlaces"
              (click)="selectPlace(place)">
              {{ place.description }}
            </li>
          </ul>
          <google-map
            #googleMap
            *ngIf="mapSelectedPosition && mapCenter"
            height="300px"
            width="100%"
            [center]="mapCenter"
            [options]="mapOptions"
            (mapClick)="addMarker($event)">
            <map-marker
              [position]="mapSelectedPosition"
              [options]="mapOptions.animation">
            </map-marker>
          </google-map>
        </div>

        <div class="flex items-center justify-between">
          <div class="w-full">
            <p>Scan Radius</p>
            <p-slider
              formControlName="radius"
              styleClass="w-85"
              (onChange)="onSliderChange($event)"></p-slider>
          </div>
        </div>
      }
    </form>
  </div>
</div>
