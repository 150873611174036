<div class="grid grid-cols-3 gap-5 mt-4">
  <div>
    <div>
      <p class="font-bold m-0">Current Plan</p>
      <p class="text-sm mt-0">General Information of the subscribed plan</p>

      @if (user$()?.currentPlan) {
        <div class="bg-white p-4 rounded mb-3">
          <p class="text-sm m-0">Plan</p>
          <p class="font-bold m-0">
            {{ user$()?.currentPlan?.productDetails?.name }}
          </p>
          <small class="block mb-3"
            >{{ user$()!.currentPlan!.plan.amount | currency }} /
            {{
              user$()!.currentPlan!.plan.interval_count > 1
                ? user$()!.currentPlan!.plan.interval_count
                : ''
            }}
            {{ user$()!.currentPlan!.plan.interval }}</small
          >
          <small class="bg-primary p-2 rounded text-white">{{
            user$()!.subscriptionInfo.subscriptionStatus.toUpperCase()
          }}</small>
          <p class="m-0 mt-3">
            <small
              >Valid till
              {{
                user$()?.subscription?.currentPeriodEnd
                  | formatDate: 'MMM dd, yyyy'
              }}</small
            >
          </p>
          <div class="mt-4">
            <p-button
              [routerLink]="'/subscription/plans'"
              label="Change Plan"
              outlined="true"
              icon="pi pi-refresh" />
          </div>
        </div>
      } @else {
        <div class="bg-white p-5 rounded mb-3 text-center">
          <span class="text-white bg-black p-2 rounded text-sm"
            >Not Subscribed</span
          >
          <div class="mt-4">
            <p-button
              [routerLink]="'/subscription/plans'"
              label="View Plans"
              outlined="true"
              icon="pi pi-eye" />
          </div>
        </div>
      }
    </div>
  </div>
  <div>
    <div>
      <p class="font-bold m-0">Select Payment Method</p>
      <p class="text-sm mt-0">Select a payment method to continue</p>

      <div class="bg-white p-4 rounded mb-5">
        <p class="font-bold m-0">Credit / Debit Card</p>
        <p class="text-sm mt-0">
          We accept Master Card, Visa & American Express
        </p>

        <app-cards-list></app-cards-list>
      </div>
    </div>
  </div>
  <div>
    <div class="bg-white p-5 rounded">
      <p class="text-xl font-bold">Billing Summary</p>
      @if (billings$()) {
        <p-tabs value="0">
          <p-tablist>
            <p-tab value="0">Upcoming</p-tab>
            <p-tab value="1">Past</p-tab>
          </p-tablist>
          <p-tabpanels>
            <p-tabpanel value="0">
              @if (billings$()?.upcoming) {
                <p class="m-0 mb-1 font-bold">Invoice Details</p>
                @for (line of billings$()!.upcoming?.lines?.data; track line) {
                  <p class="m-0 mb-1 italic">
                    {{ line.description }}:
                    <span class="font-bold">{{ line.amount | currency }}</span>
                  </p>
                }
                <hr />
                <div class="flex items-center justify-between">
                  <p class="m-0 mb-1 font-bold">Subtotal</p>
                  <p class="m-0 mb-1">
                    {{ billings$()!.upcoming!.subtotal | currency }}
                  </p>
                </div>
                @if (
                  billings$()!.upcoming!.total_discount_amounts &&
                  billings$()!.upcoming!.total_discount_amounts.length > 0
                ) {
                  <div class="flex items-center justify-between">
                    <p class="m-0 mb-1 font-bold">Discounted Amount</p>
                    <p class="m-0 mb-1">
                      -
                      {{
                        billings$()!.upcoming!.total_discount_amounts[0].amount
                          | currency
                      }}
                    </p>
                  </div>
                }
                <hr />
                <div class="flex items-center justify-between">
                  <p class="m-0 mb-1 font-bold">Amount Due</p>
                  <p class="m-0 mb-1 font-bold">
                    {{ billings$()!.upcoming!.total | currency }}
                  </p>
                </div>
                <div class="flex items-center justify-between">
                  <p class="m-0 mb-1 italic text-sm">
                    This invoice will be charged on
                    {{ billings$()!.upcoming!.created | formatDate }}
                  </p>
                </div>
              }
            </p-tabpanel>
            <p-tabpanel value="1">
              @if (billings$()?.past && billings$()!.past!.length > 0) {
                @for (bill of billings$()!.past; track bill) {
                  @if (bill.subtotal !== 0) {
                    <p class="m-0 mb-1 font-bold">Invoice Details</p>
                    @for (line of bill?.lines?.data; track line) {
                      <p class="m-0 mb-1 italic">
                        {{ line.description }}:
                        <span class="font-bold">{{
                          line.amount | currency
                        }}</span>
                      </p>
                    }
                    <hr />
                    <div class="flex items-center justify-between">
                      <p class="m-0 mb-1 font-bold">Subtotal</p>
                      <p class="m-0 mb-1">{{ bill.subtotal | currency }}</p>
                    </div>
                    @if (
                      bill.total_discount_amounts &&
                      bill.total_discount_amounts.length > 0 &&
                      bill.total_discount_amounts[0].amount !== 0
                    ) {
                      <div class="flex items-center justify-between">
                        <p class="m-0 mb-1 font-bold">Discounted Amount</p>
                        <p class="m-0 mb-1">
                          -
                          {{ bill.total_discount_amounts[0].amount | currency }}
                        </p>
                      </div>
                    }
                    <hr />
                    <div class="flex items-center justify-between">
                      <p class="m-0 mb-1 font-bold" *ngIf="bill.total > 0">
                        Amount Paid
                      </p>
                      <p class="m-0 mb-1 font-bold" *ngIf="bill.total < 0">
                        Amount Credited
                      </p>
                      <p class="m-0 mb-1">{{ bill.total | currency }}</p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="m-0 mb-1 font-bold">Payment Date</p>
                      <p class="m-0 mb-1">{{ bill.created | formatDate }}</p>
                    </div>
                    <div class="mt-4 mb-4">
                      <p-button
                        (click)="downloadInvoice(bill.invoice_pdf)"
                        label="Download Invoice"
                        outlined="true"
                        icon="pi pi-download" />
                    </div>
                  }
                }
              }
            </p-tabpanel>
          </p-tabpanels>
        </p-tabs>
      } @else {
        <div class="bg-white p-5 rounded mb-3 text-center">
          <span class="text-white bg-black p-2 rounded text-sm"
            >No Billing Records</span
          >
        </div>
      }
    </div>
  </div>
</div>
