import { AddCardParams, SubscribeParams } from './subscription.model';

const CONTEXT = '[Subscription Store]';

export class GetPlans {
  static readonly type = `${CONTEXT} Get Plans`;
}

export class GetCards {
  static readonly type = `${CONTEXT} Get Cards`;
}

export class AddCard {
  static readonly type = `${CONTEXT} Add Card`;
  constructor(public payload: AddCardParams) {}
}

export class Subscribe {
  static readonly type = `${CONTEXT} Subscribe`;
  constructor(public payload: SubscribeParams) {}
}
