import { PaginationParams } from '../general-store/general.model';

const CONTEXT = '[ReportStore]';

export class GetReport {
  static readonly type = `${CONTEXT} Get Report`;
  constructor(
    public productId: number,
    public report: string
  ) {}
}

export class ClearReport {
  static readonly type = `${CONTEXT} Clear Report`;
}
