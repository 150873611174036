import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import { ProductFiltersComponent } from '../../shared-components/product-filters/product-filters.component';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { User } from '../../../../store/users-store/user.model';
import { Store } from '@ngxs/store';
import {
  ResendOtp,
  ResetUserState,
  SendOtp,
  ValidateOtp,
} from '../../../../store/users-store/user.actions';
import { ObjectToUrlParams } from '../../../services/global.service';
import { CtesKdesComponent } from '../../../pages/settings/ctes-kdes/ctes-kdes.component';
import {
  Header,
  Pagination,
  UploadedFile,
} from '../../../../store/general-store/general.model';
import { RouterModule } from '@angular/router';
import {
  CountRecallItems,
  CreateRecall,
  GetRecallUsers,
  ResetRecall,
  SetFilters,
  UpdateRecall,
  UpdateRecallStatus,
} from '../../../../store/recall-management-store/recall-management.actions';
import { RecallManagementStateSelectors } from '../../../../store/recall-management-store/recall-management.selectors';
import {
  CountRecallItemsRequest,
  Recall,
  RecallFilters,
} from '../../../../store/recall-management-store/recall-management.model';
import { DocumentUploadComponent } from '../../shared-components/document-upload/document-upload.component';
import { DialogModule } from 'primeng/dialog';
import { NgOtpInputComponent } from 'ng-otp-input';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import {
  AddUploadedFile,
  ClearUploadedFiles,
} from '../../../../store/general-store/general.actions';
import { DataTableComponent } from '../../shared-components/data-table/data-table.component';
import { SelectModule } from 'primeng/select';

@Component({
  selector: 'app-initiate-recall-form',
  imports: [
    CommonModule,
    ProductFiltersComponent,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    SelectModule,
    MultiSelectModule,
    CtesKdesComponent,
    RouterModule,
    DocumentUploadComponent,
    DialogModule,
    NgOtpInputComponent,
    DataTableComponent,
    CountdownComponent,
  ],
  templateUrl: './initiate-recall-form.component.html',
  styleUrl: './initiate-recall-form.component.scss',
})
export class InitiateRecallFormComponent {
  @ViewChild('cd', { static: false }) countdown: CountdownComponent | undefined;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput:
    | NgOtpInputComponent
    | undefined;
  @Input() type?: string = '';
  @Output() actionSuccess = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter();

  steps: any[];
  selectedStepIndex: number = 0;

  otpDialogVisible: boolean = false;
  otp: string | null = null;
  timeLeft = 300;
  resendDisabled = true;

  showResult = false;
  form: FormGroup;
  severityLevels = ['LOW', 'MEDIUM', 'HIGH'];

  first = 0;
  rows = 10000;
  sortBy = 'firstName';
  sortOrder = -1;
  search = '';

  pagination$: Signal<Pagination> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecallUsersPaginationData
  );
  isProcessing$: Signal<boolean> = this.store.selectSignal(
    RecallManagementStateSelectors.isProcessing
  );
  headers$: Signal<Header[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecallUsersHeaders
  );
  currUser$ = this.store.selectSignal(UserStateSelectors.getUser);
  isRecallOtpVerified$: Signal<boolean> = this.store.selectSignal(
    UserStateSelectors.isRecallOtpVerified
  );
  users$: Signal<User[]> = this.store.selectSignal(UserStateSelectors.getUsers);
  authProcessing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  countRecallItems$: Signal<number | null> = this.store.selectSignal(
    RecallManagementStateSelectors.countRecallItems
  );
  filters$: Signal<RecallFilters> = this.store.selectSignal(
    RecallManagementStateSelectors.getFilters
  );
  recall$: Signal<Recall | null> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecall
  );
  recallUsers$: Signal<User[]> = this.store.selectSignal(
    RecallManagementStateSelectors.getRecallUsers
  );

  constructor(
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private store: Store
  ) {
    this.countdown?.stop();
    this.steps = [
      { label: 'Find Product', icon: 'pi pi-search' },
      { label: 'Recall Details', icon: 'pi pi-question-circle' },
      { label: 'CTEs / KDEs', icon: 'pi pi-align-justify' },
      { label: 'Recall Users', icon: 'pi pi-users' },
      { label: 'Summary', icon: 'pi pi-book' },
    ];

    this.form = this.fb.group({
      recallName: ['', [Validators.required]],
      authorizedUsers: ['', [Validators.required]],
      severityLevel: ['', [Validators.required]],
      recallReason: ['', [Validators.required]],
      descriptionForEmailAndSms: ['', [Validators.required]],
      productScanMessage: ['', [Validators.required]],
      activityFeed: ['', [Validators.required]],
      attachments: [[]],
    });

    this.store.dispatch(
      new GetRecallUsers({
        first: 0,
        rows: this.rows,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder > 0 ? 'ASC' : 'DESC',
        search: this.search,
        filters: ObjectToUrlParams({}),
      })
    );

    effect(() => {
      if (this.recall$()) {
        this.form.patchValue({
          recallName: this.recall$()?.recallName,
          authorizedUsers: this.recall$()?.authorizedUsers.map(u => u.userId),
          severityLevel: this.recall$()?.severityLevel,
          recallReason: this.recall$()?.recallReason,
          descriptionForEmailAndSms: this.recall$()?.descriptionForEmailAndSms,
          productScanMessage: this.recall$()?.productScanMessage,
          activityFeed: this.recall$()?.activityFeed,
          attachments: this.recall$()?.attachments.map(a => a.url),
        });
        this.store.dispatch(new ClearUploadedFiles());
        this.recall$()?.attachments.forEach(att => {
          const uploadedFiles: UploadedFile = {
            objectURL: att.url,
          };
          this.store.dispatch(
            new AddUploadedFile(uploadedFiles, 'recallDocument')
          );
        });
      }
      if (this.isRecallOtpVerified$() && this.recall$()) {
        this.store
          .dispatch(
            new UpdateRecallStatus(this.recall$()!.id, {
              status: 'PENDING',
              approvedOrRejectReason: 'Recall submitted',
            })
          )
          .subscribe(() => {
            this.ngOtpInput?.setValue('');
            this.otp = null;
            this.otpDialogVisible = false;
            this.store.dispatch(new ResetRecall());
            this.store.dispatch(new ResetUserState());
            this.actionSuccess.emit(true);
          });
      }
    });
  }

  onOtpDialogClose() {
    this.otp = null;
    this.ngOtpInput?.setValue('');
    this.countdown?.stop();
  }

  getAuthorizedUsers(users: Recall['authorizedUsers'] | undefined) {
    if (!users) return;
    return users.map(u => u.user.firstName).join(', ');
  }

  getAttachments(attachments: Recall['attachments'] | undefined) {
    if (!attachments) return;
    return attachments.map(u => u.url.substring(u.url.lastIndexOf('/') + 1));
  }

  onUpload(files: UploadedFile[]) {
    this.form.get('attachments')?.patchValue(files.map(f => f.objectURL));
  }

  setFilters(event: any) {
    this.store.dispatch(new SetFilters(event));
  }

  countProductItems() {
    const filters = this.filters$();
    if (filters?.kde && filters?.kdeValues.length > 0) {
      const payload: CountRecallItemsRequest = {
        filters: [
          {
            kdeId: filters.kde.id,
            values: filters.kdeValues,
          },
        ],
      };
      this.store.dispatch(new CountRecallItems(payload));
    }
  }

  nextStep(showConfirmation: boolean) {
    if (showConfirmation) {
      this.confirmationService.confirm({
        header: `RECALL CONFIRMATION`,
        message: `Are you sure you want to recall these ${this.countRecallItems$()} item(s)?`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Continue',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: true,
        rejectLabel: 'Cancel',
        rejectButtonStyleClass: 'p-button-secondary',
        accept: () => {
          if (this.selectedStepIndex < this.steps.length - 1) {
            this.selectedStepIndex++;
          }
        },
      });
    } else {
      if (this.selectedStepIndex < this.steps.length - 1) {
        this.selectedStepIndex++;
      }
    }
  }

  previousStep() {
    if (this.selectedStepIndex > 0) {
      this.selectedStepIndex--;
    }
  }

  navigateToStep(index: number) {
    if (
      index === 1 &&
      !this.countRecallItems$() &&
      this.filters$().kdeValues.length === 0
    ) {
      return;
    }
    if (index > 1 && !this.recall$()) {
      return;
    }
    this.selectedStepIndex = index;
  }

  sendOtp() {
    this.countdown?.restart();
    this.store.dispatch(new SendOtp('RECALL')).subscribe(() => {
      this.otpDialogVisible = true;
    });
  }

  submitRecall() {
    if (this.otp && this.otp.length === 4) {
      this.store.dispatch(
        new ValidateOtp(this.currUser$()?.id ?? 0, parseInt(this.otp), 'RECALL')
      );
    }
  }

  createUpdateRecall() {
    const filters = this.filters$();
    if (filters.product) {
      if (!this.recall$()) {
        this.store
          .dispatch(
            new CreateRecall({
              recallName: this.form.value.recallName,
              recallReason: this.form.value.recallReason,
              descriptionForEmailAndSms:
                this.form.value.descriptionForEmailAndSms,
              severityLevel: this.form.value.severityLevel,
              activityFeed: this.form.value.activityFeed,
              productScanMessage: this.form.value.productScanMessage,
              productId: filters.product.id,
              authorizedUsers: this.form.value.authorizedUsers,
              attachments: this.form.value.attachments,
              filters,
            })
          )
          .subscribe(() => {
            this.refresh.emit();
            this.nextStep(false);
          });
      } else {
        this.store
          .dispatch(
            new UpdateRecall(this.recall$()!.id, {
              recallName: this.form.value.recallName,
              recallReason: this.form.value.recallReason,
              descriptionForEmailAndSms:
                this.form.value.descriptionForEmailAndSms,
              severityLevel: this.form.value.severityLevel,
              activityFeed: this.form.value.activityFeed,
              productScanMessage: this.form.value.productScanMessage,
              productId: filters.product.id,
              authorizedUsers: this.form.value.authorizedUsers,
              attachments: this.form.value.attachments,
              filters,
            })
          )
          .subscribe(() => {
            this.refresh.emit();
            this.nextStep(false);
          });
      }
    }
  }

  onOtpChange(value: string) {
    if (value === this.otp) return;
    this.otp = value;
  }

  resendOtp() {
    this.resendDisabled = true;
    this.store.dispatch(new ResendOtp(this.currUser$()?.id ?? 1));
    this.countdown?.restart();
  }

  countdownEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.resendDisabled = false;
    }
  }
}
