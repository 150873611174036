<div class="flex flex-col">
  <div class="sticky top-0 z-10 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form">
      <div>
        <p>Current Password</p>
        <p-iconField iconPosition="right">
          <p-inputIcon
            (click)="passwordVisibility = !passwordVisibility"
            [styleClass]="
              passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
            " />
          <input
            [type]="passwordVisibility ? 'text' : 'password'"
            formControlName="currentPassword"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('currentPassword')?.invalid &&
              form.get('currentPassword')?.touched
            " />
        </p-iconField>
        <app-error-message
          [control]="form.get('currentPassword')"
          label="Current Password"></app-error-message>
      </div>

      <div>
        <p>New Password</p>
        <p-iconField iconPosition="right">
          <p-inputIcon
            (click)="passwordVisibility = !passwordVisibility"
            [styleClass]="
              passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
            " />
          <input
            [type]="passwordVisibility ? 'text' : 'password'"
            formControlName="newPassword"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('newPassword')?.invalid &&
              form.get('newPassword')?.touched
            " />
        </p-iconField>
        <app-error-message
          [control]="form.get('newPassword')"
          label="New Password"></app-error-message>
      </div>

      <div>
        <p>Re-type Password</p>
        <p-iconField iconPosition="right">
          <p-inputIcon
            (click)="passwordVisibility = !passwordVisibility"
            [styleClass]="
              passwordVisibility ? 'pi pi-eye' : 'pi pi-eye-slash'
            " />
          <input
            [type]="passwordVisibility ? 'text' : 'password'"
            formControlName="retypePassword"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('retypePassword')?.invalid &&
              form.get('retypePassword')?.touched
            " />
        </p-iconField>
        <app-error-message
          [control]="form.get('retypePassword')"
          label="Re-type Password"></app-error-message>
      </div>

      <div>
        @for (validation of passwordValidations; track $index) {
          <p class="password-text flex items-center !mb-1">
            <span
              class="password-icon mr-2"
              [class.text-teal-700]="validation.valid"
              [class.text-red-600]="!validation.valid">
              @if (!validation.valid) {
                <i class="pi pi-times-circle"></i>
              } @else {
                <i class="pi pi-verified"></i>
              }
            </span>
            <span>
              {{ validation.message }}
            </span>
          </p>
        }
        <p class="password-text flex items-center !mb-1">
          <span
            class="password-icon mr-2"
            [class.text-teal-700]="
              !form.get('retypePassword')?.hasError('mismatch')
            "
            [class.text-red-600]="
              form.get('retypePassword')?.hasError('mismatch')
            ">
            <i
              class="pi"
              [ngClass]="{
                'pi-times-circle': form
                  .get('retypePassword')
                  ?.hasError('mismatch'),
                'pi-verified': !form
                  .get('retypePassword')
                  ?.hasError('mismatch'),
              }"></i>
          </span>
          <span *ngIf="form.get('retypePassword')?.hasError('mismatch')"
            >Passwords do not match</span
          >
          <span *ngIf="!form.get('retypePassword')?.hasError('mismatch')"
            >Passwords are matching</span
          >
        </p>
      </div>
    </form>
  </div>
</div>
