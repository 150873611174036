<div class="w-full p-8">
  <p-confirmDialog></p-confirmDialog>
  <div>
    <p class="text-2xl font-bold text-center">Billing & Payment Details</p>
    <p class="text-center">
      Provide your billing and payment details so that we can subscribe you to
      your desired plan!
    </p>
  </div>
  <p-stepper>
    <p-stepperPanel header="Billing Address">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index">
        <form [formGroup]="billingAddressForm">
          <div class="grid grid-cols-2 gap-4 bg-slate-50 p-9 rounded-lg">
            <div>
              <p class="text-base font-normal text-black mb-1 mt-0">
                First Name
              </p>
              <input pInputText class="w-full" formControlName="firstName" />
            </div>
            <div>
              <p class="text-base font-normal text-black mb-1 mt-0">
                Last Name
              </p>
              <input pInputText class="w-full" formControlName="lastName" />
            </div>
            <div class="col-span-2">
              <p class="text-base font-normal text-black mb-1 mt-0">Email</p>
              <input pInputText class="w-full" formControlName="email" />
            </div>
            <div class="flex pt-4 justify-between items-center col-span-2">
              <div>
                <small class="block text-xs">Powered by</small>
                <img
                  class="w-[100px]"
                  [src]="bucketUrl + '/images/stripe-logo.png'"
                  alt="" />
              </div>
              <div class="flex gap-2">
                <p-button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="left"
                  (onClick)="nextCallback.emit(); initializeStripe()" />
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Payment Method">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index">
        <div class="bg-slate-50 p-9 rounded-lg">
          <form [formGroup]="cardDetailsForm">
            <div>
              <label class="block mb-2">Name on Card</label>
              <input
                type="text"
                formControlName="nameOnCard"
                placeholder="Name on Card"
                pInputText
                class="w-full"
                [class.input-danger]="
                  cardDetailsForm.get('nameOnCard')?.invalid &&
                  cardDetailsForm.get('nameOnCard')?.touched
                " />
              <app-error-message
                [control]="cardDetailsForm.get('nameOnCard')"
                label="Name on Card"></app-error-message>
            </div>
            <div class="mb-5">
              <label class="block mb-2">Card Details</label>
              <div
                id="card-element"
                class="border-solid p-3 rounded border-[1px] border-gray-400 bg-white"></div>
            </div>

            <div *ngIf="errorMessage" role="alert">
              {{ errorMessage }}
            </div>

            <div class="flex pt-4 justify-between items-center col-span-2">
              <div>
                <small class="block text-xs">Powered by</small>
                <img
                  class="w-[100px]"
                  [src]="bucketUrl + '/images/stripe-logo.png'"
                  alt="" />
              </div>
              <div class="flex gap-2">
                <p-button
                  label="Go Back"
                  icon="pi pi-arrow-left"
                  iconPos="right"
                  (onClick)="prevCallback.emit()" />
                <p-button
                  [disabled]="cardDetailsForm.invalid"
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="left"
                  (onClick)="createPaymentMethod($event, nextCallback)" />
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Confirmation">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index">
        @if (selectedPlan) {
          <div class="flex justify-center">
            <div
              class="w-[50%] bg-slate-50 border-[1px] border-gray-400 border-solid rounded p-5">
              <p class="font-bold mb-0">Order Review</p>
              <div class="flex items-center justify-between">
                <p>{{ selectedPlan.productDetails.name }}</p>
                <p>${{ selectedPlan.amount / 100 }} USD</p>
              </div>
            </div>
          </div>

          <div class="flex justify-center mt-3">
            <div
              class="w-[50%] bg-slate-50 border-[1px] border-gray-400 border-solid rounded p-5">
              <p class="font-bold mb-0">Checkout Summary</p>
              <div class="flex items-center justify-between">
                <p>Subtotal</p>
                <p>${{ selectedPlan.amount / 100 }} USD</p>
              </div>
              <hr />
              <div class="flex items-center justify-between">
                <p>Total</p>
                <p>${{ selectedPlan.amount / 100 }} USD</p>
              </div>
            </div>
          </div>

          <div class="flex justify-center mt-3">
            <div class="w-[50%]">
              <p-checkbox [binary]="true" inputId="termsConditions" />
              <label for="termsConditions">
                I agree to all the
                <a
                  class="text-primary-color underline"
                  href="https://www.regenesis.ag/terms-and-conditions/"
                  target="_blank"
                  >Terms & Conditions</a
                >
                and
                <a
                  class="text-primary-color underline"
                  href="https://www.regenesis.ag/privacy-policy/"
                  target="_blank"
                  >Privacy Policy</a
                >
              </label>
            </div>
          </div>

          <div class="flex justify-center mt-5">
            <div class="w-[50%]">
              <p-button [disabled]="isLoading$()" (click)="subscribe()"
                >Pay ${{ selectedPlan.amount / 100 }} USD</p-button
              >
            </div>
          </div>

          <div class="flex justify-center mt-5">
            <div class="w-[50%] text-center">
              <small class="block text-xs mb-0">Powered by</small>
              <img
                class="w-[100px]"
                [src]="bucketUrl + '/images/stripe-logo.png'"
                alt="" />
            </div>
          </div>
        }
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</div>
