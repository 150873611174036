<form [formGroup]="form" class="pt-6 full-height-form">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <p class="text-base font-normal">Organization Name</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('name')?.invalid && form.get('name')?.touched
        "
        placeholder="Organization Name"
        formControlName="name" />
      <app-error-message
        [control]="form.get('name')"
        label="Organization Name"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Organization Type</p>
      <p-select
        formControlName="type"
        [options]="organizationTypes$()"
        optionValue="value"
        optionLabel="key"
        placeholder="Select Organization Type"
        class="w-full"
        [class.input-danger]="
          form.get('type')?.invalid && form.get('type')?.touched
        " />
      <app-error-message
        [control]="form.get('type')"
        label="Organization Type"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Country</p>
      <p-select
        formControlName="countryCode"
        [options]="countries"
        optionLabel="name"
        optionValue="id"
        placeholder="Select Country"
        class="w-full"
        (onChange)="getCities($event.value)"
        [virtualScroll]="true"
        [virtualScrollItemSize]="30"
        filter="true"
        filterBy="name"
        [class.input-danger]="
          form.get('countryCode')?.invalid && form.get('countryCode')?.touched
        " />
      <app-error-message
        [control]="form.get('countryCode')"
        label="Country"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">City</p>
      <p-select
        formControlName="city"
        [options]="form.get('countryCode')?.valid ? cities$() : []"
        optionLabel="name"
        optionValue="id"
        placeholder="Select City"
        class="w-full"
        filter="true"
        filterBy="name"
        [class.input-danger]="
          form.get('city')?.invalid && form.get('city')?.touched
        " />
      <app-error-message
        [control]="form.get('city')"
        label="City"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">GLN (Global Location Number)</p>
      <input
        pInputText
        type="text"
        class="w-full"
        [class.input-danger]="
          form.get('gln')?.invalid && form.get('gln')?.touched
        "
        placeholder="0123456789123"
        formControlName="gln" />
      <app-error-message
        [control]="form.get('gln')"
        label="GLN"></app-error-message>
    </div>

    <div>
      <p class="text-base font-normal">Organization Email Address</p>
      <input
        pInputText
        type="organizationEmail"
        class="w-full"
        [class.input-danger]="
          form.get('organizationEmail')?.invalid &&
          form.get('organizationEmail')?.touched
        "
        placeholder="example@example.com"
        formControlName="organizationEmail" />
      <app-error-message
        [control]="form.get('organizationEmail')"
        label="Organization Email Address"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Organization Phone</p>
      <p-inputGroup>
        <p-inputGroupAddon>
          <strong>{{ getCountryCode(form.get('countryCode')?.value) }}</strong>
        </p-inputGroupAddon>
        <input
          type="text"
          formControlName="phone"
          placeholder="1233445567"
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('phone')?.invalid && form.get('phone')?.touched
          " />
      </p-inputGroup>
      <app-error-message
        [control]="form.get('phone')"
        label="Organization Phone"></app-error-message>
    </div>
    <div class="col-span-2">
      <p class="text-base font-normal">Logo</p>
      <app-image-upload
        [singleMode]="true"
        [fileType]="'logo'"></app-image-upload>
    </div>
    <div>
      <p class="text-base font-normal">Contact Person</p>
      <input
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('contactPerson')?.invalid &&
          form.get('contactPerson')?.touched
        "
        placeholder="Contact Person"
        formControlName="contactPerson" />
      <app-error-message
        [control]="form.get('contactPerson')"
        label="Contact Person"></app-error-message>
    </div>
    <div>
      <p class="text-base font-normal">Contact Person Designation</p>
      <p-select
        formControlName="contactPersonDesignation"
        [options]="designations$()"
        optionLabel="key"
        optionValue="value"
        placeholder="Select Contact Person Designation"
        class="w-full"
        [class.input-danger]="
          form.get('contactPersonDesignation')?.invalid &&
          form.get('contactPersonDesignation')?.touched
        " />
      <app-error-message
        [control]="form.get('contactPersonDesignation')"
        label="Contact Person Designation"></app-error-message>
    </div>
    <div class="col-span-2">
      <p class="text-base font-normal">Business Address</p>
      <textarea
        type="text"
        formControlName="address"
        placeholder="Chicago"
        pInputText
        class="w-full"
        [class.input-danger]="
          form.get('address')?.invalid && form.get('address')?.touched
        "></textarea>
      <app-error-message
        [control]="form.get('address')"
        label="Business Address"></app-error-message>
    </div>
  </div>
</form>
<app-right-form-footer
  [type]="type"
  [formValid]="this.form.valid"
  (callAction)="action()"
  [processing]="processing$()"
  (callCancel)="actionSuccess.emit(false)">
</app-right-form-footer>
