import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { Store } from '@ngxs/store';
import {
  City,
  Country,
  Data,
  UploadedFile,
} from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import {
  AddUploadedFile,
  ClearUploadedFiles,
  GetCities,
} from '../../../../store/general-store/general.actions';
import { ImageUploadComponent } from '../../shared-components/image-upload/image-upload.component';
import {
  CreateOrganization,
  UpdateOrganization,
} from '../../../../store/organizations-store/organizations.actions';
import { Organization } from '../../../../store/organizations-store/organizations.model';
import { take } from 'rxjs';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RightFormFooterComponent } from '../../shared-components/right-form-footer/right-form-footer.component';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { SelectModule } from 'primeng/select';

@Component({
  selector: 'app-add-organization-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    SelectModule,
    ImageUploadComponent,
    InputGroupModule,
    InputGroupAddonModule,
    RightFormFooterComponent,
  ],
  templateUrl: './add-organization-form.component.html',
  styleUrl: './add-organization-form.component.scss',
})
export class AddOrganizationFormComponent implements OnChanges {
  @Input() data?: Organization;
  @Input() id?: number;
  @Input() type?: string;
  @Output() actionSuccess = new EventEmitter<boolean>();

  form: FormGroup;
  countries: Country[] = [];
  organizationTypes$: Signal<Data[]> = this.store.selectSignal(
    GeneralStateSelectors.getOrganizationTypes
  );
  designations$: Signal<Data[]> = this.store.selectSignal(
    GeneralStateSelectors.getDesignations
  );
  cities$: Signal<City[]> = this.store.selectSignal(
    GeneralStateSelectors.getCities
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);
  processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      type: [null, [Validators.required]],
      countryCode: [null, [Validators.required]],
      city: [null, [Validators.required]],
      contactPerson: [null],
      phone: [
        null,
        [
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      gln: [
        null,
        [
          Validators.pattern(/^\d{13}$/), // GLN validation: exactly 13 numeric digits
        ],
      ],
      organizationEmail: [null, [Validators.required, Validators.email]],
      address: [null],
      contactPersonDesignation: [null],
      logo: [null, [Validators.required]],
    });

    effect(() => {
      this.form.patchValue({
        logo: this.uploadedFiles$()['logo']
          ? this.uploadedFiles$()['logo'][0]?.objectURL
          : null,
      });
    });

    this.store
      .select(GeneralStateSelectors.getCountries)
      .pipe(take(1))
      .subscribe(res => {
        this.countries = res;
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.action();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      if (this.data?.countryId) {
        this.getCities(this.data?.countryId);
      }
      if (this.data?.logo) {
        const uploadedFiles: UploadedFile = {
          objectURL: this.data?.logo,
        };
        this.store.dispatch(new AddUploadedFile(uploadedFiles, 'logo'));
      }
      this.setFormValues(this.data);
    }
  }

  action() {
    if (this.form.invalid) return;
    const trimmedValue = this.form.get('name')?.value?.trim();
    this.form.get('name')?.setValue(trimmedValue);
    if (!trimmedValue) {
      return;
    }
    const values = this.form.value;
    const payload = this.getOrganizationPayload(values);

    const action$ =
      this.type === 'ADD'
        ? this.store.dispatch(new CreateOrganization(payload))
        : this.store.dispatch(new UpdateOrganization(payload, this.id!));

    action$.subscribe({
      next: () => {
        this.handleSuccess(); // Call handleSuccess instead of inline logic
      },
      error: err => {
        console.error('Error while adding/updating organization:', err);
      },
    });
  }

  private getOrganizationPayload(values: any) {
    return {
      businessAddress: values.address,
      type: values.type,
      cityId: values.city,
      contactPerson: values.contactPerson,
      gln: values.gln === '' ? null : values.gln,
      email: values.organizationEmail,
      logo: values.logo,
      name: values.name,
      phone: values.phone === '' ? null : values.phone,
      countryId: values.countryCode,
      contactPersonDesignation: values.contactPersonDesignation,
    };
  }

  private setFormValues(data?: Organization): void {
    this.form.patchValue({
      name: data?.name || null,
      type: data?.type || null,
      countryCode: data?.countryId || null,
      city: data?.cityId || null,
      contactPerson: data?.contactPerson || null,
      phone: data?.phone || null,
      gln: data?.gln || null,
      organizationEmail: data?.email || null,
      address: data?.businessAddress || null,
      contactPersonDesignation: data?.contactPersonDesignation || null,
      logo: data?.logo || null,
    });
  }

  private handleSuccess(): void {
    this.resetForm();
    this.store.dispatch(new ClearUploadedFiles());
    this.actionSuccess.emit(true);
  }

  private resetForm(): void {
    this.form.reset({
      name: null,
      type: null,
      countryCode: null,
      city: null,
      contactPerson: null,
      phone: null,
      gln: null,
      organizationEmail: null,
      address: null,
      contactPersonDesignation: null,
      logo: null,
    });
  }

  getCities(countryId: number) {
    this.store.dispatch(new GetCities(countryId));
  }

  getCountryCode(id: string) {
    if (id === null) {
      return '-';
    }
    return this.countries.filter(c => c.id === Number(id))[0]?.dialCode;
  }
}
