import { Component, Signal } from '@angular/core';
import { Plan } from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Store } from '@ngxs/store';
import { GetPlans } from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GetUser } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';

@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [ButtonModule, RouterModule, ProgressSpinnerModule],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.scss',
})
export class PlansComponent {
  pageTitle = '';
  plans$: Signal<Plan[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getPlans
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.getPlans();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.store.dispatch(new GetUser());
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  isSubscribedPlan(plan: Plan): boolean {
    if (this.user$()?.currentPlan && plan.id === this.user$()?.currentPlan.id) {
      return true;
    } else {
      return false;
    }
  }
}
