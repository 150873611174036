<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <h2>Report: {{ report.replace('_', ' ').toUpperCase() }}</h2>
    <div class="d-flex">
      <p-button label="Back to Reports" (onClick)="redirect()" />
    </div>
  </div>

  <div class="mt-4">
    @if (report$()) {
      <p-table
        [columns]="report$()!.headers"
        [value]="report$()!.rows"
        #dt
        [paginator]="true"
        [rows]="5"
        [rowsPerPageOptions]="[5, 10, 20]">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let header of columns" class="w-auto whitespace-nowrap">
              {{ header | camelToSentence }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let column of columns">
              {{ rowData[column] | textTransform }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    } @else {
      <div
        class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
      </div>
    }
  </div>
</div>
