import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import { ReportResponse } from './report.model';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getReport = (
    productId: number,
    report: string
  ): Observable<ReportResponse> => {
    return this.http
      .get<ReportResponse>(
        `${this.apiUrl}/api/qr-product-data/${productId}/report`,
        {
          params: {
            report: report,
          },
        }
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching report: ' + error.message)
          );
        })
      );
  };
}
