import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {
  LocationAnalytics,
  LocationStats,
  ScanLocationAnalytics,
} from '../../../../store/dashboard-store/dashboard.model';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
@Component({
  selector: 'app-scan-map',
  standalone: true,
  imports: [GoogleMapsModule, MapMarker],
  templateUrl: './scan-map.component.html',
  styleUrl: './scan-map.component.scss',
})
export class ScanMapComponent implements OnChanges {
  @Input({ required: true }) data: LocationStats[] = [];

  mapOptions = {
    zoomControl: true,
    zoom: 3,
    maxZoom: 15,
    minZoom: 3,
    center: { lat: 0, lng: 0 }, // Center the map to show the whole world
  };
  markers: any = [];
  selectedMarker: any = null;
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['data'] &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.markers = [];
      this.data.forEach(d => {
        this.addMarker(d);
      });
    }
  }

  addMarker(data: LocationStats) {
    this.markers.push({
      position: {
        lat: data.latitude,
        lng: data.longitude,
      },
      label: {
        title: data.label,
        text: `${data.count} scans`,
      },
    });
  }

  openInfoWindow(marker: any, mapMarker: MapMarker) {
    this.selectedMarker = marker;
    this.infoWindow.open(mapMarker);
  }
}
