import { Component, effect, Signal } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Card } from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import {
  AddCard,
  GetCards,
} from '../../../../store/subscription-store/subscription.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { environment } from '../../../../environments/environment';
import { ErrorMessageComponent } from '../../../components/shared-components/error-message/error-message.component';
import { StripeService } from '../../../services/stripe.service';
import { Stripe, StripeCardElement } from '@stripe/stripe-js';
import { NotificationService } from '../../../utilities/notification.service';
import { Router, RouterModule } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [
    ButtonModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    RouterModule,
    ConfirmDialogModule,
  ],
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.scss',
  providers: [ConfirmationService],
})
export class PaymentMethodsComponent {
  cards$: Signal<Card[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getCards
  );
  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  selectedCard: Card | null = null;
  showCardDetailsForm: boolean = false;
  bucketUrl: string = environment.bucketUrl;
  stripe: Stripe | null = null;
  card: StripeCardElement | undefined;

  cardDetailsForm: FormGroup;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.getCards();
    this.cardDetailsForm = this.fb.group({
      nameOnCard: [
        `${this.user$()?.firstName} ${this.user$()?.lastName}`,
        [Validators.required],
      ],
    });
    effect(() => {
      if (this.cards$().length > 0) {
        this.selectedCard = this.cards$().find(c => c.isDefault) ?? null;
      }
    });
  }

  getCards() {
    this.store.dispatch(new GetCards());
  }

  createPaymentMethod() {
    if (this.card) {
      this.stripe
        ?.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.cardDetailsForm.get('nameOnCard')?.value,
          },
        })
        .then(pm => {
          this.store
            .dispatch(
              new AddCard({
                cardToken: pm!.paymentMethod!.id,
              })
            )
            .subscribe(() => {
              this.getCards();
              this.notificationService.openSuccessToast(
                'New card added successfully!'
              );
              this.showCardDetailsForm = false;
            });
        });
    }
  }

  toggleCardDetailsForm() {
    if (this.user$()?.currentPlan !== null) {
      if (this.showCardDetailsForm) {
        this.createPaymentMethod();
      } else {
        this.showCardDetailsForm = true;
        this.initializeStripe();
      }
    } else {
      this.confirmationService.confirm({
        header: `NO SUBSCRIPTION`,
        message: `Before adding a new card, you have to subscribe to a plan!`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'View Plans',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: false,
        accept: () => {
          this.router.navigateByUrl('/subscription/plans');
        },
      });
    }
  }

  initializeStripe() {
    setTimeout(() => {
      this.stripeService.initialize().then(stripe => {
        this.stripe = stripe;
        this.card = stripe?.elements().create('card');
        this.card?.mount('#card-element');
      });
    }, 1000);
  }
}
