<div class="flex flex-col">
  <div class="sticky top-0 z-10 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [title]="title"
      [actionLabel]="'Initiate Download'"
      (callCancel)="actionSuccess.emit()"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <app-qr-preview
      [product]="data.product"
      [qrConfig]="getProductQrSettings(data.productQrSetting)"
      [ctaConfig]="getProductCtaConfig(data.productQrSetting)"></app-qr-preview>

    @if (qrOrder$()) {
      @for (download of qrOrder$()?.qrOrderDownloads; track download) {
        <div
          class="flex justify-between items-center p-5 bg-white rounded mt-5">
          <div>
            <p class="m-0">{{ download.status }}</p>
            <p class="m-0">
              <small>{{ download.updatedAt | textTransform }}</small>
            </p>
          </div>
          <div>
            @if (download.status === 'COMPLETED') {
              <p-button
                icon="pi pi-download"
                (click)="downloadFile(download.downloadUrl)" />
            }
          </div>
        </div>
      }
    }
  </div>
</div>
