<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
  </div>
  <app-subscription-details></app-subscription-details>
  <app-stack-bar-chart
    [title]="'QR Analytics'"
    [showFilters]="true"
    [data]="qrMetrics$()?.data"
    (reload)="getQrMetrics($event)"></app-stack-bar-chart>
</div>
