import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { ReportStateModel } from './report.model';
import { ReportService } from './report.services';
import { ClearReport, GetReport } from './report.actions';

@State<ReportStateModel>({
  name: 'Report',
  defaults: {
    isProcessing: false,
    report: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ReportState {
  constructor(
    private reportService: ReportService,
    private notificationService: NotificationService
  ) {}

  @Action(GetReport)
  getReport({ patchState }: StateContext<ReportStateModel>, action: GetReport) {
    patchState({ isProcessing: true });
    return this.reportService.getReport(action.productId, action.report).pipe(
      tap(async res => {
        patchState({
          report: res.payload,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(ClearReport)
  clearReport(
    { patchState }: StateContext<ReportStateModel>,
    action: ClearReport
  ) {
    patchState({ isProcessing: false, report: null });
  }
}
