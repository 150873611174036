<div class="flex flex-col">
  <div class="sticky top-0 z-10 pt-5 bg-light">
    <app-right-form-header
      [processing]="disabling"
      [disabled]="form.invalid"
      [title]="title"
      (callAction)="action()"
      (callCancel)="form.reset(); actionSuccess.emit()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form">
      <div class="grid grid-cols-1 gap-4">
        <div>
          <p>Email</p>
          <input
            type="email"
            formControlName="email"
            placeholder="example@example.com"
            pInputText
            class="w-full"
            [class.input-danger]="
              form.get('email')?.invalid && form.get('email')?.touched
            " />
          <app-error-message
            *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
            [control]="form.get('email')"
            label="Email"></app-error-message>
        </div>
        <div>
          <p>Select Vendor</p>
          <p-select
            formControlName="vendorId"
            [options]="vendors$()"
            optionLabel="name"
            optionValue="id"
            placeholder="Select Vendor"
            class="w-full"
            [class.input-danger]="
              form.get('vendorId')?.invalid && form.get('vendorId')?.touched
            " />
          <app-error-message
            *ngIf="
              form.get('vendorId')?.invalid && form.get('vendorId')?.touched
            "
            [control]="form.get('vendorId')"
            label="Vendor"></app-error-message>
        </div>
        <div>
          <p>Select Role</p>
          <p-multiSelect
            [options]="roles$()"
            formControlName="roleId"
            optionLabel="name"
            placeholder="Select User Role"
            class="!w-full"
            [class.input-danger]="
              form.get('roleId')?.invalid && form.get('roleId')?.touched
            ">
            <ng-template let-role pTemplate="item">
              <span>{{ role.name | textTransform }}</span>
            </ng-template></p-multiSelect
          >
          <app-error-message
            *ngIf="form.get('roleId')?.invalid && form.get('roleId')?.touched"
            [control]="form.get('roleId')"
            label="Role"></app-error-message>
        </div>
      </div>
    </form>
  </div>
</div>
