<div class="relative">
  <div
    class="flex flex-col w-[70px] bg-dark text-white p-4 transition-all duration-75 height-100 relative"
    [ngClass]="{ '!w-[260px]': isExpanded }">
    <div
      (click)="isExpanded = !isExpanded"
      class="absolute top-[15%] right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer transition-transform duration-200 hover:scale-110 z-50">
      <span
        class="flex items-center justify-center cursor-pointer rounded bg-primary h-[24px] w-[24px] transition-transform duration-200 hover:scale-110"
        [ngClass]="
          isExpanded ? 'pi pi-angle-left' : 'pi pi-angle-right'
        "></span>
    </div>

    <div *ngIf="isExpanded" class="py-5">
      <img src="assets/logos/logo-light.svg" alt="" />
    </div>

    <div *ngIf="!isExpanded" class="py-5">
      <img src="assets/logos/logo-min-light.svg" alt="" />
    </div>
    <!-- 'active-link': (isMenuActive(item) && (!isExpanded || item?.items?.length === 0)), 'active-link-parent': (hasActiveChild(item) && isExpanded) -->
    <div class="mt-5">
      <nav class="flex flex-col mt-10">
        @for (item of items; track item) {
          @if (checkPermissions(item['slugs'])) {
            <div
              class="relative rounded"
              [ngClass]="{ 'w-[40px] text-center': !isExpanded }">
              <a
                [routerLink]="item.routerLink"
                [ngClass]="{
                  'active-link':
                    (isMenuActive(item) && !hasActiveChild(item)) ||
                    (isMenuActive(item) && !isExpanded),
                  'active-link-parent': hasActiveChild(item) && isExpanded,
                }"
                class="flex items-center text-white p-3 hover:bg-gray-800 rounded duration-100 cursor-pointer"
                (click)="toggleMenu(item, $event)">
                <span [class]="item.icon"></span>
                @if (isExpanded) {
                  <span class="ml-4 flex-grow mb-[-5px]">{{ item.label }}</span>
                  @if (item.items?.length) {
                    <span
                      class="pi"
                      [ngClass]="{
                        'pi-angle-down': item.expanded,
                        'pi-angle-right': !item.expanded,
                      }"></span>
                  }
                }
              </a>

              @if (item.items?.length && item.expanded && isExpanded) {
                <div
                  class="border-l border-gray-600 ml-3 transition-all duration-200 ease-in-out">
                  @for (subItem of item.items; track subItem) {
                    @if (checkPermissions(subItem['slugs'])) {
                      <a
                        [routerLink]="subItem.routerLink"
                        routerLinkActive="active-link"
                        class="flex items-center text-white p-2 m-1 hover:bg-gray-800 rounded duration-100">
                        <span class="ml-4 mb-[-2px]">{{ subItem.label }}</span>
                      </a>
                    }
                  }
                </div>
              }
            </div>
          }
        }
      </nav>
    </div>
  </div>
</div>
