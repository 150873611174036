import { Component, Signal } from '@angular/core';
import { Plan } from '../../../../store/subscription-store/subscription.model';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Store } from '@ngxs/store';
import {
  CancelSubscription,
  GetPlans,
} from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ConfirmationService } from 'primeng/api';
import { CurrencyPipe } from '../../../pipes/currency.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [
    ButtonModule,
    TooltipModule,
    RouterModule,
    ProgressSpinnerModule,
    CurrencyPipe,
    CommonModule,
  ],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.scss',
})
export class PlansComponent {
  pageTitle = '';
  activeTooltipIndex: any = null;
  showTooltip: boolean = false;
  plans$: Signal<Plan[]> = this.store.selectSignal(
    SubscriptionStateSelectors.getPlans
  );
  user$ = this.store.selectSignal(UserStateSelectors.getUser);

  isLoading$: Signal<boolean> = this.store.selectSignal(
    SubscriptionStateSelectors.isLoading
  );
  allOptions: {
    label: string;
    isTopUp?: boolean;
    isFeature?: boolean;
  }[] = [
    { label: 'Setup & Onboarding Fee (One-time)' },
    { label: 'Traceability' },
    { label: 'QR Codes per Month' },
    { label: 'QR Code Design' },
    { label: 'Users' },
    { label: 'Vendor Access', isFeature: true },
    { label: 'Item-Level Traceability', isFeature: true },
    { label: 'Validation Checks', isFeature: true },
    { label: 'Bulk Import Data', isFeature: true },
    { label: 'Customer Engagement Module', isFeature: true },
    { label: 'Recall Management Module', isFeature: true },
    { label: 'Template Module' },
    { label: 'Digital Expiry Counters', isFeature: true },
    { label: 'Smart Anti-Counterfeit QR Codes' },
    { label: 'Custom Reporting' },
    { label: 'Support' },
    { label: 'QR Code Top-Up', isTopUp: true },
    { label: 'Additional Users', isTopUp: true },
    { label: 'Additional Products', isTopUp: true },
  ];
  unifiedList: {
    key: string;
    label: string;
    isFeature?: boolean;
    isTopUp?: boolean;
  }[] = this.allOptions
    .filter(option => !option.isTopUp)
    .map((option, index) => ({
      key: `list${index + 1}_${option.label}`, // Create a sanitized key
      label: option.label,
      isFeature: option.isFeature,
    }));
  features = this.allOptions
    .filter(option => !option.isTopUp)
    .map(option => option.label);
  topUps = this.allOptions
    .filter(option => option.isTopUp)
    .map(option => option.label);
  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly confirmationService: ConfirmationService,
    private readonly router: Router
  ) {
    this.getPlans();
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  cancelSubscription() {
    if (this.user$()?.subscriptionInfo.subscriptionStatus === 'active') {
      this.confirmationService.confirm({
        header: `CANCEL SUBSCRIPTION`,
        message: `Are you sure want to cancel the subscription?`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Yes, proceed',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.store.dispatch(
            new CancelSubscription(this.user$()!.subscription.id.toString())
          );
        },
      });
    }
  }

  switchSubscription(plan: Plan) {
    if (this.user$()?.currentPlan) {
      this.confirmationService.confirm({
        header: `SWITCH SUBSCRIPTION`,
        message: `Are you sure want to switch to the new plan?`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'Yes, proceed',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-primary',
        accept: () => {
          this.router.navigateByUrl('/subscription/plans/subscribe/' + plan.id);
        },
      });
    } else {
      this.router.navigateByUrl('/subscription/plans/subscribe/' + plan.id);
    }
  }

  getPlanName(planName: string) {
    return planName.split(' ')[0];
  }
  getLabel(plan: Plan) {
    const user = this.user$();
    const currentPlan = user?.currentPlan;
    const status = user?.subscriptionInfo?.subscriptionStatus;

    if (!currentPlan) return 'Subscribe';
    if (status === 'canceled' && currentPlan.plan.id === plan.id)
      return 'Canceled';

    return plan.amount > currentPlan.plan.amount
      ? `Upgrade to ${plan.productDetails.name}`
      : plan.amount < currentPlan.plan.amount
        ? `Downgrade to ${plan.productDetails.name}`
        : `Cancel ${plan.productDetails.name}`;
  }
}
