import { Component, HostListener } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import { OrganizationTypes } from '../../../../constants';
import { DemoRequest } from '../../../../store/users-store/user.actions';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { ActionButtonComponent } from '../../shared-components/action-button/action-button.component';
import { Country } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { skipWhile, take } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SelectModule } from 'primeng/select';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';
@Component({
  selector: 'app-new-demo-request',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    SelectModule,
    ErrorMessageComponent,
    InputGroupModule,
    InputGroupAddonModule,
    CommonModule,
    IconFieldModule,
    InputIconModule,
    CheckboxModule,
    TooltipModule,
    NgScrollbarModule,
  ],
  templateUrl: './new-demo-request.component.html',
  styleUrls: ['./new-demo-request.component.scss'],
})
export class NewDemoRequestComponent {
  demoRequestForm: FormGroup;
  countries: Country[] = [];
  isOtherType: boolean = false;
  organizationTypes = OrganizationTypes;
  processing$ = this.store.selectSignal(
    UserStateSelectors.isUserStoreProcessing
  );
  selectedType: string | null = null;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.demoRequestForm = this.fb.group({
      fullName: ['', [Validators.required]],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      otherType: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      countryCode: ['', [Validators.required]],
      address: ['', [Validators.required]],
      privacyPolicy: [false, Validators.requiredTrue],
    });

    this.store
      .select(GeneralStateSelectors.getCountries)
      .pipe(skipWhile(res => !res || res.length === 0))
      .pipe(take(1))
      .subscribe(res => {
        this.countries = res;
      });
  }

  ngOnInit() {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.processing$()) {
      this.requestDemo();
    }
  }

  onTypeChange(selectedType: string) {
    const typeControl = this.demoRequestForm.get('type');
    this.selectedType = selectedType;
    const otherTypeControl = this.demoRequestForm.get('otherType');
    if (selectedType === 'OTHER') {
      otherTypeControl?.enable();
      typeControl?.setValue(selectedType);
      otherTypeControl?.setValue(selectedType);
    } else {
      typeControl?.setValue(selectedType);
      otherTypeControl?.setValue(selectedType);
      otherTypeControl?.clearValidators();
      otherTypeControl?.disable();
    }
    otherTypeControl?.updateValueAndValidity();
  }

  onOtherTypeInput(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    if (input) {
      const value = this.demoRequestForm.get('otherType')?.value;
      this.demoRequestForm.get('type')?.setValue(value);
    }
  }

  requestDemo() {
    if (this.demoRequestForm.invalid) return;
    const trimmedFullName = this.demoRequestForm.get('fullName')?.value?.trim();
    this.demoRequestForm.get('fullName')?.setValue(trimmedFullName);
    if (!trimmedFullName) {
      return;
    }

    const [firstName, lastName] = trimmedFullName.split(' ');

    this.store.dispatch(
      new DemoRequest({
        organizationName: this.demoRequestForm.get('name')?.value,
        type:
          this.demoRequestForm.get('type')?.value === 'OTHER'
            ? this.demoRequestForm.get('otherType')?.value
            : this.demoRequestForm.get('type')?.value,
        email: this.demoRequestForm.get('email')?.value,
        phone: `${this.demoRequestForm.get('countryCode')?.value.dialCode} ${this.demoRequestForm.get('phone')?.value}`,
        countryId: this.demoRequestForm.get('countryCode')?.value.id,
        businessAddress: this.demoRequestForm.get('address')?.value,
        personalFirstName: firstName,
        personalLastName: lastName || firstName,
      })
    );
  }
}
