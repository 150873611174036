<div class="flex items-center justify-between w-100">
  <h2>{{ title }}</h2>
  <div class="flex gap-2">
    <p-button
      class="dark"
      icon="pi pi-times"
      [outlined]="true"
      label="Cancel"
      (click)="clearUploadedFiles(); this.callCancel.emit()" />
    <p-button
      *ngIf="showActionButton"
      [loading]="processing"
      [disabled]="disabled"
      icon="pi pi-check"
      (click)="this.callAction.emit()"
      [label]="actionLabel" />
  </div>
</div>
<p-divider layout="horizontal" />
