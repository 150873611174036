<div class="grid grid-cols-3 gap-8 full-height-form">
  <div class="col-span-2">
    <div class="flex flex-col">
      <div class="sticky top-0 z-10 pt-5 bg-light">
        <app-right-form-header
          [processing]="processing$()"
          [disabled]="form.invalid"
          [title]="title"
          (callAction)="action()"
          (callCancel)="
            form.reset(); actionSuccess.emit()
          "></app-right-form-header>
      </div>
      <div class="flex-1">
        <form [formGroup]="form">
          <div class="grid grid-cols-2 gap-y-0 gap-x-4">
            <div>
              <p>Select Product</p>
              <p-select
                formControlName="product"
                [options]="products$()"
                optionLabel="productName"
                placeholder="Select Product"
                class="w-full"
                [class.input-danger]="
                  form.get('product')?.invalid && form.get('product')?.touched
                " />
              <app-error-message
                [control]="form.get('product')"
                label="Product"></app-error-message>
            </div>
            <div>
              <p>Packaging Unit</p>
              <p-select
                formControlName="packagingUnit"
                [options]="packagingUnits"
                [editable]="isOtherPackagingUnit"
                placeholder="Select Packaging Unit"
                (onChange)="onPackagingUnitChange($event.value)"
                class="w-full"
                [class.input-danger]="
                  form.get('packagingUnit')?.invalid &&
                  form.get('packagingUnit')?.touched
                " />
              <app-error-message
                [control]="form.get('packagingUnit')"
                label="Packaging Unit"></app-error-message>
            </div>
            <div>
              <p>Packaging Quantity</p>
              <input
                pInputText
                class="w-full"
                [class.input-danger]="
                  form.get('quantity')?.invalid && form.get('quantity')?.touched
                "
                placeholder="Quantity"
                type="number"
                formControlName="quantity" />
              <app-error-message
                [control]="form.get('quantity')"
                label="Quantity"></app-error-message>
            </div>
            <div>
              <p>Batch Size</p>
              <input
                pInputText
                class="w-full"
                [class.input-danger]="
                  form.get('batchSize')?.invalid &&
                  form.get('batchSize')?.touched
                "
                placeholder="Batch Size"
                type="number"
                formControlName="batchSize" />
              <app-error-message
                [control]="form.get('batchSize')"
                label="Batch Size"></app-error-message>
            </div>
            <div class="col-span-2">
              <div class="flex align-items-center mb-[10px]">
                <p-radioButton
                  name="batchSizeDependence"
                  value="PACKAGING"
                  formControlName="batchSizeDependence"
                  inputId="batchSizeDependence1" />
                <label for="batchSizeDependence1" class="ml-2">
                  Batch size depend on packaging
                </label>
              </div>

              <div class="flex align-items-center mb-[10px]">
                <p-radioButton
                  name="batchSizeDependence"
                  value="UNIT"
                  formControlName="batchSizeDependence"
                  inputId="batchSizeDependence2" />
                <label for="batchSizeDependence2" class="ml-2">
                  Batch size depend on unit
                </label>
              </div>
            </div>
            <div class="mt-[20px] col-span-2">
              <div class="bg-white rounded p-10">
                <p class="m-[0px]">Total QRs based on the above input</p>
                <h2 class="m-[0px]">{{ numberOfQrs }}</h2>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div>
    <div class="flex flex-col">
      <div class="sticky top-0 z-10 pt-5 bg-light">
        <h2>QR Preview</h2>
      </div>
      <p-divider></p-divider>
    </div>
    <div class="flex-1">
      <app-qr-preview
        [product]="form.value?.product"
        [qrConfig]="getProductQrSettings(form.value?.product)"
        [ctaConfig]="getProductCtaConfig(form.value?.product)"></app-qr-preview>
    </div>
  </div>
</div>
