import {
  AddAdminUserParams,
  DemoRequestParams,
  UpdateUserParams,
  UpdateUserPasswordParams,
  UpdateUserSelfParams,
  UserLoginParams,
  UserPaginationParams,
} from './user.model';

const CONTEXT = '[UserStore]';
export class UserLogin {
  static readonly type = `${CONTEXT} Login`;
  constructor(public payload: UserLoginParams) {}
}

export class DemoRequest {
  static readonly type = `${CONTEXT} Submit Demo Request`;
  constructor(public payload: DemoRequestParams) {}
}

export class ValidateOtp {
  static readonly type = `${CONTEXT} Request OTP`;
  constructor(
    public userId: number,
    public code: number,
    public type: string
  ) {}
}

export class ResendOtp {
  static readonly type = `${CONTEXT} Resend OTP`;
  constructor(public userId: number) {}
}

export class SendOtp {
  static readonly type = `${CONTEXT} Send OTP`;
  constructor(public type: string) {}
}

export class GetUser {
  static readonly type = `${CONTEXT} Get User`;
}

export class DeleteUser {
  static readonly type = `${CONTEXT} Delete User`;
  constructor(public id: number) {}
}

export class UpdateUser {
  static readonly type = `${CONTEXT} Update User`;
  constructor(
    public id: number,
    public payload: UpdateUserParams
  ) {}
}

export class UpdateUserSelf {
  static readonly type = `${CONTEXT} Update User Self`;
  constructor(public payload: UpdateUserSelfParams) {}
}
export class UpdateUserSelfWithNotifications {
  static readonly type = `${CONTEXT} Update User Self with Notifications`;
  constructor(public payload: UpdateUserSelfParams) {}
}

export class UpdateUserPassword {
  static readonly type = `${CONTEXT} Update User Password`;
  constructor(public payload: UpdateUserPasswordParams) {}
}

export class GetUserList {
  static readonly type = `${CONTEXT} Get User List`;
  constructor(public payload: UserPaginationParams) {}
}

export class AddAdminUser {
  static readonly type = `${CONTEXT} Add Admin User`;
  constructor(public payload: AddAdminUserParams) {}
}

export class ForgotPassword {
  static readonly type = `${CONTEXT} Forgot Password`;
  constructor(public email: string) {}
}

export class ResetPassword {
  static readonly type = `${CONTEXT} Reset Password`;
  constructor(
    public token: string,
    public password: string
  ) {}
}

export class ResetUserState {
  static readonly type = `${CONTEXT} Reset User State`;
}
