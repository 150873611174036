import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { DashboardStateModel } from './dashboard.model';
import { DashboardService } from './dashboard.service';
import {
  GetActivityLogs,
  GetChartsData,
  GetCteStatistics,
  GetProductExpiration,
} from './dashboard.actions';

@State<DashboardStateModel>({
  name: 'Dashboard',
  defaults: {
    productStatistics: [],
    activities: {
      payload: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0,
      },
    },
    productChartData: {
      chartData: [],
      zoomCords: { lat: 0, lon: 0 },
      summary: null,
    },
    productExpiration: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class DashboardState {
  constructor(
    private dashboardService: DashboardService,
    private notificationService: NotificationService
  ) {}

  @Action(GetActivityLogs)
  getActivityLogs(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetActivityLogs
  ) {
    return this.dashboardService.getActivityLogs(payload).pipe(
      tap(response => {
        patchState({
          activities: {
            payload: response.payload,
            pagination: response.pagination,
          },
        });
      }),
      catchError(error => {
        this.notificationService.openErrorToast(error.error.message);
        return throwError(() => error);
      })
    );
  }
  @Action(GetChartsData)
  getProductChartData(
    { patchState }: StateContext<DashboardStateModel>,
    { productId }: GetChartsData
  ) {
    return this.dashboardService.getChartsData(productId).pipe(
      tap(response => {
        patchState({
          productChartData: response,
        });
      }),
      catchError(error => {
        this.notificationService.openErrorToast(error.error.message);
        return throwError(() => error);
      })
    );
  }
  @Action(GetCteStatistics)
  getCteStatistics(
    { patchState }: StateContext<DashboardStateModel>,
    { productId }: GetCteStatistics
  ) {
    return this.dashboardService.getCteStatistics(productId).pipe(
      tap(async res => {
        patchState({
          productStatistics: res.payload,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }
  @Action(GetProductExpiration)
  getProductExpiration(
    { patchState }: StateContext<DashboardStateModel>,
    { productId }: GetProductExpiration
  ) {
    return this.dashboardService.getExpirations(productId).pipe(
      tap(async res => {
        patchState({
          productExpiration: res.payload,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }
}
