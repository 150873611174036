<div class="flex min-h-[90vh] p-4 custom-gradient rounded">
  <!-- Steps Sidebar -->
  <div class="w-1/4 p-4 border-0 border-r-2 border-solid border-r-slate-200">
    <div class="font-bold mb-5 text-lg">Steps to Recall</div>
    <ul class="list-none p-0">
      <li
        *ngFor="let step of steps; let i = index"
        (click)="navigateToStep(i)"
        class="cursor-pointer mb-[30px] hover:text-primary transition"
        [class.text-primary]="selectedStepIndex === i">
        <div class="font-bold flex gap-4 items-center">
          <div>
            <span [class]="step.icon"></span>
          </div>
          <div>
            <p class="m-0">{{ step.label }}</p>
            <small>Step {{ i + 1 }}</small>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <!-- Content Area -->
  <div class="w-3/4 p-4">
    <div *ngIf="selectedStepIndex === 0">
      <div class="font-bold mb-5 text-lg">Filter to find the product</div>
      <app-product-filters
        [filters]="filters$()"
        (selectedFilters)="setFilters($event)"></app-product-filters>
      <div class="block mt-5">
        <p-button
          [disabled]="filters$().kdeValues.length === 0"
          class="!w-fit block"
          [outlined]="true"
          (click)="countProductItems()"
          >Fetch Items <span class="ml-3 pi pi-arrow-right"></span
        ></p-button>
      </div>
      @if (countRecallItems$()) {
        <div class="mt-5">
          <div class="p-5 rounded border-dashed border-2 bg-white">
            <div class="font-bold text-md mb-5">
              {{ countRecallItems$() }} item(s) fetched
            </div>
            <div class="flex items-center gap-3 justify-end">
              <p-button [outlined]="true" (click)="actionSuccess.emit(false)"
                >Cancel</p-button
              >
              <p-button (click)="nextStep(true)"
                >Continue <span class="ml-3 pi pi-arrow-right"></span
              ></p-button>
            </div>
          </div>
        </div>
      }
    </div>

    <div *ngIf="selectedStepIndex === 1">
      <div class="font-bold mb-5 text-lg">
        Provide recall details with reason & attachments
      </div>
      <form [formGroup]="form">
        <div class="grid grid-cols-2 gap-x-4 gap-y-0">
          <div class="col-span-2">
            <p class="text-base block font-bold !mb-2">Recall Name</p>
            <input
              pInputText
              class="w-full"
              [class.input-danger]="
                form.get('recallName')?.invalid &&
                form.get('recallName')?.touched
              "
              placeholder="e.g. Recall-12-Jan-2025"
              formControlName="recallName" />
            <app-error-message
              [control]="form.get('recallName')"
              label="Recall Name"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">
              Select Authorized User(s)
            </p>
            <p-multiSelect
              [options]="users$()"
              formControlName="authorizedUsers"
              optionLabel="firstName"
              optionValue="id"
              placeholder="Select Users"
              class="!w-full"
              [class.input-danger]="
                form.get('authorizedUsers')?.invalid &&
                form.get('authorizedUsers')?.touched
              ">
              <ng-template let-user pTemplate="item">
                <span>{{ user.firstName }} {{ user.lastName }}</span>
              </ng-template></p-multiSelect
            >
            <app-error-message
              [control]="form.get('authorizedUsers')"
              label="Authorized Users"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">Select Severity Level</p>
            <p-select
              placeholder="Choose Severity Level"
              [options]="severityLevels"
              formControlName="severityLevel" />
            <app-error-message
              [control]="form.get('severityLevel')"
              label="Severity Level"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">Reason of Recall</p>
            <textarea
              pInputTextarea
              rows="5"
              class="w-full"
              [class.input-danger]="
                form.get('recallReason')?.invalid &&
                form.get('recallReason')?.touched
              "
              placeholder="Reason of Recall"
              formControlName="recallReason"></textarea>
            <app-error-message
              [control]="form.get('recallReason')"
              label="Reason of Recall"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">
              Description for Email & SMS
            </p>
            <textarea
              pInputTextarea
              rows="5"
              class="w-full"
              [class.input-danger]="
                form.get('descriptionForEmailAndSms')?.invalid &&
                form.get('descriptionForEmailAndSms')?.touched
              "
              placeholder="Description for Email & SMS"
              formControlName="descriptionForEmailAndSms"></textarea>
            <app-error-message
              [control]="form.get('descriptionForEmailAndSms')"
              label="Description"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">Product Scan Message</p>
            <textarea
              pInputTextarea
              rows="5"
              class="w-full"
              [class.input-danger]="
                form.get('productScanMessage')?.invalid &&
                form.get('productScanMessage')?.touched
              "
              placeholder="Product Scan Message"
              formControlName="productScanMessage"></textarea>
            <app-error-message
              [control]="form.get('productScanMessage')"
              label="Product Scan Message"></app-error-message>
          </div>
          <div>
            <p class="text-base block font-bold !mb-2">Activity Feed</p>
            <textarea
              pInputTextarea
              rows="5"
              class="w-full"
              [class.input-danger]="
                form.get('activityFeed')?.invalid &&
                form.get('activityFeed')?.touched
              "
              placeholder="Activity Feed"
              formControlName="activityFeed"></textarea>
            <app-error-message
              [control]="form.get('activityFeed')"
              label="Activity Feed"></app-error-message>
          </div>
          <div class="col-span-2">
            <p class="text-base block font-bold !mb-2">Supporting Documents</p>
            <app-document-upload
              [fileType]="'recallDocument'"
              (onUpload)="onUpload($event)"></app-document-upload>
          </div>
          <div class="col-span-2 mt-5">
            <div class="flex items-center gap-3 justify-end">
              <p-button [outlined]="true" (click)="previousStep()"
                >Back</p-button
              >
              <p-button
                [disabled]="
                  form.invalid || isProcessing$() || authProcessing$()
                "
                (click)="createUpdateRecall()"
                >Continue <span class="ml-3 pi pi-arrow-right"></span
              ></p-button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="selectedStepIndex === 2">
      <div class="font-bold mb-5 text-lg">Recall CTEs & KDEs</div>
      @if (recall$()) {
        <app-ctes-kdes
          [cteType]="'RECALL'"
          [isProductCtes]="true"
          [product]="null"
          [recallCtes]="recall$()?.recallProductCtes ?? []"
          [showNavigationButtons]="false"
          [showHeading]="false"></app-ctes-kdes>
      }
      <div class="flex items-center gap-3 justify-end">
        <p-button [outlined]="true" (click)="previousStep()">Back</p-button>
        <p-button (click)="nextStep(false)"
          >Continue <span class="ml-3 pi pi-arrow-right"></span
        ></p-button>
      </div>
    </div>

    <div *ngIf="selectedStepIndex === 3">
      <div class="flex justify-between items-center mb-4">
        <div class="font-bold text-lg">Recall Users</div>
        <a
          class="no-underline text-primary border-primary border-solid p-3 rounded"
          href="/settings/roles"
          target="_blank"
          >Manage Roles</a
        >
      </div>
      <div class="mt-4">
        @if (this.recallUsers$() && this.recallUsers$().length > 0) {
          <app-data-table
            [showTableToolbar]="false"
            [allowSorting]="false"
            [allowFiltering]="false"
            [first]="first"
            [rows]="rows"
            [sortBy]="sortBy"
            [sortOrder]="sortOrder"
            [data$]="this.recallUsers$"
            [headers$]="this.headers$"
            [showActions]="false"
            [isProcessing$]="this.isProcessing$"
            [pagination$]="this.pagination$" />
        }
      </div>
      <div class="mt-4">
        <div class="flex items-center gap-3 justify-end">
          <p-button [outlined]="true" (click)="previousStep()">Back</p-button>
          <p-button (click)="nextStep(false)"
            >Continue <span class="ml-3 pi pi-arrow-right"></span
          ></p-button>
        </div>
      </div>
    </div>

    <div *ngIf="selectedStepIndex === 4">
      <div class="font-bold mb-5 text-lg">Recall Summary</div>
      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Recall Name</div>
        </div>
        <div class="col-span-2">
          <div>{{ recall$()?.recallName }}</div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Severity Level</div>
        </div>
        <div class="col-span-2">
          <div>{{ recall$()?.severityLevel }}</div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Authorized Users</div>
        </div>
        <div class="col-span-2">
          <div>{{ getAuthorizedUsers(recall$()?.authorizedUsers) }}</div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Reason of Recall</div>
        </div>
        <div class="col-span-2">
          <div>
            {{ recall$()?.recallReason }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Description for Email & SMS</div>
        </div>
        <div class="col-span-2">
          <div>
            {{ recall$()?.descriptionForEmailAndSms }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Product Scan Message</div>
        </div>
        <div class="col-span-2">
          <div>
            {{ recall$()?.productScanMessage }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Attachments</div>
        </div>
        <div class="col-span-2">
          <div>
            @for (url of getAttachments(recall$()?.attachments); track url) {
              <div>{{ url }}</div>
            }
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-3 gap-3 border-0 pt-5 pb-5 border-b-2 border-solid border-b-[#CCCCCC]">
        <div>
          <div class="font-bold">Recall Items</div>
        </div>
        <div class="col-span-2">
          <div>{{ countRecallItems$() }} Items</div>
        </div>
      </div>

      <div class="mt-4">
        <div class="flex items-center gap-3 justify-end">
          <p-button [outlined]="true" (click)="previousStep()">Back</p-button>
          <p-button (click)="sendOtp()"
            >Submit <span class="ml-3 pi pi-arrow-right"></span
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  (onHide)="onOtpDialogClose()"
  [modal]="true"
  [(visible)]="otpDialogVisible"
  class="w-[25%]">
  <div class="text-center">
    <div class="mb-4">
      <span class="pi pi-exclamation-circle text-primary text-[50px]"></span>
    </div>
    <div class="text-lg font-bold mb-4">
      Confirm your recall with Two-Factor Authentication
    </div>
    <div class="mb-4">
      Please enter the 4-digit code sent to your registered email address
    </div>
    <div class="mb-4 text-center">
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{
          length: 4,
          inputClass: 'otp-box',
          allowNumbersOnly: true,
        }"></ng-otp-input>
    </div>
    <div class="flex w-full justify-between items-center mb-4">
      <div>
        <countdown
          #cd
          [config]="{ leftTime: timeLeft, format: 'm:ss' }"
          (event)="countdownEvent($event)" />
      </div>
      <div>
        <p-button
          class="!bg-none"
          label="Resend OTP"
          (click)="resendOtp()"
          [disabled]="resendDisabled" />
      </div>
    </div>
    <div>
      <div class="flex gap-3 justify-center items-center">
        <button
          pButton
          class="bg-slate-200 text-black border-slate-200"
          (click)="otpDialogVisible = false">
          Cancel
        </button>
        <button
          pButton
          [disabled]="!otp || otp.length < 4 || authProcessing$()"
          class="bg-primary"
          (click)="submitRecall()">
          Continue
        </button>
      </div>
    </div>
  </div>
</p-dialog>
