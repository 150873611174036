import { Component, effect } from '@angular/core';
import { StripeService } from '../../../services/stripe.service';
import {
  Stripe,
  StripeCardCvcElement,
  StripeCardElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElement,
  StripeElements,
} from '@stripe/stripe-js';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import {
  AddCard,
  GetPlans,
  Subscribe,
} from '../../../../store/subscription-store/subscription.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStateSelectors } from '../../../../store/users-store/user.selectors';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ErrorMessageComponent } from '../../../components/shared-components/error-message/error-message.component';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SubscriptionStateSelectors } from '../../../../store/subscription-store/subscription.selectors';
import { Plan } from '../../../../store/subscription-store/subscription.model';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-subscribe',
  standalone: true,
  imports: [
    CommonModule,
    StepperModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorMessageComponent,
    ConfirmDialogModule,
    CheckboxModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss',
})
export class SubscribeComponent {
  bucketUrl: string = environment.bucketUrl;
  stripe: Stripe | null = null;
  card: StripeCardElement | undefined;
  errorMessage: string = '';
  planId: string = '';
  paymentMethodId: string = '';
  selectedPlan: Plan | null = null;

  user$ = this.store.selectSignal(UserStateSelectors.getUser);
  plans$ = this.store.selectSignal(SubscriptionStateSelectors.getPlans);
  isLoading$ = this.store.selectSignal(SubscriptionStateSelectors.isLoading);

  billingAddressForm: FormGroup;
  cardDetailsForm: FormGroup;

  constructor(
    private stripeService: StripeService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.getPlans();
    this.planId = this.activatedRoute.snapshot.params['id'];
    this.billingAddressForm = this.fb.group({
      firstName: [{ value: this.user$()?.firstName, disabled: true }],
      lastName: [{ value: this.user$()?.lastName, disabled: true }],
      email: [{ value: this.user$()?.email, disabled: true }],
    });
    this.cardDetailsForm = this.fb.group({
      nameOnCard: [
        `${this.user$()?.firstName} ${this.user$()?.lastName}`,
        [Validators.required],
      ],
    });
    effect(() => {
      if (this.plans$().length > 0) {
        this.selectedPlan =
          this.plans$().find(p => p.id === this.planId) ?? null;
      }
    });
  }

  getPlans() {
    this.store.dispatch(new GetPlans());
  }

  initializeStripe() {
    setTimeout(() => {
      this.stripeService.initialize().then(stripe => {
        this.stripe = stripe;
        this.card = stripe?.elements().create('card');
        this.card?.mount('#card-element');
      });
    }, 1000);
  }

  createPaymentMethod(event: Event, nextCallBack: any): void {
    event.preventDefault();
    if (this.card) {
      this.stripe
        ?.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.cardDetailsForm.get('nameOnCard')?.value,
          },
        })
        .then(pm => {
          this.paymentMethodId = pm.paymentMethod!.id;
          this.store
            .dispatch(
              new AddCard({
                cardToken: this.paymentMethodId,
              })
            )
            .subscribe(() => {
              nextCallBack.emit();
            });
        });
    }
  }

  subscribe() {
    this.confirmationService.confirm({
      header: `CONFIRM SUBSCRIPTION`,
      message: `Are you sure want to subscribe to ${this.selectedPlan?.productDetails.name}?`,
      icon: 'pi pi-exclamation-circle',
      acceptLabel: 'Confirm',
      acceptIcon: 'pi',
      rejectVisible: false,
      acceptButtonStyleClass: 'p-button-primary',
      accept: () => {
        this.store
          .dispatch(
            new Subscribe({
              sourceId: this.paymentMethodId,
              planId: this.planId,
            })
          )
          .subscribe(() => {
            window.location.href = '/subscription/success';
          });
      },
    });
  }
}
