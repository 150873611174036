import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
  Signal,
  effect,
  SimpleChanges,
} from '@angular/core';
import { QrOrder } from '../../../../store/qr-store/qr.model';
import { ButtonModule } from 'primeng/button';
import { Store } from '@ngxs/store';
import {
  UploadHistory,
  DownloadQRTemplate,
  UploadQRTemplate,
} from '../../../../store/qr-store/qr.actions';
import { QrStateSelectors } from '../../../../store/qr-store/qr.selectors';
import { NgClass, CommonModule } from '@angular/common';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { UploadedFile } from '../../../../store/general-store/general.model';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';
import { DocumentUploadComponent } from '../../shared-components/document-upload/document-upload.component';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-bulk-upload-form',
  standalone: true,
  imports: [
    ButtonModule,
    NgClass,
    CommonModule,
    RightFormHeaderComponent,
    DocumentUploadComponent,
    DividerModule,
  ],
  templateUrl: './bulk-upload-form.component.html',
  styleUrls: ['./bulk-upload-form.component.scss'],
})
export class BulkUploadComponent implements OnInit {
  @Input() data!: QrOrder;
  @Input() id?: number;
  @Input() type?: string;
  @Input() title!: string;
  @Output() actionSuccess = new EventEmitter<void>();
  @Output() onDownloadTemplate = new EventEmitter<void>();
  @Output() onUploadTemplate = new EventEmitter<File>();
  @ViewChild('fileInput') fileInput!: ElementRef;

  uploadHistory: Array<{
    name: string;
    description: string;
    date: string;
    status: string;
  }> = [];
  s3UploadedUrl: string | undefined = '';
  selectedFileName: string | undefined;
  isUploading: boolean = false;
  isLoadingHistory: boolean = false;

  processing$: Signal<boolean> = this.store.selectSignal(
    QrStateSelectors.isProcessing
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);
  isUpload$: Signal<boolean> = this.store.selectSignal(
    GeneralStateSelectors.isUploadInProgress
  );
  qrOrder$: Signal<QrOrder | null> = this.store.selectSignal(
    QrStateSelectors.getQrOrder
  );

  constructor(private store: Store) {
    effect(() => {
      if (
        this.uploadedFiles$() &&
        this.uploadedFiles$()['bulkUpload'] &&
        !this.isUpload$()
      ) {
        this.s3UploadedUrl = this.uploadedFiles$()['bulkUpload'][0].objectURL;
        this.store
          .dispatch(new UploadQRTemplate(this.data.id, this.s3UploadedUrl))
          .pipe(
            tap(() => {
              this.isUploading = false;
            }),
            catchError(error => {
              console.error('Error uploading QR template:', error);
              this.isUploading = false;
              return of(error);
            })
          )
          .subscribe(() => {
            this.fetchUploadHistory();
          });
      }
    });
  }

  ngOnInit(): void {
    this.fetchUploadHistory();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !changes['data'].firstChange) {
      this.resetState();
      this.fetchUploadHistory();
    }
  }

  resetState(): void {
    this.uploadHistory = [];
    this.isLoadingHistory = false;
    this.selectedFileName = undefined;
    this.isUploading = false;
  }

  fetchUploadHistory(): void {
    this.isLoadingHistory = true;

    this.store
      .dispatch(new UploadHistory(this.data.id))
      .pipe(
        tap((response: any) => {
          this.isLoadingHistory = false;

          if (response?.Qr?.uploadHistory?.payload?.length) {
            this.uploadHistory = response.Qr.uploadHistory.payload.map(
              (item: any) => ({
                name: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
                description: `${item.totalRowsProcessed} bulk ${item.totalRowsProcessed === 1 ? 'record' : 'records'} imported`,
                date: new Date(item.createdAt).toLocaleString(),
                status: item.status,
              })
            );
          } else {
            this.uploadHistory = [];
          }
        }),
        catchError(error => {
          console.error('Error fetching upload history:', error);
          this.isLoadingHistory = false;
          this.uploadHistory = [];
          return of(error);
        })
      )
      .subscribe();
  }

  downloadTemplate(): void {
    this.store
      .dispatch(new DownloadQRTemplate(this.data.id))
      .pipe(
        tap(() => {
          console.log('Template downloaded successfully');
        }),
        catchError(error => {
          console.error('Error downloading QR template:', error);
          return of(error);
        })
      )
      .subscribe();
  }

  triggerFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  handleFileUpload(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.selectedFileName = file.name;
      this.isUploading = true;

      this.store
        .dispatch(new ClearUploadedFiles())
        .pipe(
          concatMap(() =>
            this.store.dispatch(new GeneratePresignedUrl(file, 'bulkUpload'))
          ),
          tap(() => {
            console.log('File uploaded successfully');
          }),
          catchError(error => {
            console.error('Error uploading file:', error);
            this.isUploading = false;
            return of(error);
          }),
          tap(() => {})
        )
        .subscribe(() => {
          // this.isUploading = false;
        });
    }
  }
}
