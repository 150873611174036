import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  GetCountries,
  GeneratePresignedUrl,
  RemoveUploadedFile,
  GetCities,
  GetOrganizationTypes,
  GetDesignations,
  AddUploadedFile,
  ClearUploadedFiles,
  GetRoles,
  HideSideMenu,
  AppStartAction,
  KDEInputTypes,
  AddKDE,
  GetIconsList,
  ClearUploadedFilesByTypes,
  ExportData,
  UpdateKDE,
  DeleteKDE,
} from './general.actions';
import { GeneralStateModel } from './general.model';
import { GeneralService } from './general.service';
import { catchError, tap, throwError } from 'rxjs';
import * as _ from 'lodash';
import { GetOrganizationCTEs } from '../organizations-store/organizations.actions';
import { GetUser } from '../users-store/user.actions';
import { NotificationService } from '../../app/utilities/notification.service';
@State<GeneralStateModel>({
  name: 'General',
  defaults: {
    isLoading: false,
    countries: [],
    designations: [],
    organizationTypes: [],
    cities: [],
    uploadedFiles: [],
    uploadedFilesMap: {},
    roles: [],
    hideSideMenu: false,
    CTEIcons: [],
    isUploadInProgress: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class GeneralState {
  constructor(
    private generalService: GeneralService,
    private notificationService: NotificationService
  ) {}

  @Action(GetCountries)
  getCountries({ patchState }: StateContext<GeneralStateModel>) {
    patchState({ isLoading: true });
    return this.generalService.getCountries().pipe(
      tap(async res => {
        patchState({
          countries: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetOrganizationTypes)
  getOrganizationTypes({ patchState }: StateContext<GeneralStateModel>) {
    patchState({ isLoading: true });
    return this.generalService.getOrganizationTypes().pipe(
      tap(async res => {
        patchState({
          organizationTypes: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetDesignations)
  getDesignations({ patchState }: StateContext<GeneralStateModel>) {
    patchState({ isLoading: true });
    return this.generalService.getDesignations().pipe(
      tap(async res => {
        patchState({
          designations: res,
          isLoading: false,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetRoles)
  getRoles({ patchState }: StateContext<GeneralStateModel>) {
    patchState({ isLoading: true });
    return this.generalService.getRoles().pipe(
      tap(async res => {
        patchState({
          roles: res,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }

  @Action(GetCities)
  getCities(
    { patchState }: StateContext<GeneralStateModel>,
    action: GetCities
  ) {
    patchState({ cities: undefined });
    return this.generalService.getCities(action.countryId).pipe(
      tap(async res => {
        patchState({
          cities: res,
        });
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GeneratePresignedUrl)
  async generatePresignedUrl(
    { patchState, getState }: StateContext<GeneralStateModel>,
    action: GeneratePresignedUrl
  ) {
    const fileName = action.file.name;
    const fileType = action.file.type;
    const currentUrls =
      _.cloneDeep(getState().uploadedFilesMap[action.fileType]) ?? [];
    patchState({ isUploadInProgress: true });
    return this.generalService.getPresignedUrl(fileName, fileType).pipe(
      tap(async url => {
        const newUrlEntry = {
          name: fileName,
          size: action.file.size,
          objectURL: url.split('?')[0],
          uploading: true,
        };
        currentUrls.push(newUrlEntry);

        const newUploadedFilesMap = {
          ...getState().uploadedFilesMap,
          [action.fileType]: currentUrls,
        };
        patchState({
          uploadedFilesMap: newUploadedFilesMap,
        });

        this.generalService.uploadFileToS3(url, action.file).subscribe({
          next: () => {
            const index = currentUrls.findIndex(
              entry => entry.objectURL === newUrlEntry.objectURL
            );

            if (index > -1) {
              currentUrls[index].uploading = false;
              const newUploadedFilesMap = {
                ...getState().uploadedFilesMap,
                [action.fileType]: currentUrls,
              };
              patchState({
                uploadedFilesMap: newUploadedFilesMap,
                isUploadInProgress: false,
              });
            }
          },
          error: error => {
            patchState({ isUploadInProgress: false });
            console.error('Upload failed', error);
          },
        });
      }),
      catchError(async error => {
        patchState({ isUploadInProgress: false });
        return throwError(() => error);
      })
    );
  }

  @Action(RemoveUploadedFile)
  removeUploadedFile(
    ctx: StateContext<GeneralStateModel>,
    action: RemoveUploadedFile
  ) {
    const state = ctx.getState();
    const updatedFiles = state.uploadedFiles.filter(
      file => file.objectURL !== action.file.objectURL
    );
    ctx.patchState({
      uploadedFiles: updatedFiles,
    });
  }

  @Action(AddUploadedFile)
  addUploadedFile(
    ctx: StateContext<GeneralStateModel>,
    action: AddUploadedFile
  ) {
    const currentUrls =
      _.cloneDeep(ctx.getState().uploadedFilesMap[action.fileType]) ?? [];
    currentUrls.push(action.file);
    const newUploadedFilesMap = {
      ...ctx.getState().uploadedFilesMap,
      [action.fileType]: currentUrls,
    };
    ctx.patchState({
      uploadedFilesMap: newUploadedFilesMap,
    });
  }

  @Action(ClearUploadedFiles)
  clearUploadedFiles(ctx: StateContext<GeneralStateModel>) {
    ctx.patchState({
      uploadedFilesMap: {},
      uploadedFiles: [],
    });
  }

  @Action(ClearUploadedFilesByTypes)
  clearUploadedFilesByType(
    ctx: StateContext<GeneralStateModel>,
    action: ClearUploadedFilesByTypes
  ) {
    const uploadFiles = _.cloneDeep(ctx.getState().uploadedFilesMap) ?? [];
    const index = uploadFiles[action.fileType].findIndex(
      file => file.objectURL === action.file.objectURL
    );
    if (index > -1) {
      uploadFiles[action.fileType].splice(index, 1);
      ctx.patchState({
        uploadedFilesMap: uploadFiles,
      });
    }
  }

  @Action(HideSideMenu)
  hideSideMenu(ctx: StateContext<GeneralStateModel>) {
    ctx.patchState({
      hideSideMenu: true,
    });
    setTimeout(() => {
      ctx.patchState({
        hideSideMenu: false,
      });
    }, 500);
  }

  @Action(KDEInputTypes)
  kdeInputTypes(_ctx: StateContext<GeneralStateModel>) {
    return this.generalService.getKDEInputTypes().pipe(
      tap(async res => {}),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }

  @Action(GetIconsList)
  getIconsList({ patchState }: StateContext<GeneralStateModel>) {
    return this.generalService.getIconsList().pipe(
      tap(async res => {
        patchState({
          CTEIcons: res,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }

  @Action(AppStartAction)
  appStartAction({ dispatch }: StateContext<GeneralStateModel>) {
    const actions = [
      new GetCountries(),
      new GetOrganizationTypes(),
      new GetDesignations(),
      new GetOrganizationCTEs(),
      new KDEInputTypes(),
      new GetIconsList(),
    ];
    if (localStorage.getItem('regen_access_token')) {
      actions.push(new GetUser());
    }
    dispatch(actions);
  }

  @Action(AddKDE)
  addKDE(
    { patchState, dispatch }: StateContext<GeneralStateModel>,
    action: AddKDE
  ) {
    patchState({ isLoading: true });
    return this.generalService.addKDE(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'KDE has been created successfully!'
        );
        patchState({
          isLoading: false,
        });
        dispatch([new GetOrganizationCTEs(), new HideSideMenu()]);
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }
  @Action(UpdateKDE)
  updateKDE(
    { patchState, dispatch }: StateContext<GeneralStateModel>,
    action: UpdateKDE
  ) {
    patchState({ isLoading: true });
    return this.generalService.updateKDE(action.id, action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'KDE has been updated successfully!'
        );
        patchState({
          isLoading: false,
        });
        dispatch([new GetOrganizationCTEs(), new HideSideMenu()]);
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteKDE)
  deleteKDE(
    { dispatch, patchState }: StateContext<GeneralStateModel>,
    action: DeleteKDE
  ) {
    return this.generalService.deleteKDE(action.kdeID).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast(
          'Organization CTE deleted successfully!'
        );
        dispatch([new GetOrganizationCTEs(), new HideSideMenu()]);
      }),
      catchError(async error => {
        patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(ExportData)
  exportData(_ctx: StateContext<GeneralStateModel>, action: ExportData) {
    return this.generalService.exportData(action.payload.model).pipe(
      tap(response => {
        this.notificationService.openSuccessToast(response.message);
      }),
      catchError(error => {
        this.notificationService.openErrorToast(error.error.message);
        return throwError(() => error);
      })
    );
  }
}
