import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  effect,
  Signal,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorMessageComponent } from '../../shared-components/error-message/error-message.component';
import { TextareaModule } from 'primeng/textarea';
import { Store } from '@ngxs/store';
import {
  AddOrganizationCTE,
  UpdateOrganizationCTE,
} from '../../../../store/organizations-store/organizations.actions';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { CardModule } from 'primeng/card';
import { NgClass, CommonModule } from '@angular/common';
import {
  CTE,
  UploadedFile,
} from '../../../../store/general-store/general.model';
import {
  ClearUploadedFiles,
  GeneratePresignedUrl,
} from '../../../../store/general-store/general.actions';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GoogleMap, GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { UpdateProductCTE } from '../../../../store/products-store/products.actions';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationService } from 'primeng/api';
import { SliderModule } from 'primeng/slider';
import { GoogleMapsLoaderService } from '../../../services/google-maps-loader.service';
import { UpdateRecallCTE } from '../../../../store/recall-management-store/recall-management.actions';
import { RightFormHeaderComponent } from '../../shared-components/right-form-header/right-form-header.component';

@Component({
  selector: 'app-add-cteform',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    TextareaModule,
    CardModule,
    NgClass,
    CommonModule,
    ProgressSpinnerModule,
    GoogleMapsModule,
    MapMarker,
    InputSwitchModule,
    SliderModule,
    RightFormHeaderComponent,
  ],
  templateUrl: './add-cte-form.component.html',
  styleUrl: './add-cte-form.component.scss',
})
export class AddCTEFormComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() data?: CTE;
  @Input() id?: number;
  @Input() type?: string;
  @Input() cteType: string = 'ORGANIZATION';
  @Input() title: string = '';
  @Output() actionSuccess = new EventEmitter<boolean>();
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;
  @ViewChild('searchBox', { static: false }) searchBox: ElementRef | undefined;
  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap | undefined;

  selectedIcon = '';
  uploadedIcon = '';
  isUploading = false;
  autocompleteService = new google.maps.places.AutocompleteService();
  filteredPlaces: any[] = [];
  form: FormGroup;
  cteIcons$ = this.store.selectSignal(GeneralStateSelectors.getCTEIcons);
  processing$ = this.store.selectSignal(
    OrganizationStateSelectors.isOrganizationStoreProcessing
  );
  uploadedFiles$: Signal<Record<string, UploadedFile[]>> =
    this.store.selectSignal(GeneralStateSelectors.getPresignedUrls);

  mapSelectedPosition: google.maps.LatLngLiteral | null = null;
  mapCenter: google.maps.LatLngLiteral | null = null;
  mapOptions = {
    zoomControl: true,
    animation: {
      animation: google.maps.Animation.BOUNCE,
    },
  };
  isLocationDisabled: boolean = false;

  private overlayIndex = 200;
  radius: number = 0;
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
    private confirmationService: ConfirmationService,
    private googleMapsLoader: GoogleMapsLoaderService
  ) {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      type: [this.data?.type === 'RECALL'],
      latitude: [{ value: null }],
      longitude: [{ value: null }],
      radius: [{ value: null }],
      isMapVisible: [false],
      multipleKDEsRecords: [false],
    });
    effect(() => {
      if (
        this.uploadedFiles$()[`cteIcon`] &&
        this.uploadedFiles$()[`cteIcon`][0].uploading
      ) {
        this.selectedIcon = '';
        this.uploadedIcon = '';
        this.isUploading = true;
      } else if (
        this.uploadedFiles$()[`cteIcon`] &&
        !this.uploadedFiles$()[`cteIcon`][0].uploading
      ) {
        this.selectedIcon = '';
        this.isUploading = false;
        this.uploadedIcon = this.uploadedFiles$()['cteIcon'][0].objectURL ?? '';
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'Enter' &&
      this.isTopmostOverlay() &&
      !this.processing$()
    ) {
      this.action();
    }
  }

  isTopmostOverlay(): boolean {
    const allOverlays = document.querySelectorAll('[data-overlay-index]');
    const currentIndex = parseInt(
      this.el.nativeElement.getAttribute('data-overlay-index') || '0',
      10
    );
    const maxIndex = Math.max(
      ...Array.from(allOverlays).map(el =>
        parseInt(el.getAttribute('data-overlay-index') || '0', 10)
      )
    );
    return currentIndex === maxIndex;
  }

  ngOnInit() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-overlay-index',
      this.overlayIndex.toString()
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchBox?.nativeElement) {
        this.initializeGoogleMaps();
      }
    }, 500);
  }
  filterPlaces(event: any) {
    const query = event.target.value;

    if (!query) {
      this.filteredPlaces = [];
      return;
    }

    this.autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.filteredPlaces = predictions || [];
        } else {
          this.filteredPlaces = [];
        }
      }
    );
  }

  selectPlace(place: any) {
    this.filteredPlaces = [];

    if (this.searchBox?.nativeElement) {
      this.searchBox.nativeElement.value = place.description;
      const placesService = new google.maps.places.PlacesService(
        this.googleMap?.googleMap as google.maps.Map
      );

      placesService.getDetails(
        { placeId: place.place_id },
        (placeDetails, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            placeDetails?.geometry?.location
          ) {
            this.mapSelectedPosition = {
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng(),
            };

            this.mapCenter = this.mapSelectedPosition;
            this.form.controls['latitude'].setValue(
              this.mapSelectedPosition.lat
            );
            this.form.controls['longitude'].setValue(
              this.mapSelectedPosition.lng
            );
          } else {
            console.error(
              'Error fetching place details or geometry is undefined',
              status
            );
          }
        }
      );
    }
  }
  initializeGoogleMaps() {
    this.googleMapsLoader
      .loadGoogleMapsApi()
      .then(google => {
        if (!google.maps || !google.maps.places) {
          console.error('Google Maps Places Library is missing!');
          return;
        }

        const inputElement = this.searchBox?.nativeElement;
        if (!inputElement) {
          console.error('Search box element not found!');
          return;
        }

        const autocomplete = new google.maps.places.Autocomplete(inputElement, {
          types: ['geocode'],
        });
        console.log('Autocomplete initialized:', autocomplete);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (place.geometry && place.geometry.location) {
            this.mapSelectedPosition = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            this.mapCenter = this.mapSelectedPosition;
            this.form.controls['latitude'].setValue(
              this.mapSelectedPosition.lat
            );
            this.form.controls['longitude'].setValue(
              this.mapSelectedPosition.lng
            );

            console.log('Selected Place:', place.formatted_address);
          } else {
            console.error('Selected place has no geometry');
          }
        });
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['data'] &&
      this.data &&
      changes['data'].currentValue !== changes['data'].previousValue
    ) {
      this.radius = this.data?.radius;
      setTimeout(() => {
        if (this.googleMap && this.searchBox) {
          console.log('GoogleMap and SearchBox are ready1');
          this.initializeGoogleMaps();
        }
      }, 1000);
      this.form.patchValue({
        title: this.data?.title || '',
        description: this.data?.description || '',
        type: this.data.type === 'RECALL',
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        radius: this.data?.radius,
        isMapVisible: !!(this.data?.latitude && this.data?.longitude),
        multipleKDEsRecords: this.data?.multipleKDEsRecords,
      });
      if (this.data.isDefault) {
        this.form.get('title')?.disable();
        this.form.get('description')?.disable();
      } else {
        this.form.get('title')?.enable();
        this.form.get('description')?.enable();
      }
      this.mapSelectedPosition = {
        lat: this.data.latitude ?? 0,
        lng: this.data.longitude ?? 0,
      };
      this.mapCenter = {
        lat: this.data.latitude ?? 0,
        lng: this.data.longitude ?? 0,
      };
      if (this.cteIcons$().includes(this.data.icon)) {
        this.selectedIcon = this.data.icon;
        this.uploadedIcon = '';
      } else {
        this.uploadedIcon = this.data.icon;
        this.selectedIcon = '';
      }
    } else {
      this.setCurrentLocation();
      this.form.reset();
      this.selectedIcon = '';
      this.uploadedIcon = '';
    }
  }

  setCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.mapSelectedPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.mapCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (this.form.get('isMapVisible')?.value) {
            this.form
              .get('latitude')
              ?.patchValue(this.mapSelectedPosition?.lat);
            this.form
              .get('longitude')
              ?.patchValue(this.mapSelectedPosition?.lng);
          }
        },
        error => {
          this.isLocationDisabled = true;
          console.error('Error fetching location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
    setTimeout(() => {
      if (this.googleMap && this.searchBox) {
        console.log('GoogleMap and SearchBox are ready2');
        this.initializeGoogleMaps();
      }
    }, 1000);
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.mapSelectedPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.form.controls['latitude'].patchValue(event.latLng.lat());
      this.form.controls['longitude'].patchValue(event.latLng.lng());
      this.form.updateValueAndValidity();
    }
  }

  action() {
    const payload = this.form.getRawValue();
    delete payload.isMapVisible;
    payload.type = this.form.get('type')?.value ? 'RECALL' : 'STANDARD';
    if (this.type === 'ADD') {
      this.store
        .dispatch(
          new AddOrganizationCTE({
            ...payload,
            type: payload.type,
            multipleKDEsRecords: payload.multipleKDEsRecords ?? false,
            icon:
              this.uploadedIcon === '' ? this.selectedIcon : this.uploadedIcon,
          })
        )
        .subscribe(() => {
          this.form.reset();
          this.uploadedIcon = '';
          if (this.fileInput) this.fileInput.nativeElement.value = '';
        });
    } else if (this.type === 'EDIT' && this.id) {
      if (this.cteType === 'ORGANIZATION') {
        this.store
          .dispatch(
            new UpdateOrganizationCTE(this.id, {
              ...payload,
              type: payload.type,
              multipleKDEsRecords: payload.multipleKDEsRecords ?? false,
              icon:
                this.uploadedIcon === ''
                  ? this.selectedIcon
                  : this.uploadedIcon,
            })
          )
          .subscribe(() => {
            this.form.reset();
            this.uploadedIcon = '';
            if (this.fileInput) this.fileInput.nativeElement.value = '';
          });
      } else if (this.cteType === 'PRODUCT' && this.data?.productId) {
        this.store
          .dispatch(
            new UpdateProductCTE(this.id, this.data.productId, {
              latitude: this.form.get('latitude')?.value,
              longitude: this.form.get('longitude')?.value,
              radius: this.form.get('radius')?.value,
              multipleKDEsRecords:
                this.form.get('multipleKDEsRecords')?.value ?? false,
            })
          )
          .subscribe(() => {
            this.form.reset();
            this.uploadedIcon = '';
            if (this.fileInput) this.fileInput.nativeElement.value = '';
          });
      } else if (this.cteType === 'RECALL') {
        this.store
          .dispatch(
            new UpdateRecallCTE(this.id, {
              latitude: this.form.get('latitude')?.value,
              longitude: this.form.get('longitude')?.value,
              radius: this.form.get('radius')?.value,
              multipleKDEsRecords:
                this.form.get('multipleKDEsRecords')?.value ?? false,
            })
          )
          .subscribe(() => {
            this.form.reset();
            this.uploadedIcon = '';
            if (this.fileInput) this.fileInput.nativeElement.value = '';
          });
      }
    }
  }

  upload(event: any): void {
    this.store.dispatch(new ClearUploadedFiles());
    const files = event.target.files;
    const key = `cteIcon`;
    Array.from(files).forEach(async (file: any) => {
      this.store.dispatch(new GeneratePresignedUrl(file, key));
    });
  }
  updateMapPosition() {
    const lat = parseFloat(this.form.get('latitude')?.value);
    const lng = parseFloat(this.form.get('longitude')?.value);
    if (!isNaN(lat) && !isNaN(lng)) {
      this.mapSelectedPosition = { lat, lng };
      this.mapCenter = { lat, lng };
    } else {
      console.log('Invalid coordinates');
    }
  }

  toggleMapVisible(event: any) {
    if (this.isLocationDisabled) {
      this.confirmationService.confirm({
        header: `PERMISSION ERROR`,
        message: `Permission to access the location is disabled in the browser. Please enable it to continue!`,
        icon: 'pi pi-exclamation-circle',
        acceptLabel: 'OK',
        acceptIcon: 'pi',
        acceptButtonStyleClass: 'p-button-primary',
        rejectVisible: false,
        accept: () => {
          this.form.get('isMapVisible')?.patchValue(false);
        },
      });
    } else {
      this.form.get('isMapVisible')?.patchValue(event.checked);
      if (!event.checked) {
        this.form.get('latitude')?.patchValue(0);
        this.form.get('longitude')?.patchValue(0);
      } else {
        this.setCurrentLocation();
      }
    }
  }
  onSliderChange(event: any): void {
    this.radius = event.value;
  }
  toggleCTEType(event: any): void {
    this.form.get('type')?.patchValue(event.checked);
  }
}
