import {
  GenericApiResponse,
  Header,
  Pagination,
  PaginationParams,
} from '../general-store/general.model';
import { Organization } from '../organizations-store/organizations.model';
import {
  CurrentPlan,
  Subscription,
} from '../subscription-store/subscription.model';

export interface UserLoginParams {
  email: string;
  password: string;
}

export interface GenericAuthResponse extends GenericApiResponse {
  payload: User;
}

export interface OtpAuthResponse extends GenericApiResponse {
  payload: {
    user: User;
    token: string;
  };
}

export interface DemoRequestParams {
  organizationName: string;
  type: string;
  email: string;
  phone: string;
  countryId: number;
  businessAddress: string;
  personalFirstName: string;
  personalLastName: string;
  status?: string;
}

export interface UserPaginationParams extends PaginationParams {
  role: string;
}

export interface UserStateModel {
  isLoggedIn: boolean;
  isOTPVerified: boolean;
  isRecallOTPVerified: boolean;
  user?: User;
  authActionProcessing: boolean;
  isLoading: boolean;
  users: User[];
  pagination: Pagination;
  headers: Header[];
  organizationLogoUrl?: string;
  userRole: UserRoles;
  userAllowedPermissions: MergedPermission[] | [];
}
export interface NotificationItem {
  title: string;
  description: string;
  emailEnabled: boolean;
}

export interface NotificationCategory {
  name: string;
  items: NotificationItem[];
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  roles: Roles[];
  organizations: Organization[];
  notifications: NotificationCategory[];
  roleName: UserRoles;
  currentPlan: CurrentPlan;
  customerId: string;
  subscription: Subscription;
  photo: string;
  passwordResetAt: string;
  isDefault: boolean;
  subscriptionInfo: {
    hasPurchasedSubscription: boolean;
    hasValidSubscription: boolean;
    subscriptionStatus: string;
    canAddProduct: boolean;
    canAddQRCodes: boolean;
    canInviteUsers: boolean;
    vendorsSupported: boolean;
    canCancelPlan: boolean;
  };
}

export interface AddAdminUserParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationId: number;
  roleId?: number;
}

export interface UpdateUserParams {
  firstName: string;
  lastName: string;
  roleIds: number[];
}

export interface UpdateUserSelfParams {
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
  notifications: NotificationCategory[];
}

export interface UpdateUserPasswordParams {
  currentPassword: string;
  newPassword: string;
}

export interface UserResponse extends GenericApiResponse {
  payload: User[];
}

export enum UserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
}

export interface Roles {
  permissions: string;
  ctes: null;
  actions: string[];
  id: number;
  organizationId: number;
  name: string;
  isDefault: boolean;
  createdAt: Date;
  updatedAt: Date;
  user_roles: UserRoles;
  assignedPermissions: AssignedPermission[];
}

export interface AssignedPermission {
  id: number;
  name: string;
  slug: string;
  sectionId: number;
  createdAt: Date;
  updatedAt: Date;
  RolePermissions: RolePermissions;
}

export interface RolePermissions {
  id: number;
  roleId: number;
  permissionId: number;
  allowed: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface UsersRoles {
  createdAt: Date;
  updatedAt: Date;
  roleId: number;
  userId: number;
}

export interface MergedPermission {
  slug: string;
  allowed: boolean;
}
