import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
import {
  GenericApiResponse,
  PaginationParams,
} from '../general-store/general.model';
import {
  CountRecallItemsRequest,
  CountRecallItemsResponse,
  CreateRecallRequest,
  CreateRecallResponse,
  RecallResponse,
  RecallsResponse,
  RecallUsersResponse,
  UpdateRecallCTEParams,
  UpdateRecallCTEsParams,
  UpdateRecallKDEsParams,
  UpdateRecallStatusRequest,
} from './recall-management.model';

@Injectable({
  providedIn: 'root',
})
export class RecallManagementService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getRecalls = (
    paginationParams: PaginationParams
  ): Observable<RecallsResponse> => {
    return this.http
      .get<RecallsResponse>(`${this.apiUrl}/api/recall-products`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching recalls: ' + error.message)
          );
        })
      );
  };

  public getRecall = (id: number): Observable<RecallResponse> => {
    return this.http
      .get<RecallResponse>(`${this.apiUrl}/api/recall-products/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching recall: ' + error.message)
          );
        })
      );
  };

  public getRecallUsers = (
    paginationParams: PaginationParams
  ): Observable<RecallUsersResponse> => {
    return this.http
      .get<RecallUsersResponse>(`${this.apiUrl}/api/recall-products/users`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching recall users: ' + error.message)
          );
        })
      );
  };

  public getRecallRequests = (
    paginationParams: PaginationParams
  ): Observable<RecallsResponse> => {
    return this.http
      .get<RecallsResponse>(`${this.apiUrl}/api/recall-products/assigned`, {
        params: {
          page: paginationParams.first / paginationParams.rows + 1,
          limit: paginationParams.rows,
          sortBy: paginationParams.sortBy,
          sortOrder: paginationParams.sortOrder,
          search: paginationParams.search,
          filters: paginationParams.filters,
        },
      })
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error fetching recall requests: ' + error.message)
          );
        })
      );
  };

  public createRecall = (
    payload: CreateRecallRequest
  ): Observable<CreateRecallResponse> => {
    return this.http
      .post<CreateRecallResponse>(`${this.apiUrl}/api/recall-products`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error creating recall: ' + error.message)
          );
        })
      );
  };

  public updateRecall = (
    id: number,
    payload: CreateRecallRequest
  ): Observable<CreateRecallResponse> => {
    return this.http
      .put<CreateRecallResponse>(
        `${this.apiUrl}/api/recall-products/${id}`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating recall: ' + error.message)
          );
        })
      );
  };

  public updateRecallStatus = (
    id: number,
    payload: UpdateRecallStatusRequest
  ): Observable<GenericApiResponse> => {
    return this.http
      .patch<GenericApiResponse>(
        `${this.apiUrl}/api/recall-products/${id}/status`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error updating recall: ' + error.message)
          );
        })
      );
  };

  public deleteRecall = (id: number): Observable<GenericApiResponse> => {
    return this.http
      .delete<GenericApiResponse>(`${this.apiUrl}/api/recall-products/${id}`)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting recall: ' + error.message)
          );
        })
      );
  };

  public countRecallItems(
    payload: CountRecallItemsRequest
  ): Observable<CountRecallItemsResponse> {
    return this.http
      .post<CountRecallItemsResponse>(
        `${this.apiUrl}/api/qr-product-data/kde/counts`,
        payload
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error counting recall items');
          });
        })
      );
  }

  public addRecallKDE(payload: any): Observable<void> {
    delete (payload as any).id;
    return this.http
      .post<void>(`${this.apiUrl}/api/recall-product-kdes`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateRecallKDE(id: any, payload: any): Observable<void> {
    delete (payload as any).id;
    return this.http
      .patch<void>(`${this.apiUrl}/api/recall-product-kdes/${id}`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateRecallKDEs(payload: UpdateRecallKDEsParams): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/recall-product-kdes/bulk`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating recall product KDEs');
          });
        })
      );
  }

  public updateRecallCTE(
    id: number,
    payload: UpdateRecallCTEParams
  ): Observable<void> {
    delete (payload as any).id;
    return this.http
      .put<void>(`${this.apiUrl}/api/recall-product-ctes/${id}`, payload)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public updateRecallCTEs(payload: UpdateRecallCTEsParams): Observable<void> {
    return this.http
      .put<void>(`${this.apiUrl}/api/recall-product-ctes/bulk`, payload)
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(() => {
            console.log(error);
            return new Error('Error updating recall product CTEs');
          });
        })
      );
  }

  public deleteRecallKde = (id: number): Observable<GenericApiResponse> => {
    return this.http
      .delete<GenericApiResponse>(
        `${this.apiUrl}/api/recall-product-kdes/${id}`
      )
      .pipe(
        map(response => response),
        catchError(error => {
          return throwError(
            () => new Error('Error deleting recall KDE: ' + error.message)
          );
        })
      );
  };
}
