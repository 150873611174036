<div class="mb-[100px]">
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false">
    <app-add-cteform
      [title]="sidebar.title"
      [data]="sidebar.data"
      [type]="sidebar.type"
      [id]="sidebar.id"
      [cteType]="cteType"
      (actionSuccess)="sidebar.visible = false"></app-add-cteform>
  </app-right-form-base>
  <app-right-form-base
    [isVisible]="kdeSidebar.visible"
    (sidebarHidden)="kdeSidebar.visible = false"
    [widthClass]="kdeSidebar.width"
    [title]="kdeSidebar.title">
    <app-add-kdeform
      [title]="kdeSidebar.title"
      [data]="kdeSidebar.data"
      [id]="kdeSidebar.id"
      [type]="kdeSidebar.type"
      (actionSuccess)="updateKde($event)"
      [cteID]="currentCteId"
      [cteType]="cteType"></app-add-kdeform>
  </app-right-form-base>
  @if (showHeading) {
    <div class="flex items-center justify-between mb-4">
      <span class="font-bold">CTEs</span>
      @if (!isProductCtes) {
        <p-button
          id="add-cte-button"
          icon="pi pi-plus"
          label="Add New CTE"
          appAccess="add-cte-kde"
          (onClick)="
            sidebar = {
              visible: true,
              title: 'Create New CTE',
              type: 'ADD',
              width: 'w-50',
            }
          " />
      }
    </div>
  }
  <div
    cdkDropList
    [cdkDropListData]="ctes"
    (cdkDropListDropped)="dropCte($event)">
    @for (cte of ctes; track cte; let i = $index) {
      <div cdkDrag class="mb-4">
        <div class="flex items-center" (click)="preventSelection($event)">
          <p-accordion
            class="w-full"
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up">
            <p-accordion-panel [value]="i">
              <p-accordion-header>
                <div class="flex justify-between gap-2 w-full items-center">
                  <div class="flex items-center gap-3">
                    <i class="pi pi-align-justify"></i>
                    @if (isS3Url(cte.icon)) {
                      <img
                        [src]="cte.icon"
                        class="w-[25px] h-[25px] rounded-full"
                        alt="CTE Icon" />
                    } @else {
                      <i class="{{ cte.icon }} text-xl font-bold"></i>
                    }
                    <div class="mb-[-5px]">
                      {{ cte.title }}
                    </div>
                  </div>
                  <div class="flex items-center gap-3">
                    <p-button
                      label="Add KDE"
                      icon="pi pi-plus"
                      [text]="true"
                      class="z-10"
                      (click)="addKDE(cte, $event)" />
                    <p-divider layout="vertical" />
                    <div class="z-10 mr-5">
                      <span
                        (click)="preventSelection($event); op.toggle($event)"
                        class="pi pi-ellipsis-v cursor-pointer"></span>
                      <p-popover #op [dismissable]="true">
                        <div class="flex flex-col">
                          <p-button
                            class="dark"
                            icon="pi pi-pencil"
                            label="Edit"
                            [text]="true"
                            (click)="actionClickedKDE('EDIT', cte.id, cte)" />
                          <p-button
                            [disabled]="cte.isDefault"
                            class="dark"
                            icon="pi pi-trash"
                            (click)="actionClickedKDE('DELETE', cte.id, cte)"
                            label="Delete"
                            [text]="true" />
                        </div>
                      </p-popover>
                    </div>
                  </div>
                </div>
              </p-accordion-header>
              <p-accordion-content>
                <div class="mb-2">KDEs</div>
                <div
                  cdkDropList
                  [cdkDropListData]="
                    cte.productKdes || cte.kdes || cte.recallProductKdes
                  "
                  (cdkDropListDropped)="dropKde($event, cte)">
                  <div
                    *ngFor="
                      let kde of cte.productKdes ||
                        cte.kdes ||
                        cte.recallProductKdes
                    "
                    cdkDrag
                    class="mb-4">
                    <div
                      class="px-[14px] py-[8px] rounded bg-light"
                      (click)="preventSelection($event)">
                      <div class="flex items-center justify-between">
                        <div class="flex items-center gap-3">
                          <i class="pi pi-align-justify mr-2"></i>
                          <div class="mb-[-5px]">
                            {{ kde.title }}
                          </div>
                        </div>
                        <div class="flex items-center gap-3">
                          <p-divider layout="vertical" />
                          <div class="z-10 mr-5">
                            <span
                              (click)="
                                preventSelection($event); op.toggle($event)
                              "
                              class="pi pi-ellipsis-v cursor-pointer"></span>
                            <p-popover #op [dismissable]="true">
                              <div class="flex flex-col">
                                <p-button
                                  [disabled]="kde.isDefault"
                                  class="dark"
                                  icon="pi pi-pencil"
                                  label="Edit"
                                  [text]="true"
                                  (click)="
                                    actionClickedCTE('EDIT', cte.id, cte)
                                  " />
                                <p-button
                                  [disabled]="kde.isDefault"
                                  class="dark"
                                  icon="pi pi-trash"
                                  (click)="
                                    actionClickedCTE('DELETE', cte.id, cte)
                                  "
                                  label="Delete"
                                  [text]="true" />
                              </div>
                            </p-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </p-accordion-content>
            </p-accordion-panel>
          </p-accordion>
        </div>
      </div>
    }
  </div>
</div>
@if (isProductCtes && showNavigationButtons) {
  <app-right-form-footer
    [formValid]="true"
    (callAction)="updateProduct()"
    label="Update & Close"
    (callCancel)="actionSuccess.emit()">
  </app-right-form-footer>
}
