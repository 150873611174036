import {
  Component,
  Input,
  Signal,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SuperAdminMenu, OrganizationMenu } from '../../data/menu.data';
import { Store } from '@ngxs/store';
import { UserStateSelectors } from '../../../store/users-store/user.selectors';
import {
  MergedPermission,
  UserRoles,
} from '../../../store/users-store/user.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    PanelMenuModule,
    ButtonModule,
    CommonModule,
    RouterModule,
  ],
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isExpanded = true;
  items: MenuItem[] = OrganizationMenu;
  userRole$: Signal<UserRoles> = this.store.selectSignal(
    UserStateSelectors.getUserRole
  );
  userPermissions$: Signal<MergedPermission[]> = this.store.selectSignal(
    UserStateSelectors.getUserPermissions
  );

  constructor(
    private store: Store,
    private router: Router
  ) {
    if (this.userRole$() === UserRoles.SUPER_ADMIN) {
      this.items = SuperAdminMenu;
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activateParentMenu();
      });
    this.activateParentMenu();
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  toggleMenu(item: MenuItem, event: Event) {
    this.items = this.items.map(i => {
      return {
        ...i,
        expanded: false,
      };
    });
    if (item.items && item.items?.length > 0 && !this.isExpanded) {
      this.isExpanded = true;
      item.expanded = !item.expanded;
    } else {
      event.stopPropagation();
      item.expanded = !item.expanded;
    }
  }

  checkPermissions(slugs: string[]): boolean {
    if (this.userRole$() === UserRoles.SUPER_ADMIN) {
      return true;
    }
    if (!slugs || slugs.length === 0) return true;
    return this.userPermissions$().some(
      p => slugs.includes(p.slug) && p.allowed
    );
  }
  activateParentMenu() {
    const currentRoute = this.router.url;

    this.items.forEach(parent => {
      if (
        parent.items?.some(child => currentRoute.includes(child.routerLink))
      ) {
        parent.expanded = true;
      }
    });
  }

  isMenuActive(item: MenuItem): boolean {
    const currentRoute = this.router.url;
    if (this.isExpanded) {
      return currentRoute.includes(item.routerLink);
    }

    return (
      !!currentRoute.includes(item.routerLink) ||
      !!item.items?.some(subItem => currentRoute.includes(subItem.routerLink))
    );
  }

  hasActiveChild(item: MenuItem): boolean {
    return (
      item.items?.some(subItem =>
        this.router.url.includes(subItem.routerLink)
      ) ?? false
    );
  }
}
