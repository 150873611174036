<p-confirmDialog></p-confirmDialog>
<div class="grid grid-cols-3 gap-5">
  <div>
    <div>
      <p class="font-bold m-0">Current Plan</p>
      <p class="text-sm mt-0">General Information of the subscribed plan</p>

      @if (user$()?.currentPlan) {
        <div class="bg-slate-50 p-4 rounded mb-3">
          <p class="text-sm m-0">Plan</p>
          <p class="font-bold m-0">
            {{ user$()?.currentPlan?.productDetails?.name }}
          </p>
          <small>${{ user$()!.currentPlan!.amount / 100 }} USD / month</small>
        </div>
      } @else {
        <div class="bg-slate-50 p-5 rounded mb-3 text-center">
          <span class="text-white bg-black p-2 rounded text-sm"
            >Not Subscribed</span
          >
        </div>
      }

      <p-button [routerLink]="'/subscription/plans'">Change Plan</p-button>
    </div>
  </div>
  <div>
    <div>
      <p class="font-bold m-0">Select Payment Method</p>
      <p class="text-sm mt-0">Select a payment method to continue</p>

      <div class="bg-slate-50 p-4 rounded mb-5">
        <p class="font-bold m-0">Credit / Debit Card</p>
        <p class="text-sm mt-0">
          We accept Master Card, Visa & American Express
        </p>

        @for (card of cards$(); track card) {
          <div class="flex items-center justify-between mb-3">
            <div class="flex">
              <p-radioButton
                name="card"
                [value]="card"
                [inputId]="card.id"
                [(ngModel)]="selectedCard"></p-radioButton>
              <label class="ml-5" [for]="card.id">
                <p class="text-sm m-0 font-bold">
                  {{ card.billing_details.name }} ({{
                    card.card.brand.toUpperCase()
                  }})
                </p>
                <p class="m-0">**** **** **** {{ card.card.last4 }}</p>
              </label>
            </div>
            <div>
              @if (card.isDefault) {
                <small
                  class="text-xs bg-primary-color text-white p-2 rounded-lg"
                  >Default</small
                >
              }
            </div>
          </div>
        }
        @if (cards$().length === 0) {
          <div class="mt-6 text-center">
            <span class="text-white bg-black p-2 rounded text-sm"
              >No cards added</span
            >
          </div>
        }
        @if (showCardDetailsForm) {
          <div class="mt-9">
            <form [formGroup]="cardDetailsForm">
              <div>
                <label class="block mb-2">Name on Card</label>
                <input
                  type="text"
                  formControlName="nameOnCard"
                  placeholder="Name on Card"
                  pInputText
                  class="w-full"
                  [class.input-danger]="
                    cardDetailsForm.get('nameOnCard')?.invalid &&
                    cardDetailsForm.get('nameOnCard')?.touched
                  " />
                <app-error-message
                  [control]="cardDetailsForm.get('nameOnCard')"
                  label="Name on Card"></app-error-message>
              </div>
              <div class="mb-5">
                <label class="block mb-2">Card Details</label>
                <div
                  id="card-element"
                  class="border-solid p-3 rounded border-[1px] border-gray-400 bg-white"></div>
              </div>
            </form>
          </div>
        }
        <div class="mt-9">
          <p-button [outlined]="true" (click)="toggleCardDetailsForm()"
            >Add New Card</p-button
          >
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="bg-slate-50 p-5 rounded">
      <p class="text-xl font-bold">Bill Summary</p>

      <div class="flex items-center justify-between">
        <p class="text-sm">Hamara Group</p>
        <p class="text-sm font-bold">USD 43,300</p>
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <p class="text-sm">Hamara Group SA</p>
        <p class="text-sm font-bold">USD 4,300</p>
      </div>
      <hr />
      <div class="flex items-center justify-between">
        <p class="text-sm">Total</p>
        <p class="text-sm font-bold">USD 47,600</p>
      </div>
    </div>
  </div>
</div>
