<div class="w-full p-8">
  <div class="flex justify-between items-center">
    <div>
      <h2>{{ pageTitle }}</h2>
      <p-breadcrumb class="max-w-full" [model]="breadcrumbs" />
    </div>
  </div>

  <div class="grid grid-cols-3 gap-4 mt-5">
    <div
      class="bg-white p-10 rounded cursor-pointer hover:bg-primary hover:text-white transition duration-300 group"
      (click)="redirect('farmers_profile', 199)">
      <div class="text-md font-bold mb-3 text-primary group-hover:text-white">
        Farmers Profile
      </div>
      <div class="text-xs">
        This report provides key insights into individual farmers, including
        crop types, farming practices, production data, and geographical
        location. It highlights the farmer's operational performance,
        sustainability efforts, and technology use, offering valuable
        information for stakeholders to support informed decision-making.
      </div>
    </div>

    <div
      class="bg-white p-10 rounded cursor-pointer hover:bg-primary hover:text-white transition duration-300 group"
      (click)="redirect('cooperatives_suppliers', 200)">
      <div class="text-md font-bold mb-3 text-primary group-hover:text-white">
        Cooperative Suppliers
      </div>
      <div class="text-xs">
        This report outlines key information on suppliers working with farming
        cooperatives. It includes details on product offerings, delivery
        timelines, and pricing structures. This report helps stakeholders
        evaluate supplier reliability and optimize supply chain operations.
      </div>
    </div>

    <div
      class="bg-white p-10 rounded cursor-pointer hover:bg-primary hover:text-white transition duration-300 group"
      (click)="redirect('farmers_delivery', 200)">
      <div class="text-md font-bold mb-3 text-primary group-hover:text-white">
        Farmers Delivery
      </div>
      <div class="text-xs">
        This report tracks and monitors deliveries from farmers to distribution
        centers or cooperatives. It includes data on delivery schedules,
        quantities, and any logistical issues. This report aids in streamlining
        the delivery process and improving overall efficiency.
      </div>
    </div>

    <div
      class="bg-white p-10 rounded cursor-pointer hover:bg-primary hover:text-white transition duration-300 group"
      (click)="redirect('bags_purchase_view', 200)">
      <div class="text-md font-bold mb-3 text-primary group-hover:text-white">
        Bags Purchase View
      </div>
      <div class="text-xs">
        This report provides a detailed summary of bag purchases made by farmers
        or cooperatives. It includes data on quantities, pricing, and supplier
        information. This report helps in managing inventory and ensuring timely
        procurement of necessary supplies for farming operations.
      </div>
    </div>
  </div>
</div>
