import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { ClearUploadedFiles } from '../../../../store/general-store/general.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-right-form-header',
  imports: [ButtonModule, DividerModule, CommonModule],
  templateUrl: './right-form-header.component.html',
  styleUrl: './right-form-header.component.scss',
})
export class RightFormHeaderComponent {
  @Input() title!: string;
  @Input() disabled!: boolean;
  @Input() processing!: boolean;
  @Input() showActionButton = true;
  @Input() actionLabel = 'Save';
  @Output() callAction = new EventEmitter<boolean>();
  @Output() callCancel = new EventEmitter<boolean>();

  constructor(private store: Store) {}

  clearUploadedFiles() {
    this.store.dispatch(new ClearUploadedFiles());
  }
}
