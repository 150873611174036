import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ClearReport } from '../../../../store/report-store/report.actions';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-reports-list',
  imports: [BreadcrumbModule],
  templateUrl: './reports-list.component.html',
  styleUrl: './reports-list.component.scss',
})
export class ReportsListComponent {
  pageTitle = '';
  breadcrumbs: MenuItem[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
      this.breadcrumbs = data['breadcrumbs'];
    });
  }

  redirect(report: string, productId: number) {
    this.store.dispatch(new ClearReport());
    this.router.navigateByUrl(`/reports/view/${report}/${productId}`);
  }
}
