import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { RecallManagementStateModel } from './recall-management.model';
import { RecallManagementService } from './recall-management.services';
import {
  AddRecallKDE,
  CountRecallItems,
  CreateRecall,
  DeleteRecall,
  DeleteRecallKDE,
  GetAllRecalls,
  GetRecall,
  GetRecallList,
  GetRecallRequestList,
  GetRecallUsers,
  ResetRecall,
  SetFilters,
  SetRecall,
  UpdateRecall,
  UpdateRecallCTE,
  UpdateRecallCTEs,
  UpdateRecallKDE,
  UpdateRecallKDEs,
  UpdateRecallStatus,
} from './recall-management.actions';
import { HideSideMenu } from '../general-store/general.actions';

@State<RecallManagementStateModel>({
  name: 'RecallManagement',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    recalls: [],
    allRecalls: [],
    recallRequests: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    recallUsersPagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    recallUsersHeaders: [],
    recallUsers: [],
    recallFilters: {
      product: null,
      cte: null,
      kde: null,
      kdeValues: [],
    },
    recall: null,
    countRecallItems: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class RecallManagementState {
  constructor(
    private recallManagementService: RecallManagementService,
    private notificationService: NotificationService
  ) {}

  @Action(GetRecallList)
  getRecallList(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecallList
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecalls(action.payload).pipe(
      tap(async res => {
        patchState({
          recalls: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetRecall)
  getRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecall(action.id).pipe(
      tap(async res => {
        patchState({
          recall: res.payload,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetRecallUsers)
  getRecallUsers(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecallUsers
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecallUsers(action.payload).pipe(
      tap(async res => {
        patchState({
          recallUsers: res.payload,
          isProcessing: false,
          recallUsersPagination: res.pagination,
          recallUsersHeaders: res.headers,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(GetAllRecalls)
  getAllRecalls({ patchState }: StateContext<RecallManagementStateModel>) {
    return this.recallManagementService
      .getRecallRequests({
        first: 0,
        rows: 10000,
        sortBy: 'recallName',
        sortOrder: 'ASC',
        filters: '',
        search: '',
      })
      .pipe(
        tap(async res => {
          patchState({
            allRecalls: res.payload,
          });
        })
      );
  }

  @Action(GetRecallRequestList)
  getRecallRequestList(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: GetRecallRequestList
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.getRecallRequests(action.payload).pipe(
      tap(async res => {
        patchState({
          recallRequests: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(CreateRecall)
  createRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: CreateRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.createRecall(action.payload).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
          recall: res.payload.recallProduct,
        });
        this.notificationService.openSuccessToast(
          'New Recall has been created successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(UpdateRecall)
  updateRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService
      .updateRecall(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isProcessing: false,
            recall: res.payload.recallProduct,
          });
          this.notificationService.openSuccessToast(
            'Recall has been updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }

  @Action(UpdateRecallStatus)
  updateRecallStatus(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallStatus
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService
      .updateRecallStatus(action.id, action.payload)
      .pipe(
        tap(async res => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Recall status has been updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }

  @Action(DeleteRecall)
  deleteRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: DeleteRecall
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.deleteRecall(action.id).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(SetFilters)
  setFilters(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: SetFilters
  ) {
    patchState({
      recallFilters: action.filters,
    });
  }

  @Action(ResetRecall)
  resetRecall({ patchState }: StateContext<RecallManagementStateModel>) {
    patchState({
      recallFilters: {
        product: null,
        cte: null,
        kde: null,
        kdeValues: [],
      },
      recall: null,
      countRecallItems: null,
    });
  }

  @Action(SetRecall)
  setRecall(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: SetRecall
  ) {
    patchState({
      recall: action.recall,
    });
  }

  @Action(CountRecallItems)
  countRecallItems(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: CountRecallItems
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.countRecallItems(action.payload).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
          countRecallItems: res.payload.count,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(AddRecallKDE)
  addRecallKde(
    {
      getState,
      patchState,
      dispatch,
    }: StateContext<RecallManagementStateModel>,
    action: AddRecallKDE
  ) {
    return this.recallManagementService.addRecallKDE(action.kde).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Recall KDE has been created successfully!'
        );
        const state = getState();
        const recall = state.recall;
        dispatch(new GetRecall(recall?.id || 0));
        const cteIndex = recall!.recallProductCtes.findIndex(
          cte => cte.id === action.kde.productCteId
        );

        if (cteIndex !== -1) {
          const updatedKdes = [
            ...recall!.recallProductCtes[cteIndex].recallProductKdes,
            action.kde,
          ];

          patchState({
            recall: {
              ...recall!,
              recallProductCtes: recall!.recallProductCtes.map((cte, index) =>
                index === cteIndex
                  ? { ...cte, recallProductKdes: updatedKdes }
                  : cte
              ),
            },
          });
        }
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(DeleteRecallKDE)
  deleteRecallKde(
    { patchState, getState }: StateContext<RecallManagementStateModel>,
    action: DeleteRecallKDE
  ) {
    patchState({ isProcessing: true });
    return this.recallManagementService.deleteRecallKde(action.id).pipe(
      tap(async res => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Recall KDE deleted successfully!'
        );
        const state = getState();
        const recall = state.recall;

        const cteIndex = recall!.recallProductCtes.findIndex(
          cte => cte.id === action.cte.id
        );

        if (cteIndex !== -1) {
          const updatedKdes = recall!.recallProductCtes[
            cteIndex
          ].recallProductKdes.filter(kde => kde.id !== action.id);

          recall!.recallProductCtes[cteIndex].recallProductKdes = updatedKdes;
          patchState({
            recall,
          });
        }
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(UpdateRecallKDE)
  updateProductKDE(
    { getState, patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallKDE
  ) {
    return this.recallManagementService
      .updateRecallKDE(action.id, action.kde)
      .pipe(
        tap(async () => {
          patchState({ isProcessing: false });
          this.notificationService.openSuccessToast(
            'Recall KDE updated successfully!'
          );

          const state = getState();
          const recall = state.recall;

          const cteIndex = recall!.recallProductCtes.findIndex(cte =>
            cte.recallProductKdes.some(kde => kde.id === action.id)
          );

          if (cteIndex !== -1) {
            const updatedKdes = recall!.recallProductCtes[
              cteIndex
            ].recallProductKdes.map(kde =>
              kde.id === action.id ? { ...kde, ...action.kde } : kde
            );

            patchState({
              recall: {
                ...recall!,
                recallProductCtes: recall!.recallProductCtes.map(
                  (cte, index) =>
                    index === cteIndex
                      ? { ...cte, productKdes: updatedKdes }
                      : cte
                ),
              },
            });
          }
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }

  @Action(UpdateRecallKDEs)
  updateRecallKdes(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallKDEs
  ) {
    return this.recallManagementService.updateRecallKDEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Recall KDEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(UpdateRecallCTEs)
  updateRecallCtes(
    { patchState }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallCTEs
  ) {
    return this.recallManagementService.updateRecallCTEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Recall CTEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
      })
    );
  }

  @Action(UpdateRecallCTE)
  updateRecallCte(
    {
      patchState,
      dispatch,
      getState,
    }: StateContext<RecallManagementStateModel>,
    action: UpdateRecallCTE
  ) {
    return this.recallManagementService
      .updateRecallCTE(action.id, action.payload)
      .pipe(
        tap(async () => {
          this.notificationService.openSuccessToast(
            'Recall CTE updated successfully!'
          );
          const state = getState();
          const recall = state.recall;
          if (recall) {
            dispatch([new GetRecall(recall.id), new HideSideMenu()]);
          }
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
        })
      );
  }
}
