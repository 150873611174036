<div>
  <h3 class="mb-5">Subscription Details</h3>

  @if (isLoading$() || !user$()) {
    <div class="flex items-center justify-center w-100">
      <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
    </div>
  } @else if (user$()?.currentPlan) {
    <div class="bg-white p-5 rounded flex items-center justify-between">
      @if (!hasHighestPlan) {
        <div class="flex items-center">
          <div>
            <span
              class="pi pi-credit-card text-primary font-bold mr-3 text-xl"></span>
          </div>
          <p>
            You are currently subscribed to
            <span class="text-primary font-bold">{{
              user$()?.currentPlan?.productDetails?.name
            }}</span
            >. Upgrade to unlock more limits!
          </p>
        </div>
      } @else {
        <div class="flex items-center">
          <div>
            <span
              class="pi pi-credit-card text-primary font-bold mr-3 text-xl"></span>
          </div>
          <p>
            You are currently subscribed to
            <span class="text-primary font-bold">{{
              user$()?.currentPlan?.productDetails?.name
            }}</span
            >. You can enjoy highest level of features!
          </p>
        </div>
      }
      <div>
        <p-button
          [routerLink]="'/subscription/plans'"
          label="View Plans"
          icon="pi pi-eye"
          outlined="true" />
      </div>
    </div>
    <div class="grid grid-cols-5 gap-3 mt-3">
      @for (usage of subscriptionUsage$(); track usage) {
        <div class="bg-white rounded p-5">
          <h2 class="mt-5 mb-5">
            {{ usage.used | numberK }}
            <sub
              ><small class="font-normal text-sm"
                >/ {{ usage.total | numberK }}</small
              ></sub
            >
          </h2>
          <p>{{ usage.label }}</p>
        </div>
      }
    </div>
  } @else {
    <app-no-subscription-banner
      class="block"
      title="Subscribe to Continue!"
      description="Actions on this platform are available to subscribed users only. Please
          upgrade your account to proceed."></app-no-subscription-banner>
  }
</div>
