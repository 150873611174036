<div class="relative h-full flex flex-col">
  <div class="sticky top-0 z-10 p-4 bg-light">
    <h2>Request Demo</h2>
    <p>
      Submit your details below to schedule a personalized demo and learn how
      reGenesis can streamline your product journey tracking.
    </p>
  </div>

  <div class="flex-1 px-4">
    <form [formGroup]="demoRequestForm">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p>Full Name</p>
          <input
            type="text"
            formControlName="fullName"
            placeholder="e.g. William Marshal"
            pInputText
            class="w-full"
            [class.input-danger]="
              demoRequestForm.get('fullName')?.invalid &&
              demoRequestForm.get('fullName')?.touched
            " />
          <app-error-message
            *ngIf="
              demoRequestForm.get('fullName')?.invalid &&
              demoRequestForm.get('fullName')?.touched
            "
            [control]="demoRequestForm.get('fullName')"
            label="Full Name"></app-error-message>
        </div>
        <div>
          <p>Organization Name</p>
          <input
            type="text"
            formControlName="name"
            placeholder="e.g. Millan Foundation"
            pInputText
            class="w-full"
            [class.input-danger]="
              demoRequestForm.get('organizationName')?.invalid &&
              demoRequestForm.get('organizationName')?.touched
            " />
          <app-error-message
            *ngIf="
              demoRequestForm.get('organizationName')?.invalid &&
              demoRequestForm.get('organizationName')?.touched
            "
            [control]="demoRequestForm.get('organizationName')"
            label="Organization Name"></app-error-message>
        </div>
      </div>
      <div>
        <p>Email Address</p>
        <p-iconfield>
          <p-inputicon class="pi pi-envelope" />
          <input
            type="email"
            formControlName="email"
            placeholder="e.g. example@example.com"
            pInputText
            class="w-full"
            [class.input-danger]="
              demoRequestForm.get('email')?.invalid &&
              demoRequestForm.get('email')?.touched
            " />
        </p-iconfield>
        <app-error-message
          *ngIf="
            demoRequestForm.get('email')?.invalid &&
            demoRequestForm.get('email')?.touched
          "
          [control]="demoRequestForm.get('email')"
          label="Email Address"></app-error-message>
      </div>
      <div
        class="grid gap-4"
        [ngClass]="{
          'grid-cols-1': selectedType !== 'OTHER',
          'grid-cols-2': selectedType === 'OTHER',
        }">
        <div [ngClass]="{ 'col-span-2': selectedType !== 'OTHER' }">
          <div class="flex justify-between items-center">
            <p>Organization Type</p>
            <i
              class="pi pi-info-circle cursor-pointer text-indigo-500 font-bold"
              pTooltip="Select the type of organization you operate to help us customize your demo experience."
              tooltipPosition="top">
            </i>
          </div>
          <p-select
            formControlName="type"
            [options]="organizationTypes"
            placeholder="e.g. Farmer, Trader, Retailer"
            class="w-full"
            (onChange)="onTypeChange($event.value)"
            [class.input-danger]="
              demoRequestForm.get('type')?.invalid &&
              demoRequestForm.get('type')?.touched
            ">
          </p-select>
          <app-error-message
            *ngIf="
              demoRequestForm.get('type')?.invalid &&
              demoRequestForm.get('type')?.touched
            "
            [control]="demoRequestForm.get('type')"
            label="Organization Type">
          </app-error-message>
        </div>

        <div *ngIf="selectedType === 'OTHER'">
          <p>Other Type</p>
          <input
            type="text"
            formControlName="otherType"
            placeholder="Specify Organization Type"
            pInputText
            class="w-full"
            [class.input-danger]="
              demoRequestForm.get('otherType')?.invalid &&
              demoRequestForm.get('otherType')?.touched
            " />
          <app-error-message
            *ngIf="
              demoRequestForm.get('otherType')?.invalid &&
              demoRequestForm.get('otherType')?.touched
            "
            [control]="demoRequestForm.get('otherType')"
            label="Other Type">
          </app-error-message>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p>Country</p>
          <p-select
            formControlName="countryCode"
            [options]="countries"
            optionLabel="name"
            placeholder="Select Country"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            class="w-full"
            filter="true"
            filterBy="name"
            [class.input-danger]="
              demoRequestForm.get('countryCode')?.invalid &&
              demoRequestForm.get('countryCode')?.touched
            " />
          <app-error-message
            *ngIf="
              demoRequestForm.get('countryCode')?.invalid &&
              demoRequestForm.get('countryCode')?.touched
            "
            [control]="demoRequestForm.get('countryCode')"
            label="Country"></app-error-message>
        </div>

        <div>
          <p>Organization Phone</p>
          <p-inputGroup>
            <p-inputGroupAddon>
              <strong>{{
                demoRequestForm.get('countryCode')?.value.dialCode ?? '-'
              }}</strong>
            </p-inputGroupAddon>
            <input
              type="text"
              formControlName="phone"
              placeholder="1233445567"
              pInputText
              class="w-full"
              [class.input-danger]="
                demoRequestForm.get('phone')?.invalid &&
                demoRequestForm.get('phone')?.touched
              " />
          </p-inputGroup>
          <app-error-message
            *ngIf="
              demoRequestForm.get('phone')?.invalid &&
              demoRequestForm.get('phone')?.touched
            "
            [control]="demoRequestForm.get('phone')"
            label="Organization Phone"></app-error-message>
        </div>
      </div>

      <div>
        <p>Business Address</p>
        <p-iconfield>
          <p-inputicon class="pi pi-map-marker" />
          <input
            type="text"
            formControlName="address"
            placeholder="e.g. Street Address, City, Country"
            pInputText
            class="w-full"
            [class.input-danger]="
              demoRequestForm.get('address')?.invalid &&
              demoRequestForm.get('address')?.touched
            " />
        </p-iconfield>
        <app-error-message
          *ngIf="
            demoRequestForm.get('address')?.invalid &&
            demoRequestForm.get('address')?.touched
          "
          [control]="demoRequestForm.get('address')"
          label="Business Address"></app-error-message>
      </div>

      <div class="flex items-center gap-3">
        <p-checkbox
          [binary]="true"
          inputId="privacyPolicy"
          formControlName="privacyPolicy" />
        <label for="privacyPolicy"
          >By submitting, you agree to our
          <span class="text-primary cursor-pointer">Terms & Conditions</span>
          and
          <span class="text-primary cursor-pointer"
            >Privacy Policy.</span
          ></label
        >
      </div>

      <div>
        <p-button
          label="Request Your Personalized Demo"
          icon="pi pi-arrow-right"
          iconPos="right"
          class="w-100"
          (click)="requestDemo()"
          [disabled]="demoRequestForm.invalid"
          [loading]="processing$()" />
      </div>

      <div class="mt-4">
        <p class="text-center">
          Already have an Account?<a
            class="text-primary no-underline cursor-pointer"
            href="/">
            Login</a
          >
        </p>
      </div>
    </form>
  </div>
</div>
