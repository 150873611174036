import { Component, effect, OnInit, Signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetReport } from '../../../../store/report-store/report.actions';
import { Report } from '../../../../store/report-store/report.model';
import { ReportStateSelectors } from '../../../../store/report-store/report.selectors';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { TextTransformPipe } from '../../../pipes/text-transform.pipe';
import { CamelToSentencePipe } from '../../../pipes/camel-to-sentence.pipe';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-reports-view',
  imports: [
    TableModule,
    CommonModule,
    TextTransformPipe,
    CamelToSentencePipe,
    ButtonModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './reports-view.component.html',
  styleUrl: './reports-view.component.scss',
})
export class ReportsViewComponent implements OnInit {
  pageTitle = '';
  report = '';
  productId = 0;

  report$: Signal<Report | null> = this.store.selectSignal(
    ReportStateSelectors.getReport
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.pageTitle = data['title'];
    });
    this.activatedRoute.params.subscribe(param => {
      this.report = param['report'];
      this.productId = param['productId'];
    });
  }

  ngOnInit(): void {
    this.getReport();
  }

  getReport() {
    this.store.dispatch(new GetReport(this.productId, this.report));
  }

  redirect() {
    this.router.navigateByUrl('/reports/list');
  }
}
