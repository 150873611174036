import { Selector } from '@ngxs/store';
import { ReportState } from './report.store';
import { ReportStateModel } from './report.model';

export class ReportStateSelectors {
  @Selector([ReportState])
  static getReport(state: ReportStateModel) {
    return state.report;
  }
}
