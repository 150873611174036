<div class="flex flex-col">
  <div class="sticky top-0 z-10 pt-5 bg-light">
    <app-right-form-header
      [processing]="processing$()"
      [disabled]="form.invalid"
      [title]="title"
      (callCancel)="form.reset(); actionSuccess.emit(false)"
      (callAction)="action()"></app-right-form-header>
  </div>
  <div class="flex-1">
    <form [formGroup]="form" class="full-height-form">
      <div>
        <p>Title</p>
        <input
          pInputText
          class="w-full"
          [class.input-danger]="
            form.get('title')?.invalid && form.get('title')?.touched
          "
          placeholder="Enter Title"
          formControlName="title" />
        <app-error-message
          *ngIf="form.get('title')?.invalid && form.get('title')?.touched"
          [control]="form.get('title')"
          label="KDE Title"></app-error-message>
      </div>

      <div>
        <p>Description</p>
        <textarea
          rows="5"
          class="w-full resize-none"
          pInputTextarea
          [class.input-danger]="
            form.get('description')?.invalid && form.get('description')?.touched
          "
          formControlName="description"
          placeholder="Add description here"></textarea>
        <app-error-message
          *ngIf="
            form.get('description')?.invalid && form.get('description')?.touched
          "
          [control]="form.get('description')"
          label="KDE Description"></app-error-message>
      </div>

      <div>
        <div class="flex items-center justify-around gap-3">
          <div class="w-100 flex gap-3 items-center bg-white p-4 rounded">
            <p-checkbox
              inputId="gs1"
              binary="true"
              formControlName="gs1"
              value="GS1" />
            <label for="gs1">GS1</label>
          </div>
          <div class="w-100 flex gap-3 items-center bg-white p-4 rounded">
            <p-checkbox
              inputId="fsma2024"
              binary="true"
              formControlName="fsma204"
              value="FSMA204" />
            <label for="fsma2024">FSMA204</label>
          </div>
        </div>
      </div>

      <div>
        <p>Select KDE Inputs</p>
        <p-tabs value="0" scrollable>
          <p-tablist>
            <p-tab
              value="0"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'TEXT'"
              >Input</p-tab
            >
            <p-tab
              value="1"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'DATE'"
              >Date</p-tab
            >
            <p-tab
              value="2"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'RADIO'"
              >Radio</p-tab
            >
            <p-tab
              value="3"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'SELECT'"
              >Dropdwon</p-tab
            >
            <p-tab
              value="4"
              [disabled]="
                type === 'EDIT' && form.value.inputType !== 'LOCATION'
              "
              >Location</p-tab
            >
            <p-tab
              value="5"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'FILE'"
              >Attachment</p-tab
            >
            <p-tab
              value="6"
              [disabled]="type === 'EDIT' && form.value.inputType !== 'LINK_QR'"
              >Link QR</p-tab
            >
          </p-tablist>
          <p-tabpanels>
            <p-tabpanel value="0">
              <div class="mt-6 w-100">
                <div class="mb-2">Types</div>
                <p-select
                  formControlName="inputType"
                  [options]="inputOptions"
                  placeholder="Select" />
              </div>
            </p-tabpanel>

            <p-tabpanel value="1">
              <div class="mt-6 w-100">
                <div class="mb-2">Types</div>
                <p-select
                  formControlName="inputType"
                  [options]="dateInputOptions"
                  placeholder="Select" />
              </div>
            </p-tabpanel>

            <p-tabpanel value="2">
              <div class="mt-6 flex items-center gap-2">
                <input
                  pInputText
                  class="w-full"
                  placeholder="Key"
                  formControlName="radioKey" />
                <input
                  pInputText
                  class="w-full"
                  placeholder="Value"
                  formControlName="radioValue" />
                <span
                  class="pi pi-plus text-primary cursor-pointer"
                  (click)="addRadioOption()"></span>
              </div>

              @for (radio of radioOptions; track radio; let i = $index) {
                <div
                  class="flex items-center justify-between mt-[10px] mb-[10px]">
                  <div>
                    <p>Key: {{ radio.key }}</p>
                    <p>Value: {{ radio.value }}</p>
                  </div>
                  <span
                    class="pi pi-trash cursor-pointer text-danger"
                    (click)="deleteRadioOption(i)"></span>
                </div>
              }
            </p-tabpanel>

            <p-tabpanel value="3">
              <div class="mt-6 flex items-center gap-2">
                <input
                  pInputText
                  class="w-full"
                  placeholder="Key"
                  formControlName="dropdownKey" />
                <input
                  pInputText
                  class="w-full"
                  placeholder="Value"
                  formControlName="dropdownValue" />
                <span
                  class="pi pi-plus text-primary cursor-pointer"
                  (click)="addDropdownOption()"></span>
              </div>

              @for (radio of dropdownOptions; track radio; let i = $index) {
                <div
                  class="flex items-center justify-between mt-[10px] mb-[10px]">
                  <div>
                    <p>Key: {{ radio.key }}</p>
                    <p>Value: {{ radio.value }}</p>
                  </div>
                  <span
                    class="pi pi-trash cursor-pointer text-danger"
                    (click)="deleteDropdownOption(i)"></span>
                </div>
              }
            </p-tabpanel>

            <p-tabpanel value="4">
              <p class="text-center text-primary">
                <i class="pi pi-map-marker text-[50px] mt-[20px]"></i>
              </p>
            </p-tabpanel>

            <p-tabpanel value="5">
              <p class="text-center text-primary">
                <i class="pi pi-paperclip text-[50px] mt-[20px]"></i>
              </p>
            </p-tabpanel>
            <p-tabpanel value="6"></p-tabpanel>
          </p-tabpanels>
        </p-tabs>
        <app-validation
          [form]="form"
          [type]="form.get('inputType')?.value"
          [formType]="type"
          [options]="getOptionsForInputType(form.get('inputType')?.value)"
          [filters]="
            form.get('validations')?.get('linkQRSettings')?.get('details')
              ?.value
          "
          [charCount]="charCount">
        </app-validation>
      </div>
    </form>
  </div>
</div>
