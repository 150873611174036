<div class="w-full p-8">
  <p-confirmDialog></p-confirmDialog>
  <app-right-form-base
    [isVisible]="sidebar.visible"
    (sidebarHidden)="sidebar.visible = false"
    [title]="sidebar.title">
    <app-add-organization-admin-form
      (actionSuccess)="getUserList($event); sidebar.visible = false">
    </app-add-organization-admin-form>
  </app-right-form-base>
  <div class="flex justify-between items-center">
    <h2>{{ pageTitle }}</h2>
    <div class="d-flex">
      <p-button
        appAccess="add-user"
        icon="pi pi-plus"
        label="Add Admin User"
        (onClick)="
          sidebar = {
            visible: true,
            title: 'Add New Admin User',
            type: 'ADD',
            width: 'w-50',
          }
        " />
    </div>
  </div>
  <app-data-table
    [first]="first"
    export="USER"
    [rows]="rows"
    [sortBy]="sortBy"
    [sortOrder]="sortOrder"
    [selectedFilters]="selectedFilters"
    [data$]="this.data$"
    [headers$]="this.headers$"
    [isProcessing$]="this.isProcessing$"
    [pagination$]="this.pagination$"
    (actionClickedEvent)="actionClicked($event)"
    (refreshEvent)="refresh($event)" />
</div>
