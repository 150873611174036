import { Action, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  DemoRequest,
  ValidateOtp,
  UserLogin,
  ResendOtp,
  GetUser,
  GetUserList,
  AddAdminUser,
  ForgotPassword,
  ResetPassword,
  DeleteUser,
  UpdateUser,
  UpdateUserSelf,
  UpdateUserPassword,
  SendOtp,
  ResetUserState,
  UpdateUserSelfWithNotifications,
} from './user.actions';

import { UserRoles, UserStateModel } from './user.model';
import { UserService } from './user.service';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../../app/services/notification.service';
import { Router } from '@angular/router';
import { AppStartAction, HideSideMenu } from '../general-store/general.actions';
import { mergePermissions } from '../../app/services/global.service';
import { environment } from '../../environments/environment';
@State<UserStateModel>({
  name: 'User',
  defaults: {
    isLoggedIn: false,
    isOTPVerified: false,
    isRecallOTPVerified: false,
    authActionProcessing: false,
    user: undefined,
    users: [],
    isLoading: false,
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    organizationLogoUrl: '',
    userRole: UserRoles.SUPER_ADMIN,
    userAllowedPermissions: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserState {
  constructor(
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly ngZone: NgZone,
    private readonly router: Router
  ) {}

  @Action(UserLogin)
  login({ patchState }: StateContext<UserStateModel>, action: UserLogin) {
    patchState({ authActionProcessing: true });
    return this.userService.loginRequest(action.payload).pipe(
      tap(async res => {
        patchState({
          isLoggedIn: true,
          user: res.payload,
          authActionProcessing: false,
          userRole: res.payload.roleName,
        });
        this.ngZone.run(() => {
          this.router.navigate(['/otp']);
        });
      }),
      catchError(async () => {
        patchState({ isLoggedIn: false, authActionProcessing: false });
      })
    );
  }

  @Action(DemoRequest)
  demoRequest(
    { patchState }: StateContext<UserStateModel>,
    action: DemoRequest
  ) {
    patchState({ authActionProcessing: true });
    return this.userService.demoRequest(action.payload).pipe(
      tap(async res => {
        patchState({ authActionProcessing: false });
        if (res) {
          this.notificationService.openSuccessToast(
            'Demo request submitted successfully'
          );
          this.ngZone.run(() => {
            this.router.navigate(['/'], { replaceUrl: true });
          });
        }
      }),
      catchError(async () => {
        patchState({ authActionProcessing: false });
      })
    );
  }

  @Action(ResetUserState)
  resetRecall({ patchState }: StateContext<UserStateModel>) {
    patchState({
      isRecallOTPVerified: false,
    });
  }

  @Action(ValidateOtp)
  validateOtp(
    { patchState, dispatch }: StateContext<UserStateModel>,
    action: ValidateOtp
  ) {
    patchState({
      authActionProcessing: true,
      isOTPVerified: false,
      isRecallOTPVerified: false,
    });
    return this.userService.validateOtp(action.userId, action.code).pipe(
      tap(async res => {
        if (action.type === 'LOGIN') {
          localStorage.setItem('regen_access_token', res.payload.token);
          patchState({
            isOTPVerified: true,
            user: res.payload.user,
            authActionProcessing: false,
            userRole: res.payload.user.roleName,
          });
          dispatch(new AppStartAction());
          this.ngZone.run(() => {
            this.router.navigate(['/dashboards/landing'], {
              replaceUrl: true,
            });
          });
        } else if (action.type === 'RECALL') {
          patchState({
            authActionProcessing: false,
            isRecallOTPVerified: true,
          });
        }
      }),
      catchError(async () => {
        patchState({
          authActionProcessing: false,
          isOTPVerified: false,
          isRecallOTPVerified: false,
        });
      })
    );
  }

  @Action(DeleteUser)
  deleteUser({ patchState }: StateContext<UserStateModel>, action: DeleteUser) {
    patchState({ isLoading: true });
    return this.userService.deleteUser(action.id).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast('User deleted successfully!');
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(UpdateUser)
  updateUser({ patchState }: StateContext<UserStateModel>, action: UpdateUser) {
    patchState({ isLoading: true });
    return this.userService.updateUser(action.id, action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast('User updated successfully!');
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(UpdateUserSelf)
  updateUserSelf(
    { patchState, dispatch }: StateContext<UserStateModel>,
    action: UpdateUserSelf
  ) {
    patchState({ isLoading: true });
    return this.userService.updateUserSelf(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast('updated successfully!');
        dispatch(new GetUser());
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }
  @Action(UpdateUserSelfWithNotifications)
  updateUserSelfWithNotifications(
    { patchState, dispatch }: StateContext<UserStateModel>,
    action: UpdateUserSelf
  ) {
    patchState({ isLoading: true });
    return this.userService.updateUserSelf(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast('updated successfully!');
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(UpdateUserPassword)
  updateUserPassword(
    { patchState }: StateContext<UserStateModel>,
    action: UpdateUserPassword
  ) {
    patchState({ isLoading: true });
    return this.userService.updateUserPassword(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        this.notificationService.openSuccessToast(
          'Your password has been updated successfully!'
        );
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(ResendOtp)
  resendOtp({ patchState }: StateContext<UserStateModel>, action: ResendOtp) {
    return this.userService.resendOtp(action.userId).pipe(
      tap(async () => {
        patchState({
          isOTPVerified: false,
        });
        this.notificationService.openSuccessToast(
          'OTP has been successfully resent'
        );
      })
    );
  }

  @Action(SendOtp)
  sendOtp({ patchState }: StateContext<UserStateModel>, action: SendOtp) {
    return this.userService.sendOtp(action.type);
  }

  @Action(GetUser)
  getUser({ patchState }: StateContext<UserStateModel>) {
    return this.userService.getUser().pipe(
      tap(async res => {
        const permissions = mergePermissions(res.payload.roles);
        patchState({
          user: res.payload,
          isLoggedIn: true,
          organizationLogoUrl:
            res.payload.organizations && res.payload.organizations.length > 0
              ? res.payload.organizations[0].logo
              : `${environment.bucketUrl}/images/regenesis-icon-light.png`,

          userRole: res.payload.roleName,
          userAllowedPermissions: permissions,
        });
      })
    );
  }

  @Action(GetUserList)
  getUserList(
    { patchState }: StateContext<UserStateModel>,
    action: GetUserList
  ) {
    patchState({ isLoading: true });
    return this.userService.getUsers(action.payload).pipe(
      tap(async res => {
        patchState({
          users: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isLoading: false,
        });
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(AddAdminUser)
  addAdminUser(
    { patchState, dispatch }: StateContext<UserStateModel>,
    action: AddAdminUser
  ) {
    patchState({ isLoading: true });
    return this.userService.addAdminUser(action.payload).pipe(
      tap(async () => {
        patchState({
          isLoading: false,
        });
        dispatch(new HideSideMenu());
        this.notificationService.openSuccessToast(
          'Admin user has been created successfully!'
        );
      }),
      catchError(async () => {
        patchState({ isLoading: false });
      })
    );
  }

  @Action(ForgotPassword)
  forgotPassword(
    { patchState }: StateContext<UserStateModel>,
    action: ForgotPassword
  ) {
    patchState({ authActionProcessing: true });
    return this.userService.forgotPassword(action.email).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Password reset instructions have been sent to your email!'
        );
        this.ngZone.run(() => {
          this.router.navigate(['/'], {
            replaceUrl: true,
          });
        });
        patchState({ authActionProcessing: false });
      }),
      catchError(async () => {
        patchState({ isLoading: false, authActionProcessing: false });
      })
    );
  }

  @Action(ResetPassword)
  resetPassword(
    { patchState }: StateContext<UserStateModel>,
    action: ResetPassword
  ) {
    patchState({ authActionProcessing: true });
    return this.userService.resetPassword(action.token, action.password).pipe(
      tap(async () => {
        patchState({ authActionProcessing: false });
        this.notificationService.openSuccessToast(
          'Password reset successfully. Please login to continue'
        );
        this.ngZone.run(() => {
          this.router.navigate(['/'], {
            replaceUrl: true,
          });
        });
      }),
      catchError(async () => {
        patchState({ isLoading: false, authActionProcessing: false });
      })
    );
  }
}
