import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';
import { NotificationService } from '../../app/utilities/notification.service';
import { OrganizationStateModel } from './organizations.model';
import {
  AddOrganizationCTE,
  CreateOrganization,
  DeleteOrganization,
  DeleteOrganizationCTE,
  GetAllOrganizations,
  GetOrganizationCTEs,
  GetOrganizationList,
  UpdateOrganization,
  UpdateOrganizationCTE,
  UpdateOrganizationCTEs,
  UpdateOrganizationKDEs,
} from './organizations.actions';
import { OrganizationService } from './organizations.service';
import {
  ClearUploadedFiles,
  HideSideMenu,
} from '../general-store/general.actions';
@State<OrganizationStateModel>({
  name: 'Organization',
  defaults: {
    isProcessing: false,
    isListRefreshing: false,
    organizations: [],
    allOrganizations: [],
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
    headers: [],
    organizatonCTEs: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class OrganizationState {
  constructor(
    private organizationService: OrganizationService,
    private notificationService: NotificationService
  ) {}

  @Action(CreateOrganization)
  createOrganization(
    { patchState }: StateContext<OrganizationStateModel>,
    action: CreateOrganization
  ) {
    patchState({ isProcessing: true });
    return this.organizationService.createOrganization(action.payload).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false, isListRefreshing: false });
        if (error.error.error.message === 'Validation error') {
          this.notificationService.openValidationToast(
            error.error.error.details
          );
        } else {
          this.notificationService.openErrorToast(error.error.error.message);
        }
        return throwError(() => error);
      })
    );
  }

  @Action(GetOrganizationList)
  getOrganizationList(
    { patchState }: StateContext<OrganizationStateModel>,
    action: GetOrganizationList
  ) {
    patchState({ isProcessing: true });
    return this.organizationService.getOrganizations(action.payload).pipe(
      tap(async res => {
        patchState({
          organizations: res.payload,
          pagination: res.pagination,
          headers: res.headers,
          isProcessing: false,
        });
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(GetAllOrganizations)
  getAllOrganizations({ patchState }: StateContext<OrganizationStateModel>) {
    return this.organizationService
      .getOrganizations({
        first: 0,
        rows: 10000,
        sortBy: 'name',
        sortOrder: 'ASC',
        filters: '',
        search: '',
      })
      .pipe(
        tap(async res => {
          patchState({
            allOrganizations: res.payload,
          });
        }),
        catchError(async error => {
          return throwError(() => error);
        })
      );
  }

  @Action(GetOrganizationCTEs)
  getOrganizationCTEs({ patchState }: StateContext<OrganizationStateModel>) {
    patchState({ isProcessing: true });
    return this.organizationService.getOrganizationCTEs().pipe(
      tap(async res => {
        const sortedArr = res.sort((a, b) => a.order - b.order);
        for (const element of sortedArr) {
          element.kdes = element.kdes.sort((a, b) => a.order - b.order);
        }
        patchState({
          isProcessing: false,
          organizatonCTEs: sortedArr,
        });
      }),
      catchError(async error => {
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteOrganization)
  deleteOrganization(
    { patchState }: StateContext<OrganizationStateModel>,
    action: DeleteOrganization
  ) {
    patchState({ isProcessing: true });
    return this.organizationService.deleteOrganization(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Organization deleted successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteOrganizationCTE)
  deleteOrganizationCTE(
    { patchState, dispatch }: StateContext<OrganizationStateModel>,
    action: DeleteOrganizationCTE
  ) {
    patchState({ isProcessing: true });
    return this.organizationService.deleteOrganizationCTE(action.id).pipe(
      tap(async () => {
        patchState({
          isProcessing: false,
        });
        this.notificationService.openSuccessToast(
          'Organization CTE deleted successfully!'
        );
        dispatch([new GetOrganizationCTEs()]);
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateOrganization)
  updateOrganization(
    { patchState }: StateContext<OrganizationStateModel>,
    action: UpdateOrganization
  ) {
    patchState({ isProcessing: true });
    return this.organizationService
      .updateOrganization(action.payload, action.id)
      .pipe(
        tap(async () => {
          patchState({
            isProcessing: false,
          });
          this.notificationService.openSuccessToast(
            'Organization updated successfully!'
          );
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateOrganizationCTEs)
  updateOrganizationCtes(
    { patchState }: StateContext<OrganizationStateModel>,
    action: UpdateOrganizationCTEs
  ) {
    return this.organizationService.updateOrganizationCTEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Organization CTEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateOrganizationCTE)
  updateOrganizationCte(
    { patchState, dispatch }: StateContext<OrganizationStateModel>,
    action: UpdateOrganizationCTE
  ) {
    return this.organizationService
      .updateOrganizationCTE(action.id, action.payload)
      .pipe(
        tap(async () => {
          this.notificationService.openSuccessToast(
            'Organization CTE updated successfully!'
          );
          dispatch([new GetOrganizationCTEs(), new HideSideMenu()]);
        }),
        catchError(async error => {
          patchState({ isProcessing: false });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdateOrganizationKDEs)
  updateOrganizationKdes(
    { patchState }: StateContext<OrganizationStateModel>,
    action: UpdateOrganizationKDEs
  ) {
    return this.organizationService.updateOrganizationKDEs(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'Organization KDEs order updated successfully!'
        );
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }

  @Action(AddOrganizationCTE)
  addOrganizationCTE(
    { patchState, dispatch }: StateContext<OrganizationStateModel>,
    action: AddOrganizationCTE
  ) {
    patchState({ isProcessing: true });
    return this.organizationService.addOrganizationCTE(action.payload).pipe(
      tap(async () => {
        this.notificationService.openSuccessToast(
          'CTE has been created successfully!'
        );
        patchState({
          isProcessing: false,
        });
        dispatch([
          new GetOrganizationCTEs(),
          new HideSideMenu(),
          new ClearUploadedFiles(),
        ]);
      }),
      catchError(async error => {
        patchState({ isProcessing: false });
        return throwError(() => error);
      })
    );
  }
}
