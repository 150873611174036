@for (card of cards$(); track card) {
  <div class="flex items-center justify-between mb-3">
    <div class="flex">
      <p-radioButton
        name="card"
        [value]="card"
        [inputId]="card.id"
        [(ngModel)]="selectedCard"
        (ngModelChange)="setCardAsDefault(card)"></p-radioButton>
      <label class="ml-5" [for]="card.id">
        <p class="text-sm m-0 font-bold">
          {{ card.billing_details.name }} ({{ card.card.brand.toUpperCase() }})
        </p>
        <p class="m-0">**** **** **** {{ card.card.last4 }}</p>
      </label>
    </div>
    <div class="flex items-center gap-5">
      @if (card.isDefault) {
        <small class="text-xs bg-primary text-white p-2 rounded">Default</small>
      } @else {
        <div>
          <span
            (click)="deleteCard(card)"
            class="pi pi-trash text-danger cursor-pointer"></span>
        </div>
      }
    </div>
  </div>
}

@if (cards$().length === 0 && !isLoading$()) {
  <div class="mt-6 text-center">
    <span class="text-white bg-black p-2 rounded text-sm">No cards added</span>
  </div>
}

@if (showCardDetailsForm) {
  <div class="mt-9">
    <form [formGroup]="cardDetailsForm">
      <div>
        <p>Name on Card</p>
        <input
          type="text"
          formControlName="nameOnCard"
          placeholder="Name on Card"
          pInputText
          class="w-full"
          [class.input-danger]="
            cardDetailsForm.get('nameOnCard')?.invalid &&
            cardDetailsForm.get('nameOnCard')?.touched
          " />
        <app-error-message
          [control]="cardDetailsForm.get('nameOnCard')"
          label="Name on Card"></app-error-message>
      </div>
      <div>
        <p>Card Details</p>
        <div id="card-element" class="input-class"></div>
      </div>
    </form>
  </div>
}
<div>
  <p-button
    [disabled]="isLoading$() || isLoading || !isValidCard"
    [outlined]="true"
    icon="pi pi-plus"
    (click)="toggleCardDetailsForm()"
    [label]="!showCardDetailsForm ? 'Add New Card' : 'Save Card'" />
</div>
