@if (user$()?.currentPlan?.productDetails?.name === 'Basic Plan') {
  <app-no-subscription-banner
    class="mb-5 block"
    title="Feature Unavailable!"
    description="Customizing the product design template is restricted on your subscribed plan. Please upgrade your subscription to access full customization."></app-no-subscription-banner>
}
@if (product$()) {
  <div class="grid grid-cols-4 min-h-[100%]">
    <div
      class="border-r-[1px] border-l-[0px] border-t-[0px] border-b-[0px] border-solid border-r-[#ccc]">
      @for (module of pageModules; track module) {
        <p
          class="cursor-pointer mb-[15px]"
          [ngClass]="{
            'text-primary border-solid border-l-[3px] border-t-[0px] border-b-[0px] border-r-[0px]  border-primary pl-3 font-bold':
              selectedPageModule?.id === module.id,
          }"
          (click)="
            selectedPageModule = module;
            selectedPageModuleTemplate = null;
            selectPageModuleTemplate()
          ">
          {{ module.title }}
        </p>
      }
    </div>
    <div class="col-span-2 max-h-[85vh] overflow-y-auto pl-[30px] pr-[30px]">
      <div
        class="mb-[20px]"
        [ngClass]="{
          'disabled-section':
            user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
        }">
        <p class="mb-[15px]">Select a template:</p>
        @for (
          template of selectedPageModule?.pageModuleTemplates;
          track template;
          let i = $index
        ) {
          <div
            class="h-[130px] w-[130px] bg-primary rounded-[20px] text-white text-center flex justify-center items-center p-[20px] cursor-pointer text-[15px] p-overlay-badge"
            pTooltip="Click to Insert"
            tooltipPosition="top"
            (click)="addModule(template)">
            <p>{{ template.title }} {{ i + 1 }}</p>
            <span class="p-badge p-badge-dark">{{
              getTemplateUsedCount(template)
            }}</span>
          </div>
        }
      </div>
      @if (selectedPageModuleTemplate) {
        <p-tabs value="0">
          <p-tablist>
            <p-tab
              value="0"
              [ngClass]="{
                'disabled-section':
                  user$()?.currentPlan?.productDetails?.name === 'Basic Plan',
              }"
              >Components</p-tab
            >
            <p-tab value="1">Values</p-tab>
            <p-tab value="2">Styles</p-tab>
          </p-tablist>
          <p-tabpanels>
            <p-tabpanel value="0">
              <p-orderList
                class="w-full sidebar-orderlist"
                [value]="selectedPageModuleTemplateComponents"
                [dragdrop]="selectedPageModule?.rules?.canAddNewComponents"
                (onReorder)="reorderComponent()">
                <ng-template let-component pTemplate="item">
                  <div>
                    @switch (component.type) {
                      @case ('text') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-align-justify mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.text }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules
                                  ?.canAddNewComponents &&
                                filter(
                                  selectedPageModule?.rules
                                    ?.componentItemOptions,
                                  'type',
                                  component.type
                                ).length > 0
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('image') {
                        <div
                          (click)="
                            $event.preventDefault(); $event.stopPropagation()
                          "
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-image mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{
                                  getFileName(component.value.url)
                                }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('video') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-video mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.url }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('file') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-image mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{
                                  getFileName(component.value.url)
                                }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('boolean') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-calendar mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{
                                  component.value.isActive
                                    .toString()
                                    .toUpperCase()
                                }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('link') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-link mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.label }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('tab') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-table mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.title }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('cte') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-map-marker mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.text }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('icon') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-share-alt mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.link }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                      @case ('carousel') {
                        <div
                          class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-between">
                          <div class="flex gap-2 items-center">
                            <span class="pi pi-images mr-[10px]"></span>
                            <div>
                              <div class="font-bold">{{ component.label }}</div>
                              <div>
                                <small>{{ component.value.title }}</small>
                              </div>
                            </div>
                          </div>
                          <div class="!z-[9]">
                            <span
                              *ngIf="
                                selectedPageModule?.rules?.canAddNewComponents
                              "
                              class="pi pi-trash text-danger cursor-pointer"
                              (click)="deleteComponent(component)"></span>
                          </div>
                        </div>
                      }
                    }
                  </div>
                </ng-template>
              </p-orderList>
              <div class="mt-[15px]">
                @if (selectedPageModule?.rules?.canAddNewComponents) {
                  <div
                    class="bg-[#f9f9fb] rounded-[5px] p-[15px] flex items-center justify-center component-item text-primary font-bold text-center cursor-pointer border border-primary"
                    (click)="
                      showComponentItemOptions = !showComponentItemOptions
                    ">
                    <span class="pi pi-plus mr-[10px]"></span>
                    Add
                  </div>
                  @if (showComponentItemOptions) {
                    <div
                      class="bg-[#f9f9fb] rounded-[10px] transition-colors duration-300 ease-in-out mt-[10px]">
                      @for (
                        option of selectedPageModule?.rules
                          ?.componentItemOptions;
                        track option
                      ) {
                        <div
                          class="p-[15px] cursor-pointer hover:!text-primary"
                          (click)="addComponent(option)">
                          <span [class]="option.icon + ' mr-[10px]'"></span>
                          {{ option.label }}
                        </div>
                      }
                    </div>
                  }
                }
              </div>
            </p-tabpanel>
            <p-tabpanel value="1">
              @for (
                component of sortArray(
                  selectedPageModuleTemplate!.pageModuleTemplateDataContents
                );
                track component
              ) {
                <form>
                  @switch (component.type) {
                    @case ('text') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <input
                          pInput
                          class="w-100"
                          type="text"
                          [(ngModel)]="component.value.text"
                          (change)="updateComponent(component)"
                          [name]="component.label" />
                      </div>
                    }
                    @case ('link') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="flex gap-4 items-center">
                          <input
                            [(ngModel)]="component.value.label"
                            [name]="component.label + '_label'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Button / Link Label"
                            (change)="updateComponent(component)" />
                          <input
                            [(ngModel)]="component.value.url"
                            [name]="component.label + '_value'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Button / Link Value"
                            (change)="updateComponent(component)" />
                        </div>
                      </div>
                    }
                    @case ('tab') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="flex gap-4 items-center">
                          <input
                            [(ngModel)]="component.value.title"
                            [name]="component.label + '_label'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Tab Title"
                            (change)="updateComponent(component)" />
                          <input
                            [(ngModel)]="component.value.description"
                            [name]="component.label + '_value'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Tab Description"
                            (change)="updateComponent(component)" />
                        </div>
                      </div>
                    }
                    @case ('cte') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="flex gap-4 items-center mb-4">
                          <input
                            [(ngModel)]="component.value.text"
                            [name]="component.label + '_label'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Title"
                            (change)="updateComponent(component)" />
                          <div class="relative w-100">
                            <input
                              class="w-100"
                              type="date"
                              pInput
                              [(ngModel)]="component.value.date"
                              [name]="component.label + '_date'"
                              placeholder="Date"
                              (ngModelChange)="updateComponent(component)" />
                            <button
                              *ngIf="component.value.date"
                              type="button"
                              class="absolute top-[8px] right-[2px] p-button bg-white text-black border-none"
                              (click)="component.value.date = ''">
                              <i class="pi pi-times"></i>
                            </button>
                          </div>
                        </div>
                        <div class="flex items-center mb-4">
                          <input
                            [(ngModel)]="component.value.description"
                            [name]="component.label + '_value'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Description"
                            (change)="updateComponent(component)" />
                        </div>

                        <div class="flex">
                          <div
                            class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
                            <input
                              type="file"
                              [id]="'attachment_' + component.id"
                              class="hidden"
                              accept="image/jpeg, image/png, image/svg+xml"
                              (change)="upload($event, component)" />
                            <label
                              [for]="'attachment_' + component.id"
                              class="flex flex-col items-center justify-center cursor-pointer text-center">
                              <i class="pi pi-upload mb-[5px]"></i>
                              <p class="text-primary font-bold m-0">
                                {{ 'Icon' }}
                              </p>
                            </label>
                          </div>
                          @if (component.value.url) {
                            <div class="relative">
                              <img
                                [src]="component.value.url"
                                class="w-[150px] h-[150px] ml-[20px] object-cover" />
                              <button
                                class="absolute top-[10px] p-[8px] right-[10px] text-white bg-primary border-none rounded-full w-[15px] h-[15px] flex items-center justify-center cursor-pointer"
                                (click)="removeLogo(component)">
                                <i class="pi pi-times size icon-style"></i>
                              </button>
                            </div>
                          }
                        </div>
                        <div class="mb-5">
                          <input
                            [(ngModel)]="component.value.url"
                            [name]="component.label + '_url'"
                            [placeholder]="component.label + ' URL'"
                            pInput
                            class="w-100"
                            type="text"
                            (change)="updateComponent(component)" />
                        </div>
                      </div>
                    }
                    @case ('video') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-0 font-bold block">{{
                          component.label
                        }}</label>
                        <small class="!mb-2 block"
                          >(Supported URLs: Youtube & Vimeo)</small
                        >
                        <div class="flex gap-4 items-center">
                          <input
                            [(ngModel)]="component.value.videoTitle"
                            [name]="component.label + '_title'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Video Title"
                            (change)="updateComponent(component)" />
                          <input
                            pInput
                            class="w-100"
                            type="text"
                            [name]="component.label + '_url'"
                            (change)="updateComponent(component)"
                            placeholder="Video URL"
                            [(ngModel)]="component.value.url" />
                        </div>
                      </div>
                    }
                    @case ('image') {
                      <div class="flex">
                        <div
                          class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
                          <input
                            type="file"
                            [id]="'attachment_' + component.id"
                            class="hidden"
                            accept="image/jpeg, image/png, image/svg+xml"
                            (change)="upload($event, component)" />
                          <label
                            [for]="'attachment_' + component.id"
                            class="flex flex-col items-center justify-center cursor-pointer text-center">
                            <i class="pi pi-upload mb-[5px]"></i>
                            <p class="text-primary font-bold m-0">
                              {{ component.label }}
                            </p>
                          </label>
                        </div>
                        @if (component.value.url) {
                          <div class="relative">
                            <img
                              [src]="component.value.url"
                              [alt]="component.value.altText"
                              class="w-[150px] h-[150px] ml-[20px] object-cover" />
                            <button
                              class="absolute top-[10px] p-[8px] right-[10px] text-white bg-primary border-none rounded-full w-[15px] h-[15px] flex items-center justify-center cursor-pointer"
                              (click)="removeLogo(component)">
                              <i class="pi pi-times size icon-style"></i>
                            </button>
                          </div>
                        }
                      </div>
                      <div class="mb-5">
                        <input
                          [(ngModel)]="component.value.url"
                          [name]="component.label + '_url'"
                          [placeholder]="component.label + ' URL'"
                          pInput
                          class="w-100"
                          type="text"
                          (change)="updateComponent(component)" />
                      </div>
                    }
                    @case ('carousel') {
                      <div class="mb-5">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="flex">
                          <div
                            class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
                            <input
                              type="file"
                              [id]="'attachment_' + component.id"
                              class="hidden"
                              accept="image/jpeg, image/png, image/svg+xml"
                              (change)="upload($event, component)" />
                            <label
                              [for]="'attachment_' + component.id"
                              class="flex flex-col items-center justify-center cursor-pointer text-center">
                              <i class="pi pi-upload mb-[5px]"></i>
                              <p class="text-primary font-bold m-0">
                                {{ component.label }}
                              </p>
                            </label>
                          </div>
                          @if (component.value.url) {
                            <div class="relative">
                              <img
                                [src]="component.value.url"
                                [alt]="component.value.title"
                                class="w-[150px] h-[150px] ml-[20px] object-cover" />
                              <button
                                class="absolute top-[10px] p-[8px] right-[10px] text-white bg-primary border-none rounded-full w-[15px] h-[15px] flex items-center justify-center cursor-pointer"
                                (click)="removeLogo(component)">
                                <i class="pi pi-times size icon-style"></i>
                              </button>
                            </div>
                          }
                        </div>
                        <div class="mb-5">
                          <input
                            [(ngModel)]="component.value.url"
                            [name]="component.label + '_url'"
                            [placeholder]="component.label + ' URL'"
                            pInput
                            class="w-100"
                            type="text"
                            (change)="updateComponent(component)" />
                        </div>
                        <div class="flex gap-4 items-center">
                          <input
                            [(ngModel)]="component.value.title"
                            [name]="component.label + '_label'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Carousel Title"
                            (change)="updateComponent(component)" />
                          <input
                            [(ngModel)]="component.value.description"
                            [name]="component.label + '_value'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Carousel Description"
                            (change)="updateComponent(component)" />
                        </div>
                      </div>
                    }
                    @case ('icon') {
                      <div class="mb-5">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="flex">
                          <div
                            class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
                            <input
                              type="file"
                              [id]="'attachment_' + component.id"
                              class="hidden"
                              accept="image/jpeg, image/png, image/svg+xml"
                              (change)="upload($event, component)" />
                            <label
                              [for]="'attachment_' + component.id"
                              class="flex flex-col items-center justify-center cursor-pointer text-center">
                              <i class="pi pi-upload mb-[5px]"></i>
                              <p class="text-primary font-bold m-0">
                                Upload Icon
                              </p>
                            </label>
                          </div>
                          @if (component.value.url) {
                            <div class="relative">
                              <img
                                [src]="component.value.url"
                                class="w-[150px] h-[150px] ml-[20px] object-cover" />
                              <button
                                class="absolute top-[10px] p-[8px] right-[10px] text-white bg-primary border-none rounded-full w-[15px] h-[15px] flex items-center justify-center cursor-pointer"
                                (click)="removeLogo(component)">
                                <i class="pi pi-times size icon-style"></i>
                              </button>
                            </div>
                          }
                        </div>
                        <div class="mb-5">
                          <input
                            [(ngModel)]="component.value.url"
                            [name]="component.label + '_url'"
                            [placeholder]="component.label + ' URL'"
                            pInput
                            class="w-100"
                            type="text"
                            (change)="updateComponent(component)" />
                        </div>
                        <div class="flex gap-4 items-center">
                          <input
                            [(ngModel)]="component.value.link"
                            [name]="component.label + '_label'"
                            pInput
                            class="w-100"
                            type="text"
                            placeholder="Social Icon URL"
                            (change)="updateComponent(component)" />
                        </div>
                      </div>
                    }
                    @case ('file') {
                      <div class="flex">
                        <div
                          class="w-[150px] h-[150px] border-2 border-dashed border-[#ccc] flex justify-center items-center relative cursor-pointer rounded transition-colors duration-300 ease-in-out mb-[15px] hover:!border-primary">
                          <input
                            type="file"
                            [id]="'attachment_' + component.id"
                            class="hidden"
                            (change)="upload($event, component)" />
                          <label
                            [for]="'attachment_' + component.id"
                            class="flex flex-col items-center justify-center cursor-pointer text-center">
                            <i class="pi pi-upload mb-[5px]"></i>
                            <p class="text-primary font-bold m-0">
                              {{ component.label }}
                            </p>
                          </label>
                        </div>
                        @if (component.value.url) {
                          <div class="relative w-[150px] h-[150px] ml-[20px]">
                            <img
                              [src]="component.value.url"
                              [alt]="
                                component.value.altText || 'Uploaded image'
                              "
                              class="w-full h-full object-cover rounded" />
                            <button
                              class="absolute top-[10px] p-[8px] right-[10px] text-white bg-primary border-none rounded-full w-[15px] h-[15px] flex items-center justify-center cursor-pointer"
                              (click)="removeLogo(component)">
                              <i class="pi pi-times size icon-style"></i>
                            </button>
                          </div>
                        }
                      </div>
                      <div class="mb-5">
                        <input
                          [(ngModel)]="component.value.url"
                          [name]="component.label + '_url'"
                          [placeholder]="component.label + ' URL'"
                          pInput
                          class="w-100"
                          type="text"
                          (change)="updateComponent(component)" />
                      </div>
                    }
                    @case ('boolean') {
                      <div class="mb-[15px]">
                        <label for="" class="!mb-2 font-bold block">{{
                          component.label
                        }}</label>
                        <div class="mt-[5px]">
                          <p-inputSwitch
                            [name]="component.label"
                            (onChange)="updateComponent(component)"
                            [(ngModel)]="component.value.isActive" />
                        </div>
                      </div>
                    }
                  }
                </form>
              }
            </p-tabpanel>
            <p-tabpanel value="2">
              <div>
                @for (
                  element of selectedPageModuleTemplate.styles.colors
                    | keyvalue;
                  track element
                ) {
                  <div class="mb-[10px] bg-[#f9f9fb] rounded-[5px] p-[15px]">
                    <label class="font-bold mb-3 block">{{
                      element.key | camelToSentence
                    }}</label>
                    @for (color of element.value | keyvalue; track color) {
                      <div class="flex items-center justify-between mb-1">
                        <label>{{ color.key | camelToSentence }}</label>
                        <ngx-colors
                          ngx-colors-trigger
                          [(ngModel)]="color.value"
                          (ngModelChange)="
                            updateStyles(element, color)
                          "></ngx-colors>
                      </div>
                    }
                  </div>
                }
              </div>
            </p-tabpanel>
          </p-tabpanels>
        </p-tabs>
      }
    </div>
    <div class="max-h-[85vh] overflow-y-auto border-l border-gray-300">
      <p-tabs value="0">
        <p-tablist>
          <p-tab value="0">Standard</p-tab>
          <p-tab value="1">Recall</p-tab>
        </p-tablist>
        <p-tabpanels>
          <p-tabpanel value="0">
            @for (content of pageContent; track content; let i = $index) {
              @if (content.pageModuleTemplateDataContents.length === 0) {
                <div
                  class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[300px] text-center hover:!border-2 hover:!border-solid hover:!border-primary"
                  [id]="content.slug"
                  [ngStyle]="{
                    backgroundColor:
                      content.styles.colors['other']['backgroundColor'],
                  }"
                  [ngClass]="{
                    '!border-2 !border-solid !border-primary':
                      content?.id === selectedPageModuleTemplate?.id,
                  }">
                  <div
                    [ngClass]="{
                      'disabled-section':
                        user$()?.currentPlan?.productDetails?.name ===
                        'Basic Plan',
                    }"
                    class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                    <button
                      class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                      (click)="editModule(content)">
                      <i class="pi pi-pencil text-[10px]"></i>
                    </button>
                    <button
                      *ngIf="pageContent.length > 1"
                      class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                      (click)="deleteModule(content.id)">
                      <i class="pi pi-trash text-[10px]"></i>
                    </button>
                    <button
                      class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                      *ngIf="i !== 0"
                      (click)="reorderModule(content, -1)">
                      <i class="pi pi-arrow-up text-[10px]"></i>
                    </button>
                    <button
                      class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                      *ngIf="i !== pageContent.length - 1"
                      (click)="reorderModule(content, 1)">
                      <i class="pi pi-arrow-down text-[10px]"></i>
                    </button>
                  </div>
                  <p>No Components</p>
                </div>
              } @else {
                @if (
                  content.slug.startsWith('header-data') ||
                  content.slug.startsWith('header-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div
                      class="w-full h-[400px] bg-cover bg-center relative rounded-bl-[30px] rounded-br-[30px] mb-[35px]"
                      [ngStyle]="{
                        'background-image':
                          'url(' +
                          content.pageModuleTemplateDataContents[1].value.url +
                          ')',
                      }">
                      <div
                        class="flex p-2 rounded absolute top-[78%] left-1/2 transform -translate-x-1/2 translate-y-full"
                        [ngStyle]="{
                          backgroundColor:
                            content.styles.colors['icon']['background'],
                        }">
                        <span
                          class="pi pi-verified text-[40px]"
                          [ngStyle]="{
                            color: content.styles.colors['icon']['foreground'],
                          }"></span>
                      </div>
                    </div>
                    <div class="text-center">
                      <p
                        class="text-2xl font-semibold break-words"
                        [ngStyle]="{
                          color: content.styles.colors['text']['headline'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[2].value.text
                        }}
                      </p>
                    </div>
                    <div class="text-center">
                      @if (
                        content.pageModuleTemplateDataContents[0].value.url
                      ) {
                        <img
                          [src]="
                            content.pageModuleTemplateDataContents[0].value.url
                          "
                          alt="content.pageModuleTemplateDataContents[0].value.altText"
                          class="w-[100px] h-auto" />
                      }
                    </div>
                    <div class="text-justify break-words">
                      <p
                        [ngStyle]="{
                          color: content.styles.colors['text']['description'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[4].value.text
                        }}
                      </p>
                    </div>
                    @if (
                      content.pageModuleTemplateDataContents[3].value.isActive
                    ) {
                      <div
                        class="flex justify-between bg-gray-300 items-center border-2 border-solid p-2.5 rounded"
                        [ngStyle]="{
                          backgroundColor:
                            content.styles.colors['date']['background'],
                          borderColor: content.styles.colors['date']['border'],
                        }">
                        <div class="flex items-center">
                          <span
                            class="pi pi-clock text-[40px]"
                            [ngStyle]="{
                              color: content.styles.colors['date']['content'],
                            }"></span>
                          <span
                            class="mr-[10px] text-[12px]"
                            [ngStyle]="{
                              color: content.styles.colors['date']['content'],
                            }"></span>
                          <div
                            [ngStyle]="{
                              color: content.styles.colors['date']['content'],
                            }">
                            <small>{{
                              content.pageModuleTemplateDataContents[3].label
                            }}</small>
                            <p class="m-[0px] font-bold">
                              {{
                                content.pageModuleTemplateDataContents[3].value
                                  .date
                              }}
                            </p>
                          </div>
                        </div>
                        <div>
                          <span
                            class="text-[12px] p-[5px] text-white rounded-[5px]"
                            [ngStyle]="{
                              backgroundColor:
                                content.styles.colors['date'][
                                  'badgeBackground'
                                ],
                              color: content.styles.colors['date']['badgeText'],
                            }"
                            >{{
                              content.pageModuleTemplateDataContents[3].value
                                .text
                            }}</span
                          >
                        </div>
                      </div>
                    }
                    <div
                      class="text-center break-words"
                      [ngStyle]="{
                        color: content.styles.colors['text']['title'],
                      }">
                      <p class="text-2xl font-semibold">
                        {{
                          content.pageModuleTemplateDataContents[5].value.text
                        }}
                      </p>
                    </div>
                    <div
                      class="text-justify"
                      [ngStyle]="{
                        color: content.styles.colors['text']['description'],
                      }">
                      <p class="break-words">
                        {{
                          content.pageModuleTemplateDataContents[6].value.text
                        }}
                      </p>
                    </div>
                  </div>
                }
                @if (
                  content.slug.startsWith('brand-card-data') ||
                  content.slug.startsWith('brand-card-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>

                    <div
                      [ngStyle]="{
                        backgroundColor:
                          content.styles.colors['other']['background'],
                      }">
                      <div>
                        @if (
                          content.pageModuleTemplateDataContents[0].value.url
                        ) {
                          <img
                            class="w-full"
                            [src]="
                              content.pageModuleTemplateDataContents[0].value
                                .url
                            " />
                        }
                      </div>
                      <div class="mt-3 mb-3">
                        <div
                          class="text-center text-lg font-bold"
                          [ngStyle]="{
                            color: content.styles.colors['text']['title'],
                          }">
                          {{
                            content.pageModuleTemplateDataContents[1].value.text
                          }}
                        </div>
                        <div
                          class="text-center"
                          [ngStyle]="{
                            color: content.styles.colors['text']['description'],
                          }">
                          {{
                            content.pageModuleTemplateDataContents[2].value.text
                          }}
                        </div>
                      </div>
                      <div>
                        <div
                          class="text-center p-[10px] border-2 border-solid mt-[5px] mb-[5px] rounded-[5px] bg-transparent"
                          [ngStyle]="{
                            backgroundColor:
                              content.styles.colors['button']['background'],
                            borderColor:
                              content.styles.colors['button']['border'],
                          }">
                          <a
                            [href]="
                              content.pageModuleTemplateDataContents[3].value
                                .url
                            "
                            [ngStyle]="{
                              color: content.styles.colors['button']['text'],
                            }"
                            target="_blank"
                            class="no-underline"
                            >{{
                              content.pageModuleTemplateDataContents[3].value
                                .label
                            }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                }
                @if (
                  content.slug.startsWith('video-module-data') ||
                  content.slug.startsWith('video-module-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <p-carousel
                        [value]="content.pageModuleTemplateDataContents"
                        [numVisible]="1"
                        [numScroll]="1"
                        [circular]="true"
                        showIndicators="false">
                        <ng-template let-data pTemplate="item">
                          <div>
                            <app-video-thumbnail
                              [videoUrl]="data.value.url"></app-video-thumbnail>
                            <div
                              class="font-bold mb-3 text-lg text-center"
                              [ngStyle]="{
                                color: content.styles.colors['text']['title'],
                              }">
                              {{ data.value.videoTitle }}
                            </div>
                          </div>
                        </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                }
                @if (
                  content.slug.startsWith('text-image-video-data') ||
                  content.slug.startsWith('text-image-video-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="pageContent.length > 1"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    @for (
                      data of content.pageModuleTemplateDataContents;
                      track data
                    ) {
                      @if (data.type === 'link') {
                        <div
                          class="text-center p-[10px] border-2 border-solid mt-[5px] mb-[5px] rounded-[5px] bg-transparent"
                          [ngStyle]="{
                            backgroundColor:
                              content.styles.colors['button']['background'],
                            borderColor:
                              content.styles.colors['button']['border'],
                          }">
                          <a
                            [href]="data.value.url"
                            target="_blank"
                            class="no-underline"
                            [ngStyle]="{
                              color: content.styles.colors['button']['text'],
                            }"
                            >{{ data.value.label }}</a
                          >
                        </div>
                      }
                      @if (data.type === 'text') {
                        <div>
                          <p
                            [ngStyle]="{
                              color: content.styles.colors['text']['content'],
                            }">
                            {{ data.value.text }}
                          </p>
                        </div>
                      }
                      @if (data.type === 'image') {
                        <div>
                          @if (data.value.url) {
                            <img
                              [src]="data.value.url"
                              [alt]="data.value.altText"
                              class="w-100" />
                          }
                        </div>
                      }
                      @if (data.type === 'video') {
                        <div class="text-center mt-3">
                          <div
                            class="font-bold mb-3"
                            [ngStyle]="{
                              color: content.styles.colors['text']['title'],
                            }">
                            {{ data.value.videoTitle }}
                          </div>
                          <app-video-thumbnail
                            [videoUrl]="data.value.url"></app-video-thumbnail>
                        </div>
                      }
                    }
                  </div>
                }
                @if (
                  content.slug.startsWith('text-data') ||
                  content.slug.startsWith('text-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <p-tabs value="0" [scrollable]="true">
                        <p-tablist>
                          @for (
                            data of content.pageModuleTemplateDataContents;
                            track data;
                            let i = $index
                          ) {
                            <p-tab
                              [ngStyle]="{
                                color:
                                  content.styles.colors['text']['tabTitle'],
                              }"
                              [value]="i"
                              >{{ data.value.title }}</p-tab
                            >
                          }
                        </p-tablist>
                        <p-tabpanels>
                          @for (
                            data of content.pageModuleTemplateDataContents;
                            track data;
                            let i = $index
                          ) {
                            <p-tabpanel [value]="i">
                              <p class="m-0">{{ data?.value?.description }}</p>
                            </p-tabpanel>
                          }
                        </p-tabpanels>
                      </p-tabs>
                    </div>
                  </div>
                }
                @if (
                  content.slug.startsWith('carousel-data') ||
                  content.slug.startsWith('carousel-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <p-carousel
                        [value]="content.pageModuleTemplateDataContents"
                        [numVisible]="1"
                        [numScroll]="1">
                        <ng-template let-image pTemplate="item">
                          <div
                            class="text-center border border-surface-200 dark:border-surface-700 rounded m-2 p-4">
                            <div class="mb-4">
                              <div class="relative mx-auto">
                                @if (image?.value?.url) {
                                  <img
                                    [src]="image?.value?.url"
                                    class="w-full h-[200px] object-cover rounded-border" />
                                }
                              </div>
                            </div>
                            <div
                              class="font-bold"
                              [ngStyle]="{
                                color: content.styles.colors['text']['title'],
                              }">
                              {{ image?.value?.title }}
                            </div>
                            <div
                              [ngStyle]="{
                                color:
                                  content.styles.colors['text']['description'],
                              }">
                              <small>{{ image?.value?.description }}</small>
                            </div>
                          </div>
                        </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                }

                @if (
                  content.slug.startsWith('social-links-data') ||
                  content.slug.startsWith('social-links-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <div
                        class="text-center font-bold mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['title'],
                        }">
                        Follow Us
                      </div>
                      <div class="flex gap-6 items-center justify-center">
                        @for (
                          icon of content.pageModuleTemplateDataContents;
                          track icon
                        ) {
                          @if (icon?.value?.url) {
                            <a [href]="icon?.value?.link"
                              ><img
                                [src]="icon?.value?.url"
                                class="w-[40px] h-[40px] rounded-full object-cover"
                            /></a>
                          }
                        }
                      </div>
                    </div>
                  </div>
                }

                @if (
                  content.slug.startsWith('email-data') ||
                  content.slug.startsWith('email-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary text-center"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <div class="text-center mb-4">
                        @if (
                          content.pageModuleTemplateDataContents[0].value.url
                        ) {
                          <img
                            [src]="
                              content.pageModuleTemplateDataContents[0].value
                                .url
                            "
                            class="w-[75px] h-[75px] object-cover rounded-full" />
                        }
                      </div>
                      <div
                        class="text-center text-md font-bold mb-2"
                        [ngStyle]="{
                          color: content.styles.colors['text']['title'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[1].value.text
                        }}
                      </div>
                      <div
                        class="text-center text-md mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['description'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[2].value.text
                        }}
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          pInput
                          class="w-100"
                          placeholder="Enter your email address" />
                      </div>
                      <div>
                        <div
                          class="h-text-center p-[10px] border-2 border-solid mt-[5px] mb-[5px] rounded-[5px] bg-transparent"
                          [ngStyle]="{
                            backgroundColor:
                              content.styles.colors['button']['background'],
                            borderColor:
                              content.styles.colors['button']['border'],
                          }">
                          <a
                            [href]="
                              content.pageModuleTemplateDataContents[3].value
                                .url
                            "
                            [ngStyle]="{
                              color: content.styles.colors['button']['text'],
                            }"
                            target="_blank"
                            class="no-underline"
                            >{{
                              content.pageModuleTemplateDataContents[3].value
                                .label
                            }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                }

                @if (
                  content.slug.startsWith('footer-data') ||
                  content.slug.startsWith('footer-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <div
                        class="text-center text-md font-bold mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['title'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[0].value.text
                        }}
                      </div>
                      <div class="text-center">
                        @if (
                          content.pageModuleTemplateDataContents[1].value.url
                        ) {
                          <img
                            [src]="
                              content.pageModuleTemplateDataContents[1].value
                                .url
                            "
                            class="w-100 object-contain" />
                        }
                      </div>
                      <div
                        class="text-center text-md mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['subtitle'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[2].value.text
                        }}
                      </div>
                      <div
                        class="text-center text-xs mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['description'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[3].value.text
                        }}
                      </div>
                    </div>

                    <div>
                      <div
                        class="text-center p-[10px] border-2 border-solid mt-[5px] mb-[5px] rounded-[5px] bg-transparent"
                        [ngStyle]="{
                          backgroundColor:
                            content.styles.colors['button']['background'],
                          borderColor:
                            content.styles.colors['button']['border'],
                        }">
                        <a
                          [href]="
                            content.pageModuleTemplateDataContents[4].value.url
                          "
                          [ngStyle]="{
                            color: content.styles.colors['button']['text'],
                          }"
                          target="_blank"
                          class="no-underline"
                          >{{
                            content.pageModuleTemplateDataContents[4].value
                              .label
                          }}</a
                        >
                      </div>
                    </div>
                  </div>
                }

                @if (
                  content.slug.startsWith('rate-data') ||
                  content.slug.startsWith('rate-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <div
                        class="text-center font-bold mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['title'],
                        }">
                        {{
                          content.pageModuleTemplateDataContents[0].value.text
                        }}
                      </div>
                      <div class="flex gap-6 items-center justify-center">
                        <p-rating />
                      </div>
                    </div>
                  </div>
                }

                @if (
                  content.slug.startsWith('track-trace-data') ||
                  content.slug.startsWith('track-trace-template')
                ) {
                  <div
                    class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                    [id]="content.slug"
                    [ngStyle]="{
                      backgroundColor:
                        content.styles.colors['other']['background'],
                    }"
                    [ngClass]="{
                      '!border-2 !border-solid !border-primary':
                        content?.id === selectedPageModuleTemplate?.id,
                    }">
                    <div
                      [ngClass]="{
                        'disabled-section':
                          user$()?.currentPlan?.productDetails?.name ===
                          'Basic Plan',
                      }"
                      class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="editModule(content)">
                        <i class="pi pi-pencil text-[10px]"></i>
                      </button>
                      <button
                        *ngIf="pageContent.length > 1"
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        (click)="deleteModule(content.id)">
                        <i class="pi pi-trash text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== 0"
                        (click)="reorderModule(content, -1)">
                        <i class="pi pi-arrow-up text-[10px]"></i>
                      </button>
                      <button
                        class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                        *ngIf="i !== pageContent.length - 1"
                        (click)="reorderModule(content, 1)">
                        <i class="pi pi-arrow-down text-[10px]"></i>
                      </button>
                    </div>
                    <div>
                      <div
                        class="font-bold text-center mb-5"
                        [ngStyle]="{
                          color: content.styles.colors['text']['title'],
                        }">
                        {{
                          filter(
                            content.pageModuleTemplateDataContents,
                            'type',
                            'text'
                          )[0]?.value.text
                        }}
                      </div>
                      <p-timeline
                        [align]="'left'"
                        [value]="
                          filter(
                            content.pageModuleTemplateDataContents,
                            'type',
                            'cte'
                          )
                        ">
                        <ng-template pTemplate="marker" let-cte>
                          @if (cte.value.url) {
                            <img
                              [src]="cte.value.url"
                              class="w-[40px] h-[40px] object-cover rounded-full" />
                          }
                        </ng-template>
                        <ng-template pTemplate="content" let-cte>
                          <div class="mb-4">
                            <small
                              [ngStyle]="{
                                color: content.styles.colors['text']['date'],
                              }"
                              >{{ cte.value.date | formatDate }}</small
                            >
                            <div
                              [ngStyle]="{
                                color: content.styles.colors['text']['title'],
                              }"
                              class="font-bold">
                              {{ cte.value.text }}
                            </div>
                            <div
                              [ngStyle]="{
                                color:
                                  content.styles.colors['text']['description'],
                              }">
                              {{ cte.value.description }}
                            </div>
                          </div>
                        </ng-template>
                      </p-timeline>
                    </div>
                  </div>
                }
              }
            }
          </p-tabpanel>
          <p-tabpanel value="1">
            <div class="relative">
              <div
                class="absolute inset-0 z-10 flex items-center justify-center opacity-25 pointer-events-none">
                <p
                  class="border-2 border-solid border-red-600 rounded p-5 text-5xl font-bold text-red-600 -rotate-45 select-none">
                  RECALLED
                </p>
              </div>
              <div class="relative">
                @for (content of pageContent; track content; let i = $index) {
                  @if (content.pageModuleTemplateDataContents.length === 0) {
                    <div
                      class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[300px] text-center hover:!border-2 hover:!border-solid hover:!border-primary"
                      [id]="content.slug"
                      [ngStyle]="{
                        backgroundColor:
                          content.styles.colors['other']['backgroundColor'],
                      }"
                      [ngClass]="{
                        '!border-2 !border-solid !border-primary':
                          content?.id === selectedPageModuleTemplate?.id,
                      }">
                      <div
                        [ngClass]="{
                          'disabled-section':
                            user$()?.currentPlan?.productDetails?.name ===
                            'Basic Plan',
                        }"
                        class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                        <button
                          class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                          (click)="editModule(content)">
                          <i class="pi pi-pencil text-[10px]"></i>
                        </button>
                        <button
                          *ngIf="pageContent.length > 1"
                          class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                          (click)="deleteModule(content.id)">
                          <i class="pi pi-trash text-[10px]"></i>
                        </button>
                        <button
                          class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                          *ngIf="i !== 0"
                          (click)="reorderModule(content, -1)">
                          <i class="pi pi-arrow-up text-[10px]"></i>
                        </button>
                        <button
                          class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                          *ngIf="i !== pageContent.length - 1"
                          (click)="reorderModule(content, 1)">
                          <i class="pi pi-arrow-down text-[10px]"></i>
                        </button>
                      </div>
                      <p>No Components</p>
                    </div>
                  } @else {
                    @if (
                      content.slug.startsWith('header-data') ||
                      content.slug.startsWith('header-template')
                    ) {
                      <div
                        class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                        [id]="content.slug"
                        [ngStyle]="{
                          backgroundColor:
                            content.styles.colors['other']['background'],
                        }"
                        [ngClass]="{
                          '!border-2 !border-solid !border-primary':
                            content?.id === selectedPageModuleTemplate?.id,
                        }">
                        <div
                          [ngClass]="{
                            'disabled-section':
                              user$()?.currentPlan?.productDetails?.name ===
                              'Basic Plan',
                          }"
                          class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            (click)="editModule(content)">
                            <i class="pi pi-pencil text-[10px]"></i>
                          </button>
                          <button
                            *ngIf="pageContent.length > 1"
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            (click)="deleteModule(content.id)">
                            <i class="pi pi-trash text-[10px]"></i>
                          </button>
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            *ngIf="i !== 0"
                            (click)="reorderModule(content, -1)">
                            <i class="pi pi-arrow-up text-[10px]"></i>
                          </button>
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            *ngIf="i !== pageContent.length - 1"
                            (click)="reorderModule(content, 1)">
                            <i class="pi pi-arrow-down text-[10px]"></i>
                          </button>
                        </div>
                        <div
                          class="w-full h-[400px] bg-cover bg-center relative rounded-bl-[30px] rounded-br-[30px] mb-[35px]"
                          [ngStyle]="{
                            'background-image':
                              'url(' +
                              content.pageModuleTemplateDataContents[1].value
                                .url +
                              ')',
                          }">
                          <div
                            class="flex p-2 rounded absolute top-[78%] left-1/2 transform -translate-x-1/2 translate-y-full"
                            [ngStyle]="{
                              backgroundColor:
                                content.styles.colors['icon']['background'],
                            }">
                            <span
                              class="pi pi-verified text-[40px]"
                              [ngStyle]="{
                                color:
                                  content.styles.colors['icon']['foreground'],
                              }"></span>
                          </div>
                        </div>
                        <div class="text-center">
                          <p
                            class="text-2xl font-semibold break-words"
                            [ngStyle]="{
                              color: content.styles.colors['text']['headline'],
                            }">
                            {{
                              content.pageModuleTemplateDataContents[2].value
                                .text
                            }}
                          </p>
                        </div>
                        <div class="text-center">
                          @if (
                            content.pageModuleTemplateDataContents[0].value.url
                          ) {
                            <img
                              [src]="
                                content.pageModuleTemplateDataContents[0].value
                                  .url
                              "
                              alt="content.pageModuleTemplateDataContents[0].value.altText"
                              class="w-[100px] h-auto" />
                          }
                        </div>
                        <div class="text-justify break-words">
                          <p
                            [ngStyle]="{
                              color:
                                content.styles.colors['text']['description'],
                            }">
                            {{
                              content.pageModuleTemplateDataContents[4].value
                                .text
                            }}
                          </p>
                        </div>
                        @if (
                          content.pageModuleTemplateDataContents[3].value
                            .isActive
                        ) {
                          <div
                            class="flex justify-between bg-gray-300 items-center border-2 border-solid p-2.5 rounded"
                            [ngStyle]="{
                              backgroundColor:
                                content.styles.colors['date']['background'],
                              borderColor:
                                content.styles.colors['date']['border'],
                            }">
                            <div class="flex items-center">
                              <span
                                class="pi pi-clock text-[40px]"
                                [ngStyle]="{
                                  color:
                                    content.styles.colors['date']['content'],
                                }"></span>
                              <span
                                class="mr-[10px] text-[12px]"
                                [ngStyle]="{
                                  color:
                                    content.styles.colors['date']['content'],
                                }"></span>
                              <div
                                [ngStyle]="{
                                  color:
                                    content.styles.colors['date']['content'],
                                }">
                                <small>{{
                                  content.pageModuleTemplateDataContents[3]
                                    .label
                                }}</small>
                                <p class="m-[0px] font-bold">
                                  {{
                                    content.pageModuleTemplateDataContents[3]
                                      .value.date
                                  }}
                                </p>
                              </div>
                            </div>
                            <div>
                              <span
                                class="text-[12px] p-[5px] text-white rounded-[5px]"
                                [ngStyle]="{
                                  backgroundColor:
                                    content.styles.colors['date'][
                                      'badgeBackground'
                                    ],
                                  color:
                                    content.styles.colors['date']['badgeText'],
                                }"
                                >{{
                                  content.pageModuleTemplateDataContents[3]
                                    .value.text
                                }}</span
                              >
                            </div>
                          </div>
                        }
                        <div
                          class="text-center break-words"
                          [ngStyle]="{
                            color: content.styles.colors['text']['title'],
                          }">
                          <p class="text-2xl font-semibold">
                            {{
                              content.pageModuleTemplateDataContents[5].value
                                .text
                            }}
                          </p>
                        </div>
                        <div
                          class="text-justify"
                          [ngStyle]="{
                            color: content.styles.colors['text']['description'],
                          }">
                          <p class="break-words">
                            {{
                              content.pageModuleTemplateDataContents[6].value
                                .text
                            }}
                          </p>
                        </div>

                        <div
                          class="flex gap-3 justify-between items-center border-2 border-solid bg-yellow-100 border-yellow-600 p-2.5 rounded">
                          <div>
                            <span
                              class="pi pi-exclamation-circle text-xl text-yellow-600"></span>
                          </div>
                          <div>
                            <div class="font-bold text-md text-yellow-600">
                              The product has been Recalled
                            </div>
                            <div>
                              <small
                                >For any questions or additional details, please
                                reach out to our customer care!</small
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    @if (
                      content.slug.startsWith('footer-data') ||
                      content.slug.startsWith('footer-template')
                    ) {
                      <div
                        class="relative p-[10px] border-2 border-solid border-transparent rounded-[10px] transition-border duration-300 ease-in-out min-h-[200px] hover:!border-2 hover:!border-solid hover:!border-primary"
                        [id]="content.slug"
                        [ngStyle]="{
                          backgroundColor:
                            content.styles.colors['other']['background'],
                        }"
                        [ngClass]="{
                          '!border-2 !border-solid !border-primary':
                            content?.id === selectedPageModuleTemplate?.id,
                        }">
                        <div
                          [ngClass]="{
                            'disabled-section':
                              user$()?.currentPlan?.productDetails?.name ===
                              'Basic Plan',
                          }"
                          class="absolute top-[25px] right-[20px] flex flex-col gap-[5px] z-[99999]">
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            (click)="editModule(content)">
                            <i class="pi pi-pencil text-[10px]"></i>
                          </button>
                          <button
                            *ngIf="pageContent.length > 1"
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            (click)="deleteModule(content.id)">
                            <i class="pi pi-trash text-[10px]"></i>
                          </button>
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            *ngIf="i !== 0"
                            (click)="reorderModule(content, -1)">
                            <i class="pi pi-arrow-up text-[10px]"></i>
                          </button>
                          <button
                            class="bg-white text-black border-2 border-solid border-primary w-[35px] h-[35px] rounded-full flex justify-center items-center cursor-pointer"
                            *ngIf="i !== pageContent.length - 1"
                            (click)="reorderModule(content, 1)">
                            <i class="pi pi-arrow-down text-[10px]"></i>
                          </button>
                        </div>
                        <div>
                          <div
                            class="text-center text-md font-bold mb-5"
                            [ngStyle]="{
                              color: content.styles.colors['text']['title'],
                            }">
                            {{
                              content.pageModuleTemplateDataContents[0].value
                                .text
                            }}
                          </div>
                          <div class="text-center">
                            @if (
                              content.pageModuleTemplateDataContents[1].value
                                .url
                            ) {
                              <img
                                [src]="
                                  content.pageModuleTemplateDataContents[1]
                                    .value.url
                                "
                                class="w-100 object-contain" />
                            }
                          </div>
                          <div
                            class="text-center text-md mb-5"
                            [ngStyle]="{
                              color: content.styles.colors['text']['subtitle'],
                            }">
                            {{
                              content.pageModuleTemplateDataContents[2].value
                                .text
                            }}
                          </div>
                          <div
                            class="text-center text-xs mb-5"
                            [ngStyle]="{
                              color:
                                content.styles.colors['text']['description'],
                            }">
                            {{
                              content.pageModuleTemplateDataContents[3].value
                                .text
                            }}
                          </div>
                        </div>

                        <div>
                          <div
                            class="text-center p-[10px] border-2 border-solid mt-[5px] mb-[5px] rounded-[5px] bg-transparent"
                            [ngStyle]="{
                              backgroundColor:
                                content.styles.colors['button']['background'],
                              borderColor:
                                content.styles.colors['button']['border'],
                            }">
                            <a
                              [href]="
                                content.pageModuleTemplateDataContents[4].value
                                  .url
                              "
                              [ngStyle]="{
                                color: content.styles.colors['button']['text'],
                              }"
                              target="_blank"
                              class="no-underline"
                              >{{
                                content.pageModuleTemplateDataContents[4].value
                                  .label
                              }}</a
                            >
                          </div>
                        </div>
                      </div>
                    }
                  }
                }
              </div>
            </div>
          </p-tabpanel>
        </p-tabpanels>
      </p-tabs>
    </div>
  </div>
} @else {
  <div class="flex items-center justify-center w-100 h-[100%]">
    <p-progressSpinner styleClass="!w-[4rem] !h-[4rem]" />
  </div>
}
